
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { CollapsableList } from '../../components/Custom/CollapsableList';
import { CancelButton, DeleteButton, EditButton, SaveButton } from '../../components/Button';
import { GlobalRole } from '../../types';
import { useAutoSaveForm } from '../../hooks';

const generalRights = [
  'can_edit_all_countries',
  'can_edit_all_languages',
] as const;
const organizationRights = [
  'can_create_organizations',
  'can_edit_all_organizations',
  'can_validate_or_reject_all_organization_data',
  'can_validate_or_reject_all_data',
] as const;
const userRights = [
  'can_edit_all_users',
  'can_view_all_users',
  'can_assign_user_global_roles',
  'can_edit_user_global_roles',
] as const;
const rights = [
  'can_edit_all_countries',
  'can_edit_all_languages',
  'can_create_organizations',
  'can_edit_all_organizations',
  'can_validate_or_reject_all_organization_data',
  'can_validate_or_reject_all_data',
  'can_edit_all_users',
  'can_view_all_users',
  'can_assign_user_global_roles',
  'can_edit_user_global_roles',
] as const;
const rightStructure = [
  generalRights,
  organizationRights,
  userRights,
] as const;
type Right = typeof rights[number];
type GlobalRoleTableRowProps = {
  id: string;
  isValid?: (role: Partial<GlobalRole>) => boolean;

  autosave?: boolean;

  role?: GlobalRole;
  resetCounter?: number;

  canEdit?: boolean;
  canDelete?: boolean;

  onDelete?: () => void;
  onCancel?: () => void;
  onSave?: (role: Omit<GlobalRole, 'id'>) => void;
}

export const GlobalRoleTableRow: FC<GlobalRoleTableRowProps> = (props) => {
  const initialValues = useMemo(() => {
    return props.role ?? newRole();
  }, [props.role, props.resetCounter]);
  const data = useAutoSaveForm({ initialValues: initialValues, onSave: handleSave, shouldAutosave: props.autosave });
  const dataIsValid = useMemo(() => {
    const isValid = props.isValid ?? ((role: Omit<GlobalRole, 'id'>) => {
      if (!role?.name) return false;
      return true;
    });
    return isValid(data.formData);
  }, [data.formData, props.isValid]);

  useEffect(() => {
    data.handleFormDataChange(initialValues);
  }, [props.resetCounter]);

  function handleSave(role: Omit<GlobalRole, 'id'>) {
    props.onSave?.(role);
  }
  function handleDelete() {
    props.onDelete?.();
  }
  function handleCancel() {
    if (props.onCancel) {
      props.onCancel();
    } else {
      data.resetForm();
    }
  }
  // function setEntireGroup(group = [], value) {
  //   setRoleEdit({
  //     ...(roleEdit || {}),
  //     // ...(group.reduce((previous, currentKey, index) => ({ ...previous, [currentKey]: value }), {})),
  //     ...(group.reduce((previous, currentKey, index) => {
  //       previous[currentKey] = value;
  //       return previous;
  //     }, {})),
  //   });
  // }
  function setEntireGroup(group: readonly Right[], value: boolean) {
    const newFormData: Partial<GlobalRole> = {};
    group.forEach(right => {
      newFormData[right] = value;
    });
    data.handlePartialFormDataChange(newFormData);
  }
  function allChecked(rights: readonly Right[]) {
    return rights.every(right => data.formData[right]);
  }
  return <tr id={props.id}>
    <td>{!props.canEdit ? (
      data.formData.name
    ) : (
      <Form.Control
        isValid={dataIsValid}
        value={data.formData.name || ''}
        onChange={e => data.handleFieldChange('name', e.target.value)} />
    )}</td>
    {rightStructure.map((group, index) => (
      <td key={`${index}.header`}>
        <CollapsableList
          header={<Form.Check
            inline
            key={`${index}.header`}
            disabled={!props.canEdit}
            onChange={(e) => setEntireGroup(group, e.target.checked)}
            type={'checkbox'}
            label={`check all`}
            id={`${props.id}.${index}.header`}
            checked={allChecked(group)}
          />}
        >
          {group.map((right: Right) => <Form.Check
            key={right}
            disabled={!props.canEdit}
            onChange={(e) => data.handleFieldChange(right, e.target.checked)}
            type={'checkbox'}
            label={right}
            id={`${props.id}.${index}.${right}`}
            checked={data.formData[right]}
          />)}
        </CollapsableList>
      </td>
    ))
    }
    <td>
      {props.autosave === false && props.canEdit ? (<>
        <CancelButton id={`${props.id}-cancel-button`} onClick={handleCancel} /> {" "}
        <SaveButton id={`${props.id}-save-button`} disabled={!dataIsValid} color={dataIsValid ? 'blue' : 'lightgrey'} onClick={() => handleSave(data.formData)} /> {" "}
      </>) : null}
      {props.canDelete ? (
        <DeleteButton id={`${props.id}-delete-button`} onClick={handleDelete} />
      ) : null}
    </td>
  </tr >
}


function newRole(): Omit<GlobalRole, 'id'> {
  return {
    name: '',
    can_edit_all_countries: false,
    can_edit_all_languages: false,

    can_create_organizations: false,
    can_edit_all_organizations: false,
    can_validate_or_reject_all_organization_data: false,
    can_validate_or_reject_all_data: false,

    can_edit_all_users: false,
    can_view_all_users: false,
    can_assign_user_global_roles: false,
    can_edit_user_global_roles: false,
  };
}
