
import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import getPublicUrl from '../../helpers/getPublicUrl';
import { CountryProfileRatings, CountryProfileRegions } from './profile';
import { EditButton, GoBackButton } from '../../components/Button';
import { countryPolicies } from '../../authorization';
import { useGetAllCountryRatingsQuery, useGetCountryQuery, useGetCurrentUserQuery } from '../../services';
import { useAppDispatch, useAppSelector } from '../../helpers';
import { resetKeyvisual, setKeyvisual } from '../../slices/keyvisual.slice';
import { Helmet } from 'react-helmet';

type Key = 'info' | 'economy' | 'politics' | 'travel' | 'regions' | 'ratings';
export const CountryProfile: FC<{}> = ({ }) => {
  const { country_id } = useParams();

  const navigate = useNavigate();

  const location = useLocation();

  const { data: currentUser, isSuccess: currentUserIsSuccess } = useGetCurrentUserQuery();

  const language = useAppSelector(state => state.language);
  // const country = useAppSelector(state => state.country);
  const {
    data: country,
    isError: countryIsError, isLoading: countryIsLoading, isSuccess: countryIsSuccess,
    refetch: refetchCountry
  } = useGetCountryQuery({ country_id: Number(country_id) });
  const keyvisual = useAppSelector(state => state.keyvisual);

  const dispatch = useAppDispatch();
  const loaded = country && countryIsSuccess;

  const [key, setKey] = useState((location.state?.key || 'info') as Key);

  useEffect(() => {
    if (loaded && !keyvisual.url) {
      changeKeyvisual(key);
    }
  }, [loaded, keyvisual.url, key]);


  function changeKeyvisual(k: string | null) {
    switch (k) {
      case "info":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/embassies_default.jpg",
          title: country?.text_name[language.id] || country?.name
        }));
        setKey('info');
        break;
      case "economy":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/economic_default.jpg",
          title: country?.text_name[language.id] || country?.name
        }));
        setKey('economy');
        break;
      case "politics":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/politics_default.jpg",
          title: country?.text_name[language.id] || country?.name
        }));
        setKey('politics');
        break;
      case "travel":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/travel_default.jpg",
          title: country?.text_name[language.id] || country?.name
        }));
        setKey('travel');
        break;
      case "regions":
        setKey('regions');
        break;
      case "ratings":
        setKey('ratings');
        break;

      default:
        break;
    }
  }
  return (<Card body>
    <Helmet>
      <title>{`${country?.name} | ${APP_NAME}`}</title>
      <meta name="description" content={`${country?.name} | ${APP_NAME}`} />
    </Helmet>
    <Row>
      <Col md={3}>
        <h6>
          <GoBackButton id='go-back-to-countries' onClick={() => {
            navigate('/countries');
            dispatch(resetKeyvisual());
          }} />
          {countryIsLoading && <Spinner animation="border" />}
          {countryIsError && <>{' Error'} <Button onClick={() => { refetchCountry(); dispatch(resetKeyvisual()); }}>Reload</Button></>}
          {/* {country && country.deleted && country.id == country_id && ' Country deleted'} */}
          {loaded && `${country.text_name[language.id] || country.name}`}
          {loaded && currentUserIsSuccess && countryPolicies.update(currentUser, country) && (
            <EditButton id="edit-country" onClick={() => navigate(`/countries/${country_id}/edit`, { state: { key: key } })} />
          )}
        </h6>
        {loaded && <Card body>
          <Card.Img variant="top" src={getPublicUrl(country.flag || '')} />
          <Card.Img variant="bottom" src={getPublicUrl(country.minimap || '')} />
          Capital: {loaded && country.text_capital[language.id]} <br />
          Area: {loaded && country.area} <br />
          Population: {loaded && country.population} <br />
        </Card>}
      </Col>
      <Col>
        <Tabs
          activeKey={key}
          onSelect={(k) => changeKeyvisual(k)}
          fill
        >
          <Tab eventKey="info" title="General Information">
            <Card body>
              <Card.Text>
                Full name: {loaded && country.text_full_name[language.id]} <br />
                Languages: {loaded && country.text_languages[language.id]} <br />
                Ethnic Groups: {loaded && country.text_ethnic_groups[language.id]} <br />
                Religions: {loaded && country.text_religions[language.id]} <br />
                <br />
                Head of State: {loaded && country.head_of_state} <br />
                Prime Minister: {loaded && country.prime_minister} <br />
                <br />
                Currency: {loaded && country.text_currency[language.id]} <br />
                Life Expectancy: {loaded && country.life_expectancy} <br />
                Literacy: {loaded && country.literacy} <br />
                Mobile phone penetration: {loaded && country.mobile_phone_penetration} <br />
                Population: {loaded && country.population} <br />
                Population growth rate: {loaded && country.population_growth_rate} <br />
                Population below poverty line: {loaded && country.population_below_poverty_line} <br />
                Urban Population: {loaded && country.urban_population} <br />
                Literacy: {loaded && country.literacy} <br />
              </Card.Text>
            </Card>
          </Tab>
          {country?.economy && <Tab eventKey="economy" title="Economy">
            <Card body>
              Main exports: {loaded && country.economy.text_main_exports[language.id]} <br />
              <br />
              GDP: {loaded && country.economy.gdp} <br />
              GDP growth: {loaded && country.economy.gdp_growth} %<br />
              GDP export: {loaded && country.economy.gdp_export} %<br />
              GDP import: {loaded && country.economy.gdp_import} %<br />
              <br />
              Stock Exchange: {loaded && country.economy.stock_exchange} <br />
              Inflation rate: {loaded && country.economy.inflation_rate} %<br />
              Growth rate: {loaded && country.economy.growth_rate} %<br />
              <br />
              Foreign direct investment: {loaded && country.economy.fdi} <br />
              Gross national income: {loaded && country.economy.gni} <br />
              Public private partnerships: {loaded && country.economy.ppp} <br />
            </Card>
          </Tab>}
          {country?.politics && <Tab eventKey="politics" title="Politics">
            <Card body>
              <Card.Title>Regime type:</Card.Title>
              <Card.Text>{loaded && country.politics.text_regime_type[language.id]}</Card.Text>
              <Card.Title>Legal system:</Card.Title>
              <Card.Text>{loaded && country.politics.text_legal_system[language.id]}</Card.Text>
              <Card.Title>Independence:</Card.Title>
              <Card.Text>{loaded && country.politics.text_independence[language.id]}</Card.Text>
              <Card.Title>Press Freedom:</Card.Title>
              <Card.Text>{loaded && country.politics.text_press_freedom[language.id]}</Card.Text>
              <Card.Title>Human rights record:</Card.Title>
              <Card.Text>{loaded && country.politics.text_human_rights_record[language.id]}</Card.Text>
            </Card>
          </Tab>}
          {country?.travel && <Tab eventKey="travel" title="Travel Information">
            <Card body>
              <Card.Title>Entry/Exit requirements:</Card.Title>
              <Card.Text>{loaded && country.travel.text_entry_exit_requirements[language.id]}</Card.Text>
              <Card.Title>Immunization requirements:</Card.Title>
              <Card.Text>{loaded && country.travel.text_immunization_requirements[language.id]}</Card.Text>
              <Card.Title>Additional risks:</Card.Title>
              <Card.Text>{loaded && country.travel.text_additional_risks[language.id]}</Card.Text>
              <Card.Title>Climate:</Card.Title>
              <Card.Text>{loaded && country.travel.text_climate[language.id]}</Card.Text>
              <Card.Title>Travel time:</Card.Title>
              <Card.Text>{loaded && country.travel.text_travel_time[language.id]}</Card.Text>
              <Card.Text>{loaded && country.travel.tourist_information && <a href={country.travel.tourist_information}>Tourist information</a>}</Card.Text>
            </Card>
          </Tab>}
          {loaded && country.regions && <Tab eventKey="regions" title="Regions">
            <CountryProfileRegions data={country.regions} />
          </Tab>}
          {loaded && country.rating_data && <Tab eventKey="ratings" title="Ratings">
            <CountryProfileRatings data={country.rating_data} />
          </Tab>}
        </Tabs>
      </Col>
    </Row>
  </Card>);
}
