
import { Property } from 'csstype'
import React, { FC, useState, useMemo, ChangeEventHandler, ChangeEvent } from 'react'
import { Col, Row } from 'react-bootstrap';
import { CancelButton, EditButton, SaveButton } from '../Button';
import { DatePicker } from './DatePicker'
import { GenericInput, GenericInputProps } from './GenericInput'

type ValueType = string | number | readonly string[];
type PropsOmitted = 'onChange' | 'onSubmit' | 'value' | 'defaultValue';
interface EditableInput<T extends ValueType> extends Omit<GenericInputProps, PropsOmitted> {
  id: string;
  value?: T;
  initialValue?: T;
  isEditing?: boolean;
  isValid?: ((value: T) => boolean) | boolean;
  onCancel?: (() => void);
  onChange?: ((value: T) => void);
  onEdit?: (() => void);
  onSubmit?: ((value: T) => void);
}
export const EditableInput: FC<EditableInput<ValueType>> = ({
  id,
  isValid = true,
  onCancel = () => { },
  onChange = () => { },
  onEdit = () => { },
  onSubmit = () => { },
  type,
  value,
  disabled,
  initialValue,
  isEditing,
  ...props
}) => {
  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isEditingIsControlled) {
      setIsEditingState(false);
    }
    if (!valueIsControlled) {
      setValueState(initialValue || '');
    }
    onCancel();
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!valueIsControlled) {
      setValueState(e.target.value);
    }
    onChange(e.target.value);
  }
  const handleEdit = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (disabled || isEditingDisplay) {
      return;
    }
    if (!valueIsControlled) {
      setValueState(initialValue || '');
    }
    if (!isEditingIsControlled) {
      setIsEditingState(true);
    }
    onEdit();
  }
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    e.stopPropagation()

    onSubmit(valueDisplay)
    if (!isEditingIsControlled) {
      setIsEditingState(false);
    }
    if (!valueIsControlled) {
      setValueState(initialValue || '');
    }
  }
  const [isEditingState, setIsEditingState] = useState(false)
  const isEditingIsControlled = isEditing !== undefined
  const isEditingDisplay = isEditingIsControlled ? isEditing : isEditingState

  const [valueState, setValueState] = useState(initialValue || '')
  const valueIsControlled = value !== undefined
  const valueDisplay = valueIsControlled ? value : valueState

  const isValidDisplay = typeof isValid === 'boolean' ? isValid : isValid(valueDisplay)
  const isDate = type === 'date'

  if (!isEditingDisplay) {
    return <>
      <span id={`${id}`} className='align-middle' onClick={handleEdit}>{valueDisplay}</span>
      {!disabled && <EditButton
        id={`${id}-edit-button`}
        className='btn btn-link align-middle'
        onClick={handleEdit}
      />}
    </>;
  }
  return (
    <form onSubmit={handleSubmit}
    // style={{ border: 'solid 2px' }}
    >
      <div className='w-100 d-inline-flex align-middle'>
        {isDate ? (
          <DatePicker disabled={disabled} className={'w-100'} width={'auto'} value={valueDisplay} onChange={handleChange} />
        ) : (
          <GenericInput {...props} className={'w-100'} innerClassName='w-100' disabled={disabled} type={type} value={valueDisplay} onChange={handleChange} />
        )}
        <CancelButton
          id={`${id}-cancel-button`}
          fontSize={'1.25rem'}
          style={{
            // position: 'absolute',
            right: "0.25rem"
          }}
          disabled={disabled}
          onClick={handleCancel}
        />
        <SaveButton
          id={`${id}-save-button`}
          fontSize={'1.25rem'}
          style={{
            // position: 'absolute',
            right: 0
          }}
          disabled={disabled || !isValidDisplay}
          type="submit"
        />
      </div>
    </form>
  )
}
