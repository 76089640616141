
import React, { useState } from 'react';
import { Card, Carousel, Col, Modal, Row } from 'react-bootstrap';
import getPublicUrl from '../../../helpers/getPublicUrl';
import './styles.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import MediaFile from './MediaFile';
import { ZoomIn } from 'react-bootstrap-icons';

const carouselItemWidth = "30%";
const carouselCaptionWidth = "70%";
const maxWidth = "200px";
const maxHeight = "200px";
const maxWidthInModal = "600px";
const maxHeightInModal = "600px";
const interval = 3000;
function ModalComponent(props) {
  return <div
    className='centered'
    style={{
      width: maxWidthInModal,
      height: maxHeightInModal,
      // background: "rgba(128,128,128,0.5)"
    }}
  >
    {props.children}
  </div>
}
export default function ProductDetails(props) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedMediaIndex, setSelectedMediaIndex] = useState(0);

  function selectMedia(productIndex, mediaIndex) {
    if (productIndex || productIndex === 0) {
      const product = props.product_details[productIndex];
      if (product !== selectedProduct) setSelectedProduct(product);
      if (mediaIndex || mediaIndex === 0) {
        if (mediaIndex !== selectedMediaIndex) setSelectedMediaIndex(mediaIndex);
      } else {
        if (0 !== selectedMediaIndex) setSelectedMediaIndex(0);
      }
    } else {
      if (selectedProduct !== null) setSelectedProduct(null);
      if (selectedMediaIndex !== 0) setSelectedMediaIndex(0);
    }
  }
  function closeProductMediaModal() {
    selectMedia(null);
  }
  // TODO: fit consistently within Max-width && Max-Height
  return (<>
    <Modal
      centered
      show={!!selectedProduct}
      onHide={closeProductMediaModal}
      dialogAs={ModalComponent}
    >
      <Carousel
        touch pause="hover"
        style={{ alignmentBaseline: "hanging", width: maxWidthInModal, height: maxHeightInModal }}
        activeIndex={selectedMediaIndex}
        onSelect={setSelectedMediaIndex}
      >
        {selectedProduct?.media && selectedProduct.media.map(
          (file) => <Carousel.Item style={{ width: "100%" }}>
            <div
              style={{ width: maxWidthInModal, height: maxHeightInModal, overflow: "hidden", }}
              onClick={closeProductMediaModal}
            >
              <MediaFile file={file} />
            </div>
          </Carousel.Item>
        )}
      </Carousel>
    </Modal>
    {props.product_details.map((product, productIndex) =>
      <div key={product.id} style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", height: maxHeight }}>
        <div style={{ flex: "0 0 auto", alignSelf: "flex-start", width: carouselItemWidth, height: "100%" }}>
          <Carousel
            touch pause="hover"
            style={{ width: maxWidth, height: maxHeight }}
          >
            {product.media && product.media.map(
              (file, mediaIndex) => <Carousel.Item key={file.id} interval={interval} style={{ width: "100%" }}>
                <div
                  style={{ width: maxWidth, height: maxHeight, overflow: "hidden", backgroundColor: 'lightgrey', }}
                  onClick={() => selectMedia(productIndex, mediaIndex)}
                >
                  <MediaFile file={file} />
                  {/* <i style={{color:'blue'}} color="blue" className='bi bi-zoom-in centered size-100 non-clickable'></i> */}
                  <ZoomIn color='whitesmoke' fontSize={'x-large'} className='centered fit-100 non-clickable' />
                </div>
              </Carousel.Item>
            )}
          </Carousel>
        </div>
        <div style={{ flex: "0 0 auto", alignSelf: "flex-end", width: carouselCaptionWidth, height: "100%" }}>
          <Card style={{ width: "100%", height: "100%", alignSelf: "center", padding: "10px" }}>
            <Card.Title>{product.title}</Card.Title>
            <Card.Text>{product.description}</Card.Text>
          </Card>
        </div>
      </div>)}
  </>);
}
