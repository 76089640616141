import { Property } from 'csstype';
import React, { FC } from 'react';
import { PersonLinesFill } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';

export const UserListButton: FC<CustomButtonProptypes> = ({
  color = 'Blue',
  fontSize = '1rem',
  tooltip= "Users",
  ...props
}) => {
  return <GenericButton
    // id={id}
    color={color}
    tooltip={tooltip}
    {...props}
  >
    <PersonLinesFill color={color} fontSize={fontSize} />
  </GenericButton>
}
