
import React, { useEffect, useState } from 'react';
import { Alert, Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Spinner, Badge, Accordion, useAccordionButton } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { orgRatingActions, organizationActions } from '../../../actions';
import StarRating from '../../../components/Custom/StarRating';
import { useAppDispatch, useAppSelector } from '../../../helpers';

export function OrganizationRatings({ data }) {
  // dispatch, um Redux-State zu ändern
  const dispatch = useAppDispatch();
  // useAppSelector, um Redux-State auszulesen
  const allOrgRatings = useAppSelector(state => state.allOrgRatings);
  // const language = useAppSelector(state => state.language);

  useEffect(() => {
    // hole Organisations-Ratings (Titel, etc) von der API und speicher im Redux-State
    if (allOrgRatings.items && !allOrgRatings.loading && !allOrgRatings.error) {
      dispatch(organizationActions.getRatings(data.id));
    }
  }, [allOrgRatings.items, allOrgRatings.loading, allOrgRatings.error]);
  // Render Komponente, wenn alle benötigten Daten vorhanden sind
  return (
    data && allOrgRatings.items
  ) ? (
    <Accordion>
      {Object.entries(allOrgRatings.items).map(([key, rating_gruppe]) => {
        return (<Card key={key}>
          <Card.Header>
            <CustomToggle eventKey={`${key}`}>
              <StarRating rating={data[key]?.score / 2} /> {` `}
              {rating_gruppe.root_rating.title}
            </CustomToggle>
          </Card.Header>
          <Accordion.Collapse eventKey={`${key}`}>
            <Card.Body>
              {Object.entries(rating_gruppe.sub_ratings).map(([id, rating]) => <Row key={id}>
                <Col md={3}>
                  <br />
                  <StarRating rating={data[rating.id]?.score / 2} />
                </Col>
                <Col>
                  <br />
                  <h5>{rating.title}</h5>
                </Col>
              </Row>)}
            </Card.Body>
          </Accordion.Collapse>
        </Card>)
      }
      )}
    </Accordion>
  ) : null;
}


function CustomToggle({ children, eventKey, ...props }) {
  const decoratedOnClick = useAccordionButton(eventKey, () =>
    console.log('totally custom!'),
  );

  return (
    <Link
      as={Button}
      type="button"
      onClick={decoratedOnClick}
      {...props}
    >
      {children}
    </Link>
  );
}
