
import { CurrentUser, GlobalRole, Organization, OrganizationUser, User } from "../types";
import { globalRole, globalRoleKeys } from "./authorization.constants";

export const userPolicies = {
  viewAny,
  view,
  create,
  update,
  delete: delete_,
  restore,
  assignGlobalRole,
};
function viewAny(currentUser?: CurrentUser): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role[globalRole.can_view_all_users]) {
    return true;
  }
  return false;
};
function view(currentUser?: CurrentUser, targetUser?: User): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || (currentUser.global_role.can_view_all_users)) {
    return true;
  }
  return false;
}
function create(currentUser?: CurrentUser): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_all_users) {
    return true;
  }
  return false;
}
function update(currentUser?: CurrentUser, targetUser?: User): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_all_users) {
    return true;
  }
  return false;
}
function delete_(currentUser?: CurrentUser, targetUser?: User): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_all_users) {
    return true;
  }
  return false;
}
function restore(currentUser?: CurrentUser, targetUser?: User): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_all_users) {
    return true;
  }
  return false;
}
function assignGlobalRole(currentUser?: CurrentUser, targetUser?: User, role?: GlobalRole): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner) {
    return true;
  }
  if (!currentUser.global_role.can_assign_user_global_roles) {
    return false;
  }
  if (!role) { return false; }
  for (const k in role) {
    const key = k as keyof typeof role;
    if (role[key as keyof typeof role] === true && !currentUser.global_role[key as keyof typeof currentUser.global_role]) {
      return true;
    }
  }
  return globalRoleKeys.every((key) => {
    return role[key] !== true || currentUser.global_role[key] === true;
  });
}
