import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { countryActions } from '../../../actions';
import { cloneDeep, clone, set, create } from "lodash";
import {
    CountryPrimaryLanguageFormTabCountry,
    CountryPrimaryLanguageFormTabEconomy,
    CountryPrimaryLanguageFormTabPolitics,
    CountryPrimaryLanguageFormRegion,
    CountryPrimaryLanguageFormTabTravel,
} from './';
import { CountryPrimaryLanguageFormTabRegions } from './CountryPrimaryLanguageFormTabRegions';

export function CountryPrimaryLanguageForm(props) {
    const [key, setKey] = useState('info');


    return (<Tabs
        activeKey={key}
        onSelect={(k) => {
            setKey(k);
            props.setKeyvisual(k);
        }}
    >
        <Tab eventKey="info" title="General Information">
            <CountryPrimaryLanguageFormTabCountry data={props.data.country} language_id={props.language_id}/>
        </Tab>
        <Tab eventKey="economy" title="Economy">
            {props.data.economy
            ? <CountryPrimaryLanguageFormTabEconomy data={props.data.economy} language_id={props.language_id} />
            : <Card body>
                This Country has no economy data<br/>
                <Button onClick={()=>props.create.economy()}>Add Economy</Button>
            </Card>}
        </Tab>
        <Tab eventKey="politics" title="Politics">
            {props.data.politics
            ? <CountryPrimaryLanguageFormTabPolitics data={props.data.politics} language_id={props.language_id} />
            : <Card body>
                This Country has no politics data<br/>
                <Button onClick={()=>props.create.politics()}>Add Politics</Button>
            </Card>}
        </Tab>
        <Tab eventKey="travel" title="Travel Information">
            {props.data.travel
            ? <CountryPrimaryLanguageFormTabTravel data={props.data.travel} language_id={props.language_id} />
            : <Card body>
                This Country has no travel data<br/>
                <Button onClick={()=>props.create.travel()}>Add Travel</Button>
            </Card>}
        </Tab>
        {/* <Tab eventKey="regions" title="Regions">
            {props.regions
            ? <CountryPrimaryLanguageFormTabRegions
                regions={props.regions}
                language_id={props.language_id}
                removeRegion={props.removeRegion}
                addRegion={props.addRegion}
            />
            : <Card body>
                This Country has no region data<br/>
                <Button onClick={props.create.regions}>Add Regions</Button>
            </Card>}
        </Tab> */}
    </Tabs>
    );
}
