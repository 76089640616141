import React, { FC } from 'react';
import { Pencil } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';

export const EditButton: FC<CustomButtonProptypes> = ({
  // id = 'edit-button',
  color = 'DarkCyan',
  fontSize = '1rem',
  tooltip= "Edit",
  ...props
}) => {
  return <GenericButton
    // id={id}
    color={color}
    tooltip={tooltip}
    {...props}
  >
    <Pencil color={color} fontSize={fontSize} />
  </GenericButton>
}
