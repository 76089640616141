import React, { FC } from 'react';
import { useGetAllSectorsQuery } from '../../../services';
import { useAppSelector } from '../../../helpers';


export const Sectors: FC<{
  sectors: number[];
}> = (props) => {
  const { data: allSectors, isLoading: sectorsIsLoading, isError: sectorsIsError } = useGetAllSectorsQuery(0);

  return (<>
    {props.sectors && allSectors?.items && allSectors.items.map(pair => {
      let out = '';
      if (props.sectors.includes(pair.rootSector.id)) out += pair.rootSector.name;
      let subSectors = pair.subSectors.map(sector => props.sectors.includes(sector.id) ? sector.name : false).filter(e => !!e).join(', ');
      if (subSectors) out += out ? (', ' + subSectors) : subSectors;
      return out;
    }).filter(e => !!e).join(', ')}
  </>);
}
