import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, FormGroup } from "react-bootstrap";
import { Link, useParams, useLocation } from "react-router-dom";
import { alertActions, countryActions } from '../../../actions';
import { CountryPrimaryLanguageForm, CountryTranslateForm } from './';
import { cloneDeep, clone } from "lodash";
import { alertConstants } from '../../../constants';
import { getPublicUrl, useAppDispatch, useAppSelector } from '../../../helpers';
import { countryService } from '../../../services';
import { setKeyvisual } from '../../../slices/keyvisual.slice';

export function CountryForm(props) {
  const { country_id } = useParams();
  const location = useLocation();
  const allLanguages = useAppSelector(state => state.allLanguages);
  const dispatch = useAppDispatch();

  const [key, setKey] = useState(location.state?.key || 'info');
  const [regionsKey, setRegionsKey] = useState(-1);
  const incrementRegionKey = () => {
    const k = regionsKey + 1;
    setRegionsKey(k);
    return k;
  }

  const [translating, setTranslating] = useState(false);
  const [translatingTo, setTranslatingTo] = useState(null);
  // const [languageToAdd, setLanguageToAdd] = useState(null);
  // const [languageToRemove, setLanguageToRemove] = useState(null);


  const set = {};
  const [data, setData] = useState(cloneDeep(props.data) || {
    primary_language: {},

    // languages: {},
  });
  [data.country, set.country] = useState(cloneDeep(props.data?.country) || {
    text_capital: {},
    text_currency: {},
    text_ethnic_groups: {},
    text_full_name: {},
    text_languages: {},
    text_name: {},
    text_religions: {},
  });
  [data.economy, set.economy] = useState(cloneDeep(props.data?.economy)
    // || {
    //     text_main_exports: {},
    // }
  );
  [data.politics, set.politics] = useState(cloneDeep(props.data?.politics)
    // || {
    //     text_regime_type: {},
    //     text_press_freedom: {},
    //     text_human_rights_record: {},
    //     text_legal_system: {},
    //     text_independence: {},
    // }
  );
  [data.regions, set.regions] = useState(cloneDeep(props.data?.regions)
    // || []
  );
  [data.travel, set.travel] = useState(cloneDeep(props.data?.travel)
    // || {
    //     text_entry_exit_requirements: {},
    //     text_immunization_requirements: {},
    //     text_additional_risks: {},
    //     text_tourist_information: {},
    //     text_climate: {},
    //     text_travel_time: {},
    // }
  );
  const [create, setCreate] = useState({
    economy: () => set.economy({
      text_main_exports: {},
    }),
    politics: () => set.politics({
      text_regime_type: {},
      text_press_freedom: {},
      text_human_rights_record: {},
      text_legal_system: {},
      text_independence: {},
    }),
    regions: () => set.regions([]),
    travel: () => set.travel({
      text_entry_exit_requirements: {},
      text_immunization_requirements: {},
      text_additional_risks: {},
      text_tourist_information: {},
      text_climate: {},
      text_travel_time: {},
    }),
  });

  [data.primary_language, set.primary_language] = useState(cloneDeep(props.data?.primary_language) || null);
  // [data.languages, set.languages] = useState(cloneDeep(props.data?.languages) || {});

  [data.country.flag, set.flag] = useState(props.data?.country.flag || '');

  const addRegion = () => {
    set.regions([...data.regions, {
      key: incrementRegionKey(),
      text_name: [],
      text_description: [],
    }]);
  };
  const removeRegion = region => set.regions(cloneDeep(data.regions.filter(r => r.key != region.key)));

  const [initialized, setInitialized] = useState(false);
  if (!initialized && allLanguages.items) {
    if (props.data) setData(cloneDeep(props.data));
    setInitialized(true);
  }
  if (initialized && regionsKey === -1) {
    let k = 0;
    set.regions(data.regions.map(element => {
      return {
        ...element,
        key: ++k,
      };
    }));
    setRegionsKey(k);
  }
  // if (initialized && !languageToAdd) {
  //     const findLanguage = findFirstAddableLanguage();
  //     if (findLanguage) setLanguageToAdd(findLanguage);
  // }
  // if (initialized && !languageToRemove) {
  //     const findLanguage = data.languages[0];
  //     if (findLanguage) setLanguageToRemove(findLanguage);
  // }
  if (initialized && !translatingTo) {
    const findLanguage = findFirstTranslatableLanguage();
    if (findLanguage) setTranslatingTo(findLanguage);
  }
  if (initialized && !data.primary_language && allLanguages.items) {
    const findLanguage = allLanguages.items[0];
    if (findLanguage) set.primary_language(findLanguage);
    if (findLanguage && findLanguage.id == translatingTo?.id) setTranslatingTo(null);
  }

  // const findFirstAddableLanguage = () => {
  //     return allLanguages.items.find(lang1=>!data.languages.find(lang2=>lang1.id==lang2.id));
  // }
  // const findAddableLanguages = () => {
  //     return allLanguages.items.filter(lang1=>!data.languages.find(lang2=>lang1.id==lang2.id));
  // }
  function isTranslatableLanguage(language) {
    return data.primary_language && data.primary_language.id != language?.id
      // && data.languages.find(lang=>lang.id==language?.id)
      ;
  }
  function findFirstTranslatableLanguage() {
    return data.primary_language && allLanguages.items.find(lang1 => lang1.id != data.primary_language.id);
    //  && data.languages.find(lang1=>lang1.id != data.primary_language.id);
  }
  function findTranslatableLanguages() {
    return data.primary_language && allLanguages.items.filter(lang1 => lang1.id != data.primary_language.id);
    // && data.languages.filter(lang1=>lang1.id != data.primary_language.id);
  }
  // const addLanguage = (language) => {
  //     if (!language) return dispatch(alertActions.error('Adding Language failed: '+language));
  //     if (data.languages.length == 0) set.primary_language(language);
  //     set.languages([...data.languages, language]);
  // }
  // const removeLanguage = (language) => {
  //     if (!language) return dispatch(alertActions.error('Removing Language failed. language: '+JSON.stringify(language)));
  //     let success = false;
  //     set.languages(data.languages.filter(lang=>{
  //         const out = lang.id!=language?.id
  //         if (out) success = true;
  //         return out;
  //     }));
  //     if (language?.id && language.id == data.primary_language?.id) set.primary_language(null);
  //     if (language?.id && language.id == translatingTo) {
  //         setTranslating(false);
  //         setTranslatingTo(null);
  //     }
  //     if (!success) return dispatch(alertActions.error('Removing Language failed. Could not find language with same id in data.languages. Language: '+JSON.stringify(language)));
  // }

  const [validated, setValidated] = useState(false);
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false || !data.country.text_name[data.primary_language.id]) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      data.name = data.country.text_name[data.primary_language.id]
      setValidated(true);
    }
  };
  const setKeyvisualState = (k) => {
    setKey(k);
    props.setKey(k);
    switch (k) {
      case "info":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/embassies_default.jpg",
          title: "Information"
        }));
        break;
      case "economy":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/economic_default.jpg",
          title: "Economy"
        }));
        break;
      case "politics":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/politics_default.jpg",
          title: "Politics"
        }));
        break;
      case "travel":
        dispatch(setKeyvisual({
          url: "fileadmin/content/countries/_default/travel_default.jpg",
          title: "Travel"
        }));
        break;

      default:
        break;
    }
  }
  if (validated) props.handleData(data);

  const uploadFlag = (file) => {
    let formdata = new FormData();
    formdata.append('file', file);
    countryService.uploadFlag(data.id, formdata)
      .then(
        resp => {
          if (resp.flag) {
            set.flag(resp.flag);
          }
          dispatch(alertActions.success('Upload successful'));
        },
        error => {
          dispatch(alertActions.error(error.toString()));
        }
      );
  }

  return ((initialized || null) && <Form noValidate validated={validated} onSubmit={handleSubmit}>
    {data.id && <>
      <Form.Group id="upload-flag">
        <Image height="200" style={{
          maxWidth: 500
        }} src={getPublicUrl(data.country.flag || `fileadmin/templates/assets/images/placeholder_photo.png`)} />
        <Form.Label>Upload Organization Logo:</Form.Label>
        <Form.Control
          type="file"
          id="upload-flag-input" name="upload-flag-input"
          onChange={e => uploadFlag(e.target.files[0])}
        />
      </Form.Group>
      <br />
    </>}
    <Form.Group controlId="exampleForm.ControlSelect1">
      {/* {allLanguages.items && <Row>
                <Col key="languageToAdd">
                    <Form.Select disabled={!languageToAdd} value={languageToAdd?.id || ''}
                    onChange={e=>setLanguageToAdd(allLanguages.items.find(lang=>lang.id==e.target.value))}>
                        {findAddableLanguages().map(lang=><option key={lang.id} value={lang.id}>{lang.language}</option>)}
                    </Form.Select>
                </Col>
                <Col key="addLanguage"><Button disabled={!languageToAdd} onClick={e=>{
                    addLanguage(languageToAdd);
                    setLanguageToAdd(null);
                }}>Add Language</Button></Col>
                <Col key="languageToRemove">
                    <Form.Select disabled={!languageToRemove} value={languageToRemove?.id || ''}
                        onChange={e=>setLanguageToRemove(data.languages.find(lang=>lang.id==e.target.value))}
                    >
                        {data.languages.map(lang=><option key={lang.id} value={lang.id}>{lang.language}</option>)}
                    </Form.Select>
                </Col>
                <Col key="removeLanguage"><Button variant="danger" disabled={!languageToRemove} onClick={e=>{
                    removeLanguage(languageToRemove);
                    setLanguageToRemove(null);
                }}>Remove Language</Button></Col>
            </Row>}
            <br /> */}
      <Row>
        <Col md="2" key="defaultLanguage"><Form.Label>Default Language :</Form.Label></Col>
        <Col md="3" key="select_primary_language">
          {data.primary_language ? <Form.Select
            disabled={!data.primary_language}
            value={data.primary_language?.id}
            onChange={e => {
              if (translatingTo?.id == e.target.value) setTranslatingTo(data.primary_language);
              set.primary_language(allLanguages.items.find(lang => lang.id == e.target.value));
              // set.primary_language(data.languages.find(lang=>lang.id==e.target.value));
            }}
          >
            {allLanguages.items.map(lang => <option key={lang.id} value={lang.id}>{lang.language}</option>)}
            {/* {data.languages.map(lang=><option key={lang.id} value={lang.id}>{lang.language}</option>)} */}
          </Form.Select> : 'Add language to chose from'}
        </Col>
        <Col md={!translatingTo ? "auto" : "2"} key="TranslatingCheck">
          <Form.Check disabled={!translatingTo} type='checkbox' id={`inline-checkbox-1`} value={translating}
            label={translating ? 'Translating to:' : findFirstTranslatableLanguage() ? 'Translate' : 'Add another Language to translate to'}
            onChange={e => {
              if (translating) setTranslating(false);
              else if (translatingTo && translatingTo.id != data.primary_language?.id && allLanguages.items.find(lang => lang.id == translatingTo.id))
                // && data.languages.find(lang=>lang.id==translatingTo.id))
                setTranslating(true);
              else {
                if (!isTranslatableLanguage(translatingTo)) setTranslatingTo(findFirstTranslatableLanguage());
                setTranslating(true);
              }
            }}
          />
        </Col>
        {translating && translatingTo && <Col key="languageToAdd">
          <Form.Select
            disabled={!translatingTo}
            value={translatingTo?.id || ''}
            onChange={e => setTranslatingTo(allLanguages.items.find(lang => lang.id == e.target.value))}
          // onChange={e=>setTranslatingTo(data.languages.find(lang=>lang.id==e.target.value))}
          >
            {findTranslatableLanguages().map(lang => <option key={lang.id} value={lang.id}>{lang.language}</option>)}
          </Form.Select>
        </Col>}
      </Row>
    </Form.Group>
    {!translating && data.primary_language && <CountryPrimaryLanguageForm
      id={`primary_language_form_${data.primary_language.id}`}
      key={`primary_language_${data.primary_language.id}`}
      data={data} regions={data.regions} language_id={data.primary_language.id}
      create={create} set={set}
      addRegion={addRegion} removeRegion={removeRegion}
      setKeyvisual={setKeyvisualState}
    />}
    {translating && data.primary_language && translatingTo && <CountryTranslateForm
      id={`translate_form_${data.primary_language.id}_${translatingTo.id}`}
      key={`translate_form_${data.primary_language.id}_${translatingTo.id}`}
      data={data} regions={data.regions}
      primary_language_id={data.primary_language.id} translate_language_id={translatingTo.id}
      create={create} set={set}
      addRegion={addRegion} removeRegion={removeRegion}
      setKeyvisual={setKeyvisualState}
    />}
    <Button key="discard" variant="danger" onClick={() => props.handleData({ discard: true })}>Discard</Button>
    <Button key="apply" variant="success" onClick={apply()}>Apply</Button>
  </Form>);

  function apply() {
    return () => {
      data.country.name = data.country.text_name[data.primary_language.id];
      props.handleData(data);
    };
  }
}
