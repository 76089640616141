import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { OrganizationListData, SearchOptions } from '../organizations';
import { organizationPolicies } from '../../authorization';
import {
  useDeleteOrganizationMutation,
  useGetCurrentUserOrganizationListQuery,
  useGetCurrentUserQuery,
  useGetOrganizationListQuery,
  useHideOrganizationMutation,
  useUnhideOrganizationMutation,
} from '../../services';
import { CurrentUserOrganizationListFilter, ListQuery } from '../../types';
import { useAppDispatch } from '../../helpers';
import { setKeyvisualTitle } from '../../slices/keyvisual.slice';

export function CurrentUserOrganizationList({ ...props }) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [params, setParams] = useState({
    page: 1,
    perPage: 20,
    // sort: ['name'],
    filter: {
      organizationType: 0,
      country: 0,
      sector: 0,
      name: '',

      createdBefore: undefined,
      createdSince: undefined,

      updatedBefore: undefined,
      updatedSince: undefined,

      showIsHidden: false,
      showIsVisible: true,

      showOwned: true,
      showNotOwned: true,

      showHasLogo: true,
      showHasNoLogo: true,

      showHasKeyvisual: true,
      showHasNoKeyvisual: true,
    }
  } as ListQuery<CurrentUserOrganizationListFilter>);
  const {
    data: currentUser,
    error: currentUserError,
    isLoading: currentUserIsLoading,
    isError: currentUserIsError,
    isSuccess: currentUserIsSuccess,
  } = useGetCurrentUserQuery();
  const {
    data: organizations,
    error: orgsError,
    isError: orgsIsError, isSuccess: orgsIsSuccess, isLoading: orgsIsLoading,
    // refetch: refetchOrganizations,
  } = useGetCurrentUserOrganizationListQuery(params);
  const [deleteOrg, { isLoading: deleteOrgIsLoading, error: deleteOrgError }] = useDeleteOrganizationMutation();
  const [hideOrg, { isLoading: hideOrgIsLoading, error: hideOrgError }] = useHideOrganizationMutation();
  const [unhideOrg, { isLoading: unhideOrgIsLoading, error: unhideOrgError }] = useUnhideOrganizationMutation();

  useEffect(() => {
    dispatch(setKeyvisualTitle("My Organizations"))
  }, []);

  return (
    <OrganizationListData
      title="My Organizations"
      currentUser={currentUser}
      showRelationFilter

      data={organizations?.items}
      filter={organizations?.filter}
      pagination={organizations?.pagination}

      onChange={(p) => setParams(p)}

      isLoading={orgsIsLoading || currentUserIsLoading}
      isError={orgsIsError || currentUserIsError}
      isSuccess={orgsIsSuccess || currentUserIsSuccess}
      error={deleteOrgError || hideOrgError || unhideOrgError || orgsError || currentUserError}

      canDeleteOrganization={(org) => organizationPolicies.deleteOrg(currentUser, org)}
      canEditOrganization={(org) => organizationPolicies.update(currentUser, org)}
      canViewOrganizationUsers={(org) => organizationPolicies.viewAnyUser(currentUser, org)}

      hideOrganization={(org_id) => hideOrg({ org_id })}
      unhideOrganization={(org_id) => unhideOrg({ org_id })}
      deleteOrganization={(org_id) => deleteOrg({ org_id })}

    />
  );
}
