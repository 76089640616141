import React, { useState, useEffect, FC } from 'react';
import { Card, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useGetCurrentUserQuery } from '../../services';
import { EditableInput } from '../../components/Input';
import { useAppDispatch } from '../../helpers';

const CurrentUserProfile: FC<{}> = () => {
  const {
    data: currentUser,
    error: currentUserError,
    isError: currentUserIsError,
    isLoading: currentUserIsLoading,
    isSuccess: currentUserIsSuccess,
  } = useGetCurrentUserQuery();

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {

  }
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {

  }
  const isInvalid = false;

  return (
    <Card body>
      <div className="col-lg-8 offset-lg-2">
        <h2>Edit</h2>
        <Table>
          <tbody>
            <tr>
              <td className='w-25'><label htmlFor='username-input'>Name</label></td>
              <td className='w-75'><EditableInput
                id='username-input'
                type='text'
                disabled={!currentUser}
                initialValue={currentUser?.name}
                onSubmit={(value) => {
                  console.log('submit name', value);
                }}
              /></td>
            </tr>
            <tr>
              <td className='w-25'><label htmlFor='email-input'>Email</label></td>
              <td className='w-75'><EditableInput
                id='email-input'
                type='email'
                disabled={true || !currentUser}
                initialValue={currentUser?.email}
                onSubmit={(value) => {
                  console.log('submit email', value);
                }}
              /></td>
            </tr>
            <tr>
              <td className='w-25'><label htmlFor='password-input'>Password</label></td>
              <td className='w-75'>

              </td>
            </tr>
          </tbody>
        </Table>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input type="text" name="email" value={currentUser?.email || ''} onChange={handleChange} className={`form-control ${isInvalid ? 'is-invalid' : ''}`} />
            <div className="invalid-feedback">Username is required</div>
          </div>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" value={'user.name'} onChange={handleChange} className={'form-control' + (isInvalid ? ' is-invalid' : '')} />
            {isInvalid &&
              <div className="invalid-feedback">First Name is required</div>
            }
          </div>
          <div className="form-group">
            <button className="btn btn-primary" type='submit'>
              {true && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Update
            </button>
            <Link to="/" className="btn btn-link">Cancel</Link>
          </div>
        </form>
      </div>
    </Card>
  );
}

export { CurrentUserProfile };
