import React, { FC, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { ErrorPageProptypes } from '.';
import { Helmet } from 'react-helmet';

export const NotFound: FC<ErrorPageProptypes> = ({
  message = "We couldn't find this page or resource.",
  ...props
}) => {

  const location = useLocation();
  const { from } = location.state || {};
  return <Card body>
    <Helmet>
      <title>{`404 Not Found | ${APP_NAME}`}</title>
      <meta name="description" content={message} />
    </Helmet>
    <h1>Error 404: Not Found</h1>
    <p>{message}</p>
    <noindex />
    <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
  </Card>
}
