import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import { Icon, LatLng, LatLngExpression, LatLngTuple, latLng } from 'leaflet';
import { Button, Card, Modal } from 'react-bootstrap';
import { CustomMap, DraggableMarker, LeafletControlGeocoder } from '.';
import { OrganizationContact, SearchAddress } from '../../types';
import { AddButton, CancelButton, DeleteButton, EditButton, GenericButton } from '../Button';
import { Property } from 'csstype';
import { PencilFill, PencilSquare, TrashFill } from 'react-bootstrap-icons';

export interface CustomMapButtonProps {
  id: string;
  marker?: LatLngExpression;
  address?: SearchAddress;
  canEdit?: boolean;

  width: Property.Width;
  height: Property.Height;

  onSubmit?: (result: LatLng | null) => void;
}
export const CustomMapButton: FC<CustomMapButtonProps> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [internalMarker, setInternalMarker] = useState(props.marker ? latLng(props.marker) : null);
  const markerChanged = useMemo(() => {
    return internalMarker?.toString() !== props.marker?.toString();
  }, [internalMarker?.toString(), props.marker?.toString()]);


  function closeModal() {
    setShowModal(false);
  }

  if (!props.marker && !props.canEdit) {
    return <>Nothing to do here</>;
  }

  return (
    <>
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {markerChanged ? <Button variant="primary" onClick={() => {
              props.onSubmit?.(internalMarker);
              closeModal();
            }}>Submit</Button> : null}{' '}
            Coordinates
            {markerChanged ? <Button variant="danger" onClick={() => {
              setInternalMarker(props.marker ? latLng(props.marker) : null);
            }}>Reset</Button> : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomMap
            initialMarker={internalMarker ?? undefined}
            initialCenter={internalMarker ?? undefined}
            initialAddress={props.address}
            initialZoom={props.marker ? 16 : undefined}
            onChange={(coordinates) => {
              setInternalMarker(coordinates);
            }}
            disabled={!props.canEdit}
            width={"25rem"} height={"25rem"}
          />
        </Modal.Body>
      </Modal>
      {props.marker ? (
        <div id={`${props.id}-container`} style={{
          position: "relative",
          width: props.width, height: props.height,
        }}>
          <CustomMap
            initialMarker={props.marker}
            initialCenter={props.marker}
            initialAddress={props.address}
            initialZoom={props.marker ? 16 : undefined}
            disabled
            width={props.width} height={props.height}
          />
          {props.canEdit ? <div
            style={{
              position: "absolute",
              top: "0.5rem",
              right: "0.5rem",
              zIndex: 1000,
            }}
          >
            <GenericButton
              id={`${props.id}-edit-button`}
              color={"teal"}
              tooltip={"Edit Coordinates"}
              onClick={() => {
                setShowModal(true);
              }}
            >
              <PencilSquare color={"teal"} fontSize={20} />
            </GenericButton>{' '}
            <GenericButton
              id={`${props.id}-delete-button`}
              color={"red"}
              tooltip={"Remove Coordinates"}
              onClick={() => {
                props.onSubmit?.(null);
              }}
            >
              <TrashFill color={"red"} fontSize={20} />
            </GenericButton>
          </div> : null}
        </div>
      ) : (
        <Button
          id={`${props.id}-add-button`}
          onClick={() => {
            setShowModal(true);
          }}
        >Add Coordinates</Button>
      )}
    </>
  );
};
