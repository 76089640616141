import React, { FC, createContext, useEffect, useState } from 'react';
import { Route, useLocation, matchPath, useParams } from 'react-router-dom';
import { Forbidden, Loading, Unauthenticated } from '../errors';
import { countryActions } from '../actions';
import { useLazyGetCountryQuery, useLazyGetCurrentUserQuery, useLazyGetOrganizationQuery } from '../services';
import { useAppDispatch, useAppSelector } from '../helpers';
import { RouteConfig } from '../config/routes';
import { setKeyvisualTitle, setKeyvisualUrl } from '../slices/keyvisual.slice';

export function getPathParameterNames(path: string) {
  if (!path) return [];
  return path.split('/').filter(value => value.startsWith(':')).map(value => value.slice(1));
}

const AuthMiddleware: FC<{ route: RouteConfig }> = ({
  route
}) => {
  const dispatch = useAppDispatch();
  const { org_id, org_name, country_id, user_id } = useParams();

  const { loggedIn, token, user } = useAppSelector(state => state.authentication);

  // const [getCurrentUser, { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading }] = useLazyGetCurrentUserQuery();
  const [getCurrentUser, currentUser] = useLazyGetCurrentUserQuery();

  const [getOrganization, { data: organization, error: orgError, isLoading: orgIsLoading }] = useLazyGetOrganizationQuery();
  const [getCountry, { data: country, error: countryError, isLoading: countryIsLoading }] = useLazyGetCountryQuery();
  useEffect(() => {
    if (route.title === '') {
      dispatch(setKeyvisualTitle(route.title));
    }
  }, [route.title]);

  useEffect(() => {
    if (organization?.name) {
      dispatch(setKeyvisualTitle(organization.name));
    }
    if (organization?.keyvisual) {
      dispatch(setKeyvisualUrl(organization.keyvisual));
    }
  }, [organization?.name, organization?.keyvisual])

  useEffect(() => {
    if (loggedIn && token) {
      getCurrentUser();
    }
  }, [loggedIn, token]);

  useEffect(() => {
    if (org_id || org_name) {
      getOrganization({ org_id, org_name }, true);
    }
  }, [org_id, org_name]);
  useEffect(() => {
    if (country_id) {
      getCountry({ country_id }, true);
    }
  }, [country_id]);
  // const success = () => {
  //   return <route.Component />;
  // };
  const failure = (C: React.FC | React.ElementType) => <C />;

  if (!route.authenticate) {
    return <route.Component />;
  }
  if (currentUser.isLoading) {
    return failure(Loading);
  }
  if (currentUser.isError || !currentUser.data) {
    return failure(Unauthenticated);
  }
  if (!route.authorize) {
    return <route.Component currentUser={currentUser.data} />;
  }
  // if (!matchPath(path, location.pathname)) {
  //   return failure(Loading);
  // }
  const params = getPathParameterNames(route.path);
  const args = params.map((param) => {
    switch (param) {
      case 'org_id':
      case 'org_name':
        return organization;
      case 'country_id':
        return country;
      case 'user_id':
        return user_id;
      default:
        return undefined;
    }
  });
  if (!route.authorize(currentUser.data, ...args)) {
    return failure(Forbidden);
  }
  return <route.Component currentUser={currentUser.data} />;
}

export { AuthMiddleware };
