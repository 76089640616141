
import { To } from 'react-router-dom';

export function getOrganizationUrl(urlName: string) : string {
  if (!urlName) return '';
  return `${APP_URL}/${urlName}`;
}
export function getOrganizationUrlPath(url_name: string, org_id: string | number) : string {
  if (url_name) return `/${url_name}`;
  if (org_id) return `/orgs/${org_id}`;
  return '';
}

// following both from locutus package
export function phpEncodeUrl(str: string) {
  return encodeURIComponent(str)
    .replace(/!/g, '%21')
    .replace(/'/g, '%27')
    .replace(/\(/g, '%28')
    .replace(/\)/g, '%29')
    .replace(/\*/g, '%2A')
    .replace(/~/g, '%7E')
    .replace(/%20/g, '+')
    ;
}
export function phpDecodeUrl(str: string) {
  return decodeURIComponent((str + '')
  .replace(/%(?![\da-f]{2})/gi, function () {
    // PHP tolerates poorly formed escape sequences
    return '%25';
  })
  .replace(/\+/g, '%20'))
}
