import React, { FC, MouseEventHandler } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';

export interface ConfirmModalProps {
  show?: boolean,
  close?: boolean,
  confirm?: boolean,

  title?: React.ReactNode,
  body?: React.ReactNode,
  cancelText?: React.ReactNode,
  confirmText?: React.ReactNode,
  showCancel?: boolean,
  showConfirm?: boolean,

  onCancel?: () => void,
  onConfirm?: MouseEventHandler<HTMLButtonElement>,
}
export const defaultConfirmModalConfig: ConfirmModalProps = {
  show: false,
  close: true,
  confirm: true,

  title: <>
    <ExclamationTriangle color='#FFCC00' fontSize={40} /> {" "}
    Please Confirm
  </>,
  body: <>
    Are you sure, you want to do this?<br />
    You might not be able to reverse this action.
  </>,
  cancelText: 'Cancel',
  confirmText: 'Confirm',
  showCancel: true,
  showConfirm: true,
}
export const ConfirmModal: FC<ConfirmModalProps> = ({
  show = defaultConfirmModalConfig.show,
  close = defaultConfirmModalConfig.close,

  cancelText = defaultConfirmModalConfig.cancelText,
  confirmText = defaultConfirmModalConfig.confirmText,
  showCancel = defaultConfirmModalConfig.showCancel,
  showConfirm = defaultConfirmModalConfig.showConfirm,

  title = defaultConfirmModalConfig.title,
  body = defaultConfirmModalConfig.body,

  onCancel = defaultConfirmModalConfig.onCancel,
  onConfirm = defaultConfirmModalConfig.onConfirm,
}) => {
  return <Modal
    show={show}
    onHide={onCancel}
    keyboard={close}
  >
    <Modal.Header closeButton={close}>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>
      {showCancel && <Button variant="secondary" onClick={onCancel}>
        {cancelText}
      </Button>}
      {showConfirm && <Button variant="primary" onClick={onConfirm}>
        {confirmText}
      </Button>}
    </Modal.Footer>
  </Modal>
}
