import React, { SVGAttributes, useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import type * as CSS from 'csstype';
import { BasePlacement, Placement } from '@popperjs/core';

export interface CustomTooltipPropTypes {
  arrowProps?: any,
  backgroundColor?: CSS.Property.Color,
  color?: CSS.Property.Color,
  opacity?: 1,
  children?: React.ReactNode,
  target: HTMLElement|null,
  show?: boolean,
  placement?: Placement,
}
export function CustomTooltip({
  arrowProps,
  backgroundColor = "black",
  color = "white",
  opacity = 1,
  children,
  target,
  show = true,
  placement = "top",
  ...props
}: CustomTooltipPropTypes) {
  return <Overlay target={target} show={!!children && show} placement={placement}>
    {({ placement: _placement, arrowProps, show: _show, popper, ...props }) => (
      <div
        {...props}
        style={{
          position: 'absolute',
          padding: '2px 10px',
          borderRadius: 3,

          backgroundColor: backgroundColor,
          color,
          opacity: opacity,

          ...props.style,
        }}
      >
        {children}
      </div>
    )}
  </Overlay>
}
