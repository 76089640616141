import React, { useState } from 'react';
import { Card, Nav, Button, Form, Spinner } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import { countryActions } from '../../actions';
import { CountryForm } from './forms';

import { useAppDispatch, useAppSelector } from '../../helpers';
import { GoBackButton } from '../../components/Button';
import { useGetCountryDeprecatedQuery } from '../../services';
import { resetKeyvisual } from '../../slices/keyvisual.slice';

export function CountryEdit(props) {
  const { country_id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();
  // const country = useAppSelector(state => state.country);
  const { data: country, isLoading: countryLoading, isError: countryIsError, error: countryError } = useGetCountryDeprecatedQuery({ country_id });
  const dispatch = useAppDispatch();

  const loaded = country.data && country.data.id == country_id;

  const [key, setKey] = useState(location.state?.key || 'info');

  const handleData = (data) => {
    console.log(data);
    if (data && !data.discard) {
      if (data.delete) {
        if (data.id) dispatch(countryActions.delete(data.id));
        navigate(`/countries/${country_id}`);
        return;
      }
      dispatch(countryActions.update(data));
      dispatch(resetKeyvisual());
      navigate(`/countries/${country_id}`);
    } else {
      navigate(`/countries/${country_id}`);
    }
  }
  if (!country || !(country.loading || loaded || country.error
    || (country.deleted && country.id == country_id))) dispatch(countryActions.getById(country_id));


  return (<Card body>
    <h3>
      <GoBackButton onClick={() => {
        navigate(`/countries/${country_id}`);
        dispatch(countryActions.clear());
        dispatch(resetKeyvisual());
      }} />
      {country.loading && <Spinner animation="border" />}
      {country.error && <>{' Error'} <Button onClick={() => { dispatch(countryActions.getById(country_id)); dispatch(resetKeyvisual()); }}>Reload</Button></>}
      {country.deleted && country.id == country_id && ' Country deleted'}
      {loaded && ' ' + country.data.country.name + ' '}
    </h3>
    {loaded && <CountryForm data={country.data} handleData={handleData} setKey={setKey} />}
  </Card>);
}


