
import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Table } from "react-bootstrap";

export function CountryPrimaryLanguageFormTabTravel(props) {

    const [data, setData] = useState(props.data);
    const set = {};

    [data.tourist_information, set.tourist_information] = useState(props.data.tourist_information || '');
    [data.text_entry_exit_requirements[props.language_id], set.text_entry_exit_requirements] = useState(props.data.text_entry_exit_requirements[props.language_id] || '');
    [data.text_immunization_requirements[props.language_id], set.text_immunization_requirements] = useState(props.data.text_immunization_requirements[props.language_id] || '');
    [data.text_additional_risks[props.language_id], set.text_additional_risks] = useState(props.data.text_additional_risks[props.language_id] || '');
    [data.text_climate[props.language_id], set.text_climate] = useState(props.data.text_climate[props.language_id] || '');
    [data.text_travel_time[props.language_id], set.text_travel_time] = useState(props.data.text_travel_time[props.language_id] || '');

    return (
        <Card body>
            <Row>
                <Col md="2">Entry/Exit requirements :</Col>
                <Col><Form.Group controlId="travel_text_entry_exit_requirements">
                    <Form.Control type="text" as="textarea"
                        value={data.text_entry_exit_requirements[props.language_id]}
                        onChange={e=>set.text_entry_exit_requirements(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Immunization requirements :</Col>
                <Col><Form.Group controlId="travel_text_immunization_requirements">
                    <Form.Control type="text" as="textarea"
                        value={data.text_immunization_requirements[props.language_id]}
                        onChange={e=>set.text_immunization_requirements(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Additional risks :</Col>
                <Col><Form.Group controlId="travel_text_additional_risks">
                    <Form.Control type="text" as="textarea"
                        value={data.text_additional_risks[props.language_id]}
                        onChange={e=>set.text_additional_risks(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Climate :</Col>
                <Col><Form.Group controlId="travel_text_climate">
                    <Form.Control type="text" as="textarea"
                        value={data.text_climate[props.language_id]}
                        onChange={e=>set.text_climate(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Travel time :</Col>
                <Col><Form.Group controlId="travel_text_travel_time">
                    <Form.Control type="text"
                        value={data.text_travel_time[props.language_id]}
                        onChange={e=>set.text_travel_time(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Tourist Information :</Col>
                <Col><Form.Group controlId="travel_tourist_information">
                    <Form.Control type="text"
                        value={data.tourist_information}
                        onChange={e=>set.tourist_information(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
        </Card>
    );
}
