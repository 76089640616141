
import { CaseReducer, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../helpers';
import { Language } from '../types';

// export const setLanguage = createAsyncThunk(
//   'setLanguage',
//   (arg, thunkApi) => {
//     const allLanguages = (thunkApi.getState() as RootState).allLanguages;
//     if (allLanguages.items) {
//       return allLanguages.items.find(lang => lang.id === arg) || {
//         error: `Could not find language "${arg}" in allLanguages.items`
//       };
//     } else {
//       return {
//         error: `allLanguages.items does not exist`
//       };
//     }
//   }
// )

export const defaultLanguage = {
  id: 'en',
  language: 'English',
} as Language;

const languageSlice = createSlice({
  name: 'language',
  initialState: defaultLanguage,
  reducers: {
    resetLanguage: () => defaultLanguage,
    setLanguage: (state, { payload }: { payload: Language }) => {
      if (payload.id !== state.id || payload.language !== state.language) {
        return payload;
      }
      return state;
    }
  },
  // extraReducers: {
  //   [setLanguage.fulfilled]: (state, {payload}) => {
  //     if (payload?.error) {
  //       return state;
  //     }
  //     if (payload && payload.id && payload.name) return payload;
  //   }
  // }
});

export const { resetLanguage, setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
