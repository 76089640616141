
import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Table } from "react-bootstrap";

export function CountryPrimaryLanguageFormTabPolitics(props) {

    const [data, setData] = useState(props.data);
    const set = {};

    [data.text_regime_type[props.language_id], set.text_regime_type] = useState(props.data.text_regime_type[props.language_id] || '');
    [data.text_legal_system[props.language_id], set.text_legal_system] = useState(props.data.text_legal_system[props.language_id] || '');
    [data.text_independence[props.language_id], set.text_independence] = useState(props.data.text_independence[props.language_id] || '');
    [data.text_press_freedom[props.language_id], set.text_press_freedom] = useState(props.data.text_press_freedom[props.language_id] || '');
    [data.text_human_rights_record[props.language_id], set.text_human_rights_record] = useState(props.data.text_human_rights_record[props.language_id] || '');

    return (
        <Card body>
            <Row>
                <Col md="2">Regime type :</Col>
                <Col><Form.Group controlId="politics_text_regime_type">
                    <Form.Control type="text"
                        value={data.text_regime_type[props.language_id]}
                        onChange={e=>set.text_regime_type(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Legal system:</Col>
                <Col><Form.Group controlId="politics_text_legal_system">
                    <Form.Control type="text"
                        value={data.text_legal_system[props.language_id]}
                        onChange={e=>set.text_legal_system(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Independence :</Col>
                <Col><Form.Group controlId="politics_text_independence">
                    <Form.Control type="text"
                        value={data.text_independence[props.language_id]}
                        onChange={e=>set.text_independence(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Press Freedom Index :</Col>
                <Col><Form.Group controlId="politics_text_press_freedom">
                    <Form.Control type="text" as="textarea"
                        value={data.text_press_freedom[props.language_id]}
                        onChange={e=>set.text_press_freedom(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Human Rights record :</Col>
                <Col><Form.Group controlId="politics_text_human_rights_record">
                    <Form.Control type="text" as="textarea"
                        value={data.text_human_rights_record[props.language_id]}
                        onChange={e=>set.text_human_rights_record(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
        </Card>
    );
}
