
import React, { FC, FormEvent, useEffect, useMemo, useState } from 'react';
import { Alert, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { SaveButton } from '../../../components/Button';
import { getOrganizationUrl, handleFormEvent, phpDecodeUrl, phpEncodeUrl } from '../../../helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { useAutoSaveValue } from '../../../hooks';
import { BaseAPIError } from '../../../types';

export interface UrlNameFormProps {
  url_name: string,
  error?: BaseAPIError,
  autosave?: boolean,

  onSubmit?: (urlNameEncoded: string) => void,
  onAbort?: () => void,
  isSaving?: boolean,
  [key: string]: any
}
export const UrlNameForm: FC<UrlNameFormProps> = ({
  url_name,
  error,
  autosave = true,

  onSubmit,
  onAbort,
  isSaving = false,
  ...props
}) => {
  const { value: urlName, isDirty, handleChange, resetValue, saveManually } = useAutoSaveValue({
    initialValue: url_name,
    shouldAutosave: autosave,
    onSave: (newUrlName) => {
      if (!newUrlName) return;
      if (newUrlName === url_name) return;
      if (!checkValidUrlName(newUrlName)) return;
      onSubmit?.(newUrlName);
      setValidated(true);
    },
  });

  const [validated, setValidated] = useState(false);
  const isEmpty = useMemo(() => !urlName, [urlName]);
  // const isTaken = useMemo(() => (error && urlName === url_name) ?? false, [urlName, url_name, error]);
  const isInvalidUrlName = useMemo(() => !checkValidUrlName(urlName), [urlName]);

  const isInvalid = validated && (isEmpty || isInvalidUrlName || !!error);
  const isValid = validated && !isInvalid && !(isInvalidUrlName || isEmpty);

  useEffect(() => {
    console.debug('error', error);
  }, [error]);
  return <Form {...props}
    noValidate validated={validated && !isInvalid}
    onSubmit={handleFormSubmit} onAbort={handleFormEvent(handleAbort)}
  >
    <Row className="mb-3">
      {isSaving ? <Alert variant="warning" className="w-100">Saving...</Alert> : isDirty && <Alert variant="warning" className="w-100">Unsaved Changes</Alert>}
      <Form.Group as={Col} md="1" controlId="edit-orgs-url-name-label">
        <Form.Label id='edit-orgs-url-name'>Name</Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="10" controlId="edit-orgs-url-name-controlId">
        <Form.Control
          isValid={isValid && !isInvalid}
          isInvalid={isInvalid}
          type="text"
          placeholder="Name for Organization Url"

          value={urlName} onChange={handleFormChange} onBlur={handleFormBlur}

          aria-label="Organization Url"
          aria-describedby="edit-orgs-url-name"
        />
        <Form.Control.Feedback type="valid" >
          {url_name === urlName ? 'Success!' : 'Press Enter to check name.'}
        </Form.Control.Feedback>
        <Form.Control.Feedback type="invalid" title='Error' >
          {isEmpty && `Name is required.`}
          {isInvalidUrlName && `Only Charachters a-z, A-Z, 0-9, +, - and _ are allowed.`}
          {/* {isTaken && `Name is already Taken. Chose a different Name.`} */}
          {`${error?.data?.message}`}
        </Form.Control.Feedback>
      </Form.Group>
      {!autosave && <Form.Group as={Col} md="1" controlId="edit-orgs-url-name-save">
        <SaveButton id='save-button-organization.url_name' disabled={!checkValidity()} fontSize="2rem" type='submit' />
      </Form.Group>}
    </Row >
    <Row>
      <Form.Group as={Col} md="1" controlId="edit-orgs-url-preview-label">
        <Form.Label id='edit-orgs-url-name-preview'>Preview</Form.Label>
      </Form.Group>
      <Form.Group as={Col} md="11" controlId="edit-orgs-url-preview-controlId" >
        <Form.Control
          disabled
          value={getOrganizationUrl(urlName)}

          aria-label="Preview Full Organization Url"
          aria-describedby="edit-orgs-url-name-preview"
        />
      </Form.Group>
    </Row>
  </Form>

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.stopPropagation()
    e.preventDefault()
    if (urlName !== url_name && !isEmpty) {
      saveManually();
    }
  }
  function handleAbort() {
    onAbort?.();
  }

  function handleFormChange(e: React.ChangeEvent<HTMLInputElement>) {
    handleChange(e.target.value);
    setValidated(false);
  }
  function handleFormBlur(e: React.FocusEvent<HTMLInputElement>) {
    saveManually();
  }
  function checkValidity() {
    return !isEmpty && urlName !== url_name;
  }
}

function checkValidUrlName(str: string) {
  return new RegExp("^[a-zA-Z0-9\-\_\+]*$").test(str);
}

function checkTaken(urlName: string, url_name: string, error?: FetchBaseQueryError | SerializedError) {
  return (error && urlName === url_name) ?? false;
}
