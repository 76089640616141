
import React, { useState } from 'react';
import { Alert, Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Spinner, Badge, Accordion } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { countryRatingActions } from '../../../actions';
import { isInteger, isNumber, isObject } from 'lodash';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { useGetAllCountryRatingsQuery } from '../../../services';

function getArray(object) {
  const out = [];
  if (object && isObject(object)) {
    for (const key in object) {
      if (Object.hasOwnProperty.call(object, key) && key != 'title') {
        out.push(object[key]);
      }
    }
  }
  return out;
}

export function CountryProfileRatings(props) {
  const dispatch = useAppDispatch();
  const { data: allCountryRatings, isLoading: ratingsIsLoading, isError: ratingsIsError, isSuccess: ratingsIsSuccess } = useGetAllCountryRatingsQuery(0);
  const language = useAppSelector(state => state.language);

  if (props.data && allCountryRatings?.items) return (
    <Accordion>
      {allCountryRatings.items.map((value, index) =>
        <Card key={index}>
          <Card.Header>
            <Accordion.Button as={Button} variant="link" eventKey={'' + index}>
              {value['title'][language.id] || value['title']['en'] || 'No Title'}
            </Accordion.Button>
          </Card.Header>
          <Accordion.Collapse eventKey={'' + index}>
            <Card.Body>
              {getArray(value).map(rating => props.data[rating.id] && <Row key={rating.id}>
                <Col md={3}>
                  <br />
                  Rank {props.data[rating.id].rank_africa} in Africa
                </Col>
                <Col>
                  <br />
                  <Card.Title>{rating.text_title[language.id]}</Card.Title>
                  {rating.text_description[language.id]}
                </Col>
              </Row>)}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      )}
    </Accordion>
  );
  else return <></>;
}
