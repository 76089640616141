import { organizationConstants } from '../constants';
import { organizationService } from '../services';
import { alertActions } from './';

export const organizationActions = {
  clear,
  clearAll,
  update,
  getById,
  getAll,
  setFilter,
  store,
  // delete: _delete
  updateDeprecated,
  getByIdDeprecated,
};

function clear() {
  return { type: organizationConstants.CLEAR };
}
function clearAll() {
  return { type: organizationConstants.CLEAR_ALL };
}
function setFilter(filter) {
  return { type: organizationConstants.SET_FILTER, filter: filter };
}

function store(organization) {
  return dispatch => {
    dispatch(request(organization));

    organizationService.store(organization)
      .then(
        response => {
          dispatch(success(response));
          dispatch(alertActions.success('Organization created successfully'));
        },
        error => {
          dispatch(failure(error.toString(), organization));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: organizationConstants.STORE_REQUEST, data: data } }
  function success(response) { return { type: organizationConstants.STORE_SUCCESS, response: response } }
  function failure(error) { return { type: organizationConstants.STORE_FAILURE, error: error, data: organization } }
}

function updateDeprecated(organization) {
  return dispatch => {
    dispatch(request(organization));

    organizationService.updateDeprecated(organization)
      .then(
        organization => {
          dispatch(success(organization));
          dispatch(alertActions.success('Update successful'));
        },
        error => {
          dispatch(failure(error.toString(), organization));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: organizationConstants.UPDATE_REQUEST, id: organization.id, data: data } }
  function success(response) { return { type: organizationConstants.UPDATE_SUCCESS, id: organization.id, response: response } }
  function failure(error, data) { return { type: organizationConstants.UPDATE_FAILURE, id: organization.id, error: error, data: data } }
}
function update(organization) {
  return dispatch => {
    dispatch(request(organization));

    organizationService.update(organization)
      .then(
        organization => {
          dispatch(success(organization));
          dispatch(alertActions.success('Update successful'));
        },
        error => {
          dispatch(failure(error.toString(), organization));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(data) { return { type: organizationConstants.UPDATE_REQUEST, id: organization.id, data: data } }
  function success(response) { return { type: organizationConstants.UPDATE_SUCCESS, id: organization.id, response: response } }
  function failure(error, data) { return { type: organizationConstants.UPDATE_FAILURE, id: organization.id, error: error, data: data } }
}

function getByIdDeprecated(id) {
  return dispatch => {
    dispatch(request(id));

    organizationService.getByIdDeprecated(id)
      .then(
        response => dispatch(success(response)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: organizationConstants.GETBYID_REQUEST, id } }
  function success(response) { return { type: organizationConstants.GETBYID_SUCCESS, id, response } }
  function failure(id, error) { return { type: organizationConstants.GETBYID_FAILURE, id, error } }
}
function getById(id) {
  return dispatch => {
    dispatch(request(id));

    organizationService.getById(id)
      .then(
        response => dispatch(success(response)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: organizationConstants.GETBYID_REQUEST, id } }
  function success(response) { return { type: organizationConstants.GETBYID_SUCCESS, id, response } }
  function failure(id, error) { return { type: organizationConstants.GETBYID_FAILURE, id, error } }
}

function getAll(page, perPage, filter) {
  return dispatch => {
    dispatch(request(filter));

    organizationService.getAll(page, perPage, filter)
      .then(
        response => dispatch(success(response, filter)),
        error => dispatch(failure(error, filter))
      );
  };

  function request(filter) { return { type: organizationConstants.GETALL_REQUEST, filter: filter } }
  function success(response, filter) { return { type: organizationConstants.GETALL_SUCCESS, response: response, filter: filter } }
  function failure(error, filter) { return { type: organizationConstants.GETALL_FAILURE, error: error, filter: filter } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    organizationService.delete(id)
      .then(
        organization => dispatch(success(id)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: organizationConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: organizationConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: organizationConstants.DELETE_FAILURE, id, error } }
}
