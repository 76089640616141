import React, { FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { ErrorPageProptypes } from '.';

export const Loading : FC<ErrorPageProptypes> = ({
  message = "Plase wait or:",
  ...props
}) => {
  const { from } = useLocation().state || {};
  return <Card body>
    <h1>Loading...</h1>
    <p>{message}</p>
    <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
  </Card>
}
