import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Table } from "react-bootstrap";

export function CountryPrimaryLanguageFormTabCountry(props) {

  const [data, setData] = useState(props.data);
  const set = {};

  [data.text_capital[props.language_id], set.text_capital] = useState(props.data.text_capital[props.language_id] || '');
  [data.text_currency[props.language_id], set.text_currency] = useState(props.data.text_currency[props.language_id] || '');
  [data.text_name[props.language_id], set.text_name] = useState(props.data.text_name[props.language_id] || '');
  [data.text_full_name[props.language_id], set.text_full_name] = useState(props.data.text_full_name[props.language_id] || '');
  [data.text_languages[props.language_id], set.text_languages] = useState(props.data.text_languages[props.language_id] || '');
  [data.text_ethnic_groups[props.language_id], set.text_ethnic_groups] = useState(props.data.text_ethnic_groups[props.language_id] || '');
  [data.text_religions[props.language_id], set.text_religions] = useState(props.data.text_religions[props.language_id] || '');

  [data.flag, set.flag] = useState(props.data.flag || '');
  [data.area, set.area] = useState(props.data.area || '');
  [data.population, set.population] = useState(props.data.population || '');
  [data.head_of_state, set.head_of_state] = useState(props.data.head_of_state || '');
  [data.prime_minister, set.prime_minister] = useState(props.data.prime_minister || '');
  [data.life_expectancy, set.life_expectancy] = useState(props.data.life_expectancy || '');
  [data.literacy, set.literacy] = useState(props.data.literacy || '');
  [data.mobile_phone_penetration, set.mobile_phone_penetration] = useState(props.data.mobile_phone_penetration || '');
  [data.population_growth_rate, set.population_growth_rate] = useState(props.data.population_growth_rate || '');
  [data.population_below_poverty_line, set.population_below_poverty_line] = useState(props.data.population_below_poverty_line || '');
  [data.urban_population, set.urban_population] = useState(props.data.urban_population || '');

  return (
    <Card body>
      <Row>
        <Col md="2">Name :</Col>
        <Col><Form.Group controlId="country_name">
          <Form.Control type="text" required feedback="Please enter a name"
            value={data.text_name[props.language_id]}
            onChange={e => set.text_name(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Full Name :</Col>
        <Col><Form.Group controlId="country_full_name">
          <Form.Control type="text"
            value={data.text_full_name[props.language_id]}
            onChange={e => set.text_full_name(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Flag :</Col>
        <Col><Form.Group controlId="country_name">
          <Form.Control type="text" readOnly
            value={data.flag}
            onChange={e => set.flag(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Area :</Col>
        <Col><Form.Group controlId="country_area">
          <Form.Control type="text"
            value={data.area}
            onChange={e => set.area(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Capital :</Col>
        <Col><Form.Group controlId="country_capital">
          <Form.Control type="text"
            value={data.text_capital[props.language_id]}
            onChange={e => set.text_capital(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Languages :</Col>
        <Col><Form.Group controlId="country_languages">
          <Form.Control type="text" as="textarea"
            value={data.text_languages[props.language_id]}
            onChange={e => set.text_languages(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Ethnic Groups :</Col>
        <Col><Form.Group controlId="country_ethnic_groups">
          <Form.Control type="text" as="textarea"
            value={data.text_ethnic_groups[props.language_id]}
            onChange={e => set.text_ethnic_groups(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Religions :</Col>
        <Col><Form.Group controlId="country_religions">
          <Form.Control type="text" as="textarea"
            value={data.text_religions[props.language_id]}
            onChange={e => set.text_religions(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Head of State :</Col>
        <Col><Form.Group controlId="country_head_of_state">
          <Form.Control type="text"
            value={data.head_of_state}
            onChange={e => set.head_of_state(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Prime Minister :</Col>
        <Col><Form.Group controlId="country_prime_minister">
          <Form.Control type="text"
            value={data.prime_minister}
            onChange={e => set.prime_minister(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Currency :</Col>
        <Col><Form.Group controlId="country_currency">
          <Form.Control type="text"
            value={data.text_currency[props.language_id]}
            onChange={e => set.text_currency(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Life Expectancy :</Col>
        <Col><Form.Group controlId="country_life_expectancy">
          <Form.Control type="text"
            value={data.life_expectancy}
            onChange={e => set.life_expectancy(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Literacy :</Col>
        <Col><Form.Group controlId="country_literacy">
          <Form.Control type="text"
            value={data.literacy}
            onChange={e => set.literacy(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Mobile Phone penetration :</Col>
        <Col><Form.Group controlId="country_mobile_phone_penetration">
          <Form.Control type="text"
            value={data.mobile_phone_penetration}
            onChange={e => set.mobile_phone_penetration(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Population :</Col>
        <Col><Form.Group controlId="country_population">
          <Form.Control type="text"
            value={data.population}
            onChange={e => set.population(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Population growth rate :</Col>
        <Col><Form.Group controlId="country_population_growth_rate">
          <Form.Control type="text"
            value={data.population_growth_rate}
            onChange={e => set.population_growth_rate(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Population below poverty line :</Col>
        <Col><Form.Group controlId="country_population_below_poverty_line">
          <Form.Control type="text"
            value={data.population_below_poverty_line}
            onChange={e => set.population_below_poverty_line(e.target.value)}
          />
        </Form.Group></Col>
      </Row>
      <Row>
        <Col md="2">Urban Population :</Col>
        <Col><Form.Group controlId="country_urban_population">
          <Form.Control type="text"
            value={data.urban_population}
            onChange={e => set.urban_population(e.target.value)}
          />
        </Form.Group>
        </Col>
      </Row>
    </Card>
  );
}
