import { countryConstants } from '../constants';
import { countryService } from '../services';
import { alertActions } from './';

export const countryActions = {
  clear,
  clearAll,
  update,
  getById,
  getAll,
  getAllSimplified,
  store,
  setFilter,
  delete: _delete
};

function clear() {
  return { type: countryConstants.CLEAR };
}
function clearAll() {
  return { type: countryConstants.CLEAR_ALL };
}
function setFilter(filter) {
  return { type: countryConstants.SET_FILTER, filter: filter };
}
function store(country) {
  return dispatch => {
    dispatch(request(country));

    countryService.store(country)
      .then(
        response => {
          dispatch(success(response));
          dispatch(alertActions.success('Country successfully created'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(country) { return { type: countryConstants.STORE_REQUEST, data: country } }
  function success(response) { return { type: countryConstants.STORE_SUCCESS, response: response } }
  function failure(error) { return { type: countryConstants.STORE_FAILURE, data: country, error: error } }
}

function update(country) {
  return dispatch => {
    dispatch(request(country));

    countryService.update(country)
      .then(
        response => {
          dispatch(success(response));
          dispatch(alertActions.success('Update successful'));
        },
        error => {
          dispatch(failure(error.toString()));
          dispatch(alertActions.error(error.toString()));
        }
      );
  };

  function request(country) { return { type: countryConstants.UPDATE_REQUEST, id: country.id, data: country } }
  function success(response) { return { type: countryConstants.UPDATE_SUCCESS, id: country.id, response: response } }
  function failure(error) { return { type: countryConstants.UPDATE_FAILURE, id: country.id, error } }
}

function getById(id) {
  return dispatch => {
    dispatch(request(id));

    countryService.getById(id)
      .then(
        response => dispatch(success(response)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: countryConstants.GETBYID_REQUEST, id } }
  function success(response) { return { type: countryConstants.GETBYID_SUCCESS, id, response } }
  function failure(id, error) { return { type: countryConstants.GETBYID_FAILURE, id, error } }
}

function getAll(page, perPage, filter) {
  return dispatch => {
    dispatch(request(filter));

    countryService.getAll(page, perPage, filter)
      .then(
        response => dispatch(success(response, filter)),
        error => dispatch(failure(error, filter))
      );
  };

  function request(filter) { return { type: countryConstants.GETALL_REQUEST, filter } }
  function success(response, filter) { return { type: countryConstants.GETALL_SUCCESS, response, filter } }
  function failure(error, filter) { return { type: countryConstants.GETALL_FAILURE, error, filter } }
}

function getAllSimplified() {
  return dispatch => {
    dispatch(request());

    countryService.getAllSimplified()
      .then(
        response => dispatch(success(response)),
        error => dispatch(failure(error))
      );
  };

  function request() { return { type: countryConstants.GETALL_SIMPLIFIED_REQUEST } }
  function success(response) { return { type: countryConstants.GETALL_SIMPLIFIED_SUCCESS, response } }
  function failure(error) { return { type: countryConstants.GETALL_SIMPLIFIED_FAILURE, error } }
}


// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    countryService.delete(id)
      .then(
        country => dispatch(success(id)),
        error => dispatch(failure(id, error.toString()))
      );
  };

  function request(id) { return { type: countryConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: countryConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: countryConstants.DELETE_FAILURE, id, error } }
}
