import { countryConstants } from '../constants';

export function countries(state = {}, action) {
    switch (action.type) {
        case countryConstants.CLEAR_ALL:
            return {};
        case countryConstants.SET_FILTER:
            return {
                filter: action.filter,
            };
        case countryConstants.GETALL_REQUEST:
            return {
                filter: action.filter,
                loading: true,
            };
        case countryConstants.GETALL_SUCCESS:
            const {links, path ,...meta} = action.response.meta;
            return {
                filter: action.filter,
                items: action.response.data,
                pagination: meta,
            };
        case countryConstants.GETALL_FAILURE:
            return {
                filter: action.filter,
                error: action.error,
            };
        case countryConstants.DELETE_REQUEST:
            // add 'deleting:true' property to country being deleted
            return {
                ...state,
                items: state.items.map(country =>
                    country.id === action.id
                        ? { ...country, deleting: true }
                        : country
                )
            };
        case countryConstants.DELETE_SUCCESS:
            // remove deleted country from state
            return {
                items: state.items.filter(country => country.id !== action.id)
            };
        case countryConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to country
            return {
                ...state,
                items: state.items.map(country => {
                    if (country.id === action.id) {
                        // make copy of country without 'deleting:true' property
                        const { deleting, ...countryCopy } = country;
                        // return copy of country with 'deleteError:[error]' property
                        return { ...countryCopy, deleteError: action.error };
                    }

                    return country;
                })
            };
        default:
            return state
    }
}
