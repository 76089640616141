import React, { FC, useEffect, useMemo, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import { DragEndEvent, Icon, LatLng, LatLngExpression, LatLngTuple, latLng } from 'leaflet';
import { getPublicUrl } from '../../helpers';
import { Property } from 'csstype';
import { DraggableMarker, LeafletControlGeocoder } from '.';
import { SearchAddress } from '../../types';

const magdeburg = latLng([52.130517, 11.626724]);
const africaCenter = latLng([15.199386, 19.075023]);
const africaZoom = 5;
const markerIcon = generateMarkerIcon();

export function latLngExpressionToLatLng(latlng: LatLngExpression) {
  if (Array.isArray(latlng)) return latLng(latlng[0], latlng[1]);
  return latLng(latlng.lat, latlng.lng);
}

export interface CustomMapProps {
  showSearch?: boolean;
  initialAddress?: SearchAddress;
  initialCenter?: LatLngExpression;
  initialMarker?: LatLngExpression;
  initialZoom?: number;
  height: Property.Height;
  width: Property.Width;

  disabled?: boolean;

  onChange?: (latlng: LatLng | null) => unknown;
}
export const CustomMap: FC<CustomMapProps> = (props) => {
  const [internalCenter, setInternalCenter] = useState(latLngExpressionToLatLng(props.initialCenter || africaCenter));
  const [internalMarker, setInternalMarker] = useState(props.initialMarker ? latLngExpressionToLatLng(props.initialMarker) : null);
  const [internalZoom, setInternalZoom] = useState(props.initialZoom || africaZoom);
  useEffect(() => {
    if (props.onChange) props.onChange(internalMarker);
  }, [internalMarker]);
  const initialQuery = useMemo(() => {
    if (!props.initialAddress) return '';
    if (props.initialAddress.q) return props.initialAddress.q;
    const query = [] as string[];
    if (props.initialAddress.street) query.push(props.initialAddress.street);
    if (props.initialAddress.postalcode) query.push(props.initialAddress.postalcode);
    if (props.initialAddress.city) query.push(props.initialAddress.city);
    if (props.initialAddress.county) query.push(props.initialAddress.county);
    if (props.initialAddress.state) query.push(props.initialAddress.state);
    if (props.initialAddress.country) query.push(props.initialAddress.country);
    return query.join(' ');
  }, [props.initialAddress]);
  useEffect(() => {
    setInternalCenter(latLngExpressionToLatLng(props.initialCenter || props.initialMarker || africaCenter));
    setInternalMarker(props.initialMarker ? latLngExpressionToLatLng(props.initialMarker) : null);
    setInternalZoom(props.initialZoom || (props.initialMarker ? 16 : africaZoom));
  }, [initialQuery, props.initialCenter?.toString(), props.initialMarker?.toString(), props.initialZoom]);

  function handleCenterChange(position: LatLng) {
    setInternalCenter(position);
  }
  function handleZoomChange(value: number) {
    setInternalZoom(value);
  }
  function handleMarkerChange(position: LatLng) {
    if (props.disabled) return;
    setInternalMarker(position);
  }
  function handleResult(result: { latlng: LatLng, zoom: number }) {
    setInternalCenter(result.latlng);
    setInternalZoom(result.zoom);
    setInternalMarker(result.latlng);
  }
  return (
    <div
      style={{
        position: "relative",
        height: props.height,
        width: props.width,
        overflow: 'hidden',
      }}
    >
      <MapContainer
        center={internalCenter}
        zoom={internalZoom}
        scrollWheelZoom={false}
        style={{
          height: props.height,
          width: props.width,
          overflow: 'hidden',
        }}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {internalMarker && <DraggableMarker draggable={!props.disabled} position={internalMarker} icon={markerIcon} onDragEnd={setInternalMarker} />}
        {/* {internalMarker && <Marker position={internalMarker} icon={markerIcon} draggable={true} >
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>} */}
        {props.disabled ? null : <LeafletControlGeocoder onResult={handleResult} initialQuery={initialQuery} />}
        <MapDetails height={props.height} width={props.width} center={internalCenter} zoom={internalZoom} onCenterChange={handleCenterChange} onMarkerChange={handleMarkerChange} onZoomChange={handleZoomChange} />
      </MapContainer>
    </div>
  );
};

interface MapDetailsProps {
  height: Property.Height;
  width: Property.Width;
  show?: boolean;
  center: LatLng;
  zoom: number;
  onCenterChange?: (position: LatLng) => unknown;
  onMarkerChange?: (position: LatLng) => unknown;
  onZoomChange?: (zoom: number) => unknown;
}
const MapDetails: FC<MapDetailsProps> = (props) => {
  const height = props.height;
  const width = props.width;
  const map = useMap();
  useMapEvents({
    move: () => { props.onCenterChange?.(map.getCenter()); },
    click: (e) => { props.onMarkerChange?.(e.latlng); },
    zoomend: () => { props.onZoomChange?.(map.getZoom()); },
  });
  useEffect(() => {
    const mapContainer = map.getContainer();
    mapContainer.style.height = `${height}`;
    mapContainer.style.width = `${width}`;
    map.invalidateSize(true);
  }, [props.height, props.width]);
  useEffect(() => {
    map.setView(props.center, props.zoom);
  }, [props.center.toString(), props.zoom]);
  return null;
};

function generateMarkerIcon() {
  return new Icon({
    iconUrl: getPublicUrl('images/vendor/leaflet/dist/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: getPublicUrl('images/vendor/leaflet/dist/marker-shadow.png'),
    shadowSize: [41, 41],
    shadowAnchor: [13, 41],
  });
}
