
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { cloneDeep } from 'lodash';
import { CollapsableList } from '../../components/Custom/CollapsableList';
import { CancelButton, DeleteButton, EditButton, SaveButton } from '../../components/Button';

export function OrganizationRoleTableRow({
  initializeEdit = false,

  canEdit = false,
  canDelete = false,
  canSetDefault = false,

  isValid = (role) => { return role && !!role.name },
  isDefault = false,

  rightStructure = [],
  role = { },

  onCancel = () => { },
  onSave = (role) => { },
  onSetDefault = () => { },
  onDelete = () => { },
}) {
  const [roleEdit, setRoleEdit] = useState(null);
  const [editIsValid, setEditIsValid] = useState(null);

  useEffect(() => {
    if (!initializeEdit && !canEdit) activateEdit(false);
  }, [canEdit]);
  useEffect(() => {
    if (initializeEdit) activateEdit(true);
  }, [initializeEdit]);
  useEffect(() => {
    if (roleEdit) {
      setEditIsValid(isValid(roleEdit));
    }
  }, [roleEdit])


  function activateEdit(active = true) {
    if (active && canEdit) {
      setRoleEdit(cloneDeep(role));
      return;
    }
    setRoleEdit(null);
  }
  function handleSave() {
    if (editIsValid) {
      onSave(roleEdit);
      activateEdit(false);
    }
  }
  function handleDelete() {
    onDelete();
  }
  function handleCancel() {
    activateEdit(false);
    onCancel();
  }
  function setEntireGroup(group = [], value) {
    setRoleEdit({
      ...(roleEdit || {}),
      // ...(group.reduce((previous, currentKey, index) => ({ ...previous, [currentKey]: value }), {})),
      ...(group.reduce((previous, currentKey, index) => {
        previous[currentKey] = value;
        return previous;
      }, {})),
    });
  }
  function handleNameChange({ target }) {
    setRoleEdit({ ...roleEdit, name: target.value, });
  }
  function handleChange({ target }) {
    // onChange(target.id, target.value);
    setRoleEdit({ ...roleEdit, [target.id]: target.checked, });
  }
  function allChecked(rights = []) {
    return rights.every(right => (roleEdit || role)[right]);
  }
  return <tr>
    {<td><Form.Check
      disabled={isDefault || !canSetDefault}
      onChange={(e) => !isDefault && onSetDefault()}
      type={'checkbox'}
      checked={isDefault || false}
    /></td>}
    {!roleEdit ? <td onClick={activateEdit}>{role.name}</td>
      : <td><Form.Control isValid={editIsValid} value={roleEdit.name || ''} onChange={handleNameChange} /></td>}
    {rightStructure.map((group, index) => <td key={`${index}.header`}>
      <CollapsableList
        header={<Form.Check
          inline
          key={`${index}.header`}
          disabled={!roleEdit}
          onChange={(e) => setEntireGroup(group, e.target.checked)}
          type={'checkbox'}
          label={`check all`}
          id={`${index}.header`}
          checked={allChecked(group)}
        />}
      >
        {group.map(right => <Form.Check
          key={right}
          disabled={!roleEdit}
          onChange={handleChange}
          onClick={activateEdit}
          type={'checkbox'}
          label={right}
          id={right}
          checked={(roleEdit ? roleEdit[right] : role[right]) || false}
        />)}
      </CollapsableList>
    </td>)}
    <td>
      {canEdit && !roleEdit && <EditButton onClick={activateEdit} />} {" "}
      {roleEdit && <CancelButton onClick={handleCancel} />} {" "}
      {canEdit && roleEdit && <SaveButton color={editIsValid ? 'blue' : 'lightgrey'} onClick={handleSave} />} {" "}
      {canDelete && !isDefault && !roleEdit && <DeleteButton onClick={handleDelete} />}
    </td>
  </tr>
}
