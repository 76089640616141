import React from "react";
import { countryPolicies, organizationPolicies, userPolicies } from "../authorization";
import { HomePage } from "../pages/home";
import { CountryCreate, CountryEdit, CountryList, CountryProfile } from "../pages/countries";
import {
  OrganizationAcceptInvite,
  OrganizationCreate,
  OrganizationEditV2,
  OrganizationImportCreateMany,
  OrganizationList,
  OrganizationProfile,
  OrganizationUsers
} from "../pages/organizations";
import { LoginPage, RegisterPage } from "../pages/authentication";
import { NotFound, NotImplemented } from "../errors";
import { CurrentUserOrganizationList } from "../pages/currentUser/CurrentUserOrganizationList";
import { EditUserPage } from "../pages/edituser";
import { CurrentUserProfile } from "../pages/currentUser";
import { CurrentUser } from "../types";
import { UserListV2 } from "../pages/user";

interface AuthenticatedRoute {
  path: string,
  title: string,
  Component: React.ElementType<{
    currentUser: CurrentUser
  }> | React.FunctionComponent<{
    currentUser: CurrentUser
  }>,
  hideuiHeader?: boolean,
  authenticate: true,
  authorize?: Function | false,
}
interface UnauthenticatedRoute {
  path: string,
  title: string,
  Component: React.ElementType<{}> | React.FunctionComponent<{}>,
  hideuiHeader?: boolean,
  authenticate: false,
  authorize?: false,
}
export type RouteConfig = AuthenticatedRoute | UnauthenticatedRoute;


export const routes: RouteConfig[] = [
  {
    path: "/",
    title: "Home",
    Component: HomePage,
    authenticate: false,
  },
  {
    path: "/login",
    title: "Login",
    Component: LoginPage,
    authenticate: false,
  },
  {
    path: "/register",
    title: "Register",
    Component: RegisterPage,
    authenticate: false,
  },
  {
    path: "/currentUser",
    title: "Profile",
    Component: CurrentUserProfile,
    authenticate: true,
    authorize: false,
  },
  {
    path: "/currentUser/orgs",
    title: "My Organizations",
    Component: CurrentUserOrganizationList,
    authenticate: true,
    authorize: false,
  },
  {
    path: "/currentUser/edit",
    title: "Edit Profile",
    Component: (props) => <NotImplemented message="Edit Profile is not implemented yet. Please come back later" {...props} />,
    authenticate: true,
    authorize: false,
  },
  {
    path: "/users/:user_id/edit",
    title: "Edit User",
    Component: EditUserPage,
    authenticate: true,
    authorize: () => false,
  },
  {
    path: "/users",
    title: "Users List",
    Component: UserListV2,
    authenticate: true,
    authorize: userPolicies.viewAny,
  },
  {
    path: "/importOrgs",
    title: "Import Organizations",
    Component: OrganizationImportCreateMany,
    authenticate: true,
    authorize: organizationPolicies.createMany,
  },
  {
    path: "/createOrg",
    title: "Create Organization",
    Component: OrganizationCreate,
    authenticate: true,
    authorize: organizationPolicies.create,
  },
  {
    path: "/orgs/:org_id/users/acceptInvite",
    title: "Accept Invite",
    Component: OrganizationAcceptInvite,
    authenticate: false,
  },
  {
    path: "/orgs/:org_id/users",
    title: "Organization Users",
    Component: OrganizationUsers,
    authenticate: true,
    authorize: organizationPolicies.viewAnyUser,

  },
  {
    path: "/orgs/:org_id/edit",
    title: "Edit Organization",
    Component: OrganizationEditV2,
    authenticate: true,
    authorize: organizationPolicies.update,
  },
  {
    path: "/orgs/:org_id",
    title: "Organization Profile",
    Component: OrganizationProfile,
    authenticate: false,
    hideuiHeader: true,
  },
  {
    path: "/orgs",
    title: "Organization List",
    Component: OrganizationList,
    authenticate: false,
  },
  {
    path: "/createCountry",
    title: "Create Country",
    Component: CountryCreate,
    authenticate: true,
    authorize: () => false,
  },
  {
    path: "/countries",
    title: "Country List",
    Component: (props) => <CountryList filter={{}} {...props} />,
    authenticate: false,
  },
  {
    path: "/countries_africa",
    title: "Countries in Africa",
    Component: (props) => <CountryList filter={{ continent: 'Africa' }} {...props} />,
    authenticate: false,
  },
  {
    path: "/countries/:country_id",
    title: "Country Profile",
    Component: CountryProfile,
    authenticate: false,
  },
  {
    path: "/countries/:country_id/edit",
    title: "Edit Country",
    Component: CountryEdit,
    authenticate: true,
    authorize: countryPolicies.update,
  },
  {
    path: "/:org_name",
    title: "Organization Profile",
    Component: OrganizationProfile,
    authenticate: false,
    hideuiHeader: true,
  },
  {
    path: "*",
    title: "URL Not Found",
    Component: (props) => <NotFound message="URL not found." {...props} />,
    authenticate: false,
  }
];
