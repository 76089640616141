export const languageConstants = {
    CLEAR: 'LANGUAGE_CLEAR',
    CLEAR_ALL: 'LANGUAGE_CLEAR_ALL',

    SET_LANGUAGE: 'SET_LANGUAGE',

    UPDATE_REQUEST: 'LANGUAGE_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'LANGUAGE_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'LANGUAGE_UPDATE_FAILURE',

    DELETE_REQUEST: 'LANGUAGE_DELETE_REQUEST',
    DELETE_SUCCESS: 'LANGUAGE_DELETE_SUCCESS',
    DELETE_FAILURE: 'LANGUAGE_DELETE_FAILURE',

    GETALL_REQUEST: 'LANGUAGES_GETALL_REQUEST',
    GETALL_SUCCESS: 'LANGUAGES_GETALL_SUCCESS',
    GETALL_FAILURE: 'LANGUAGES_GETALL_FAILURE',

    GETALL_SIMPLIFIED_REQUEST: 'LANGUAGES_GETALL_SIMPLIFIED_REQUEST',
    GETALL_SIMPLIFIED_SUCCESS: 'LANGUAGES_GETALL_SIMPLIFIED_SUCCESS',
    GETALL_SIMPLIFIED_FAILURE: 'LANGUAGES_GETALL_SIMPLIFIED_FAILURE',
};
