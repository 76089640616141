export function authHeader() {
  // return authorization header with jwt token

  const auth = JSON.parse(localStorage.getItem('authentication'));

  let header = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };

  if (auth && auth.token) {
    header['Authorization'] = 'Bearer ' + auth.token;
  }

  return header;
}
