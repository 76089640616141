import React, { FC, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Image, Alert } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate, NavigateOptions } from "react-router-dom";
import { ContactForm, SectorsForm, AboutForm, AwardForm, IndicatorsForm, UrlNameForm, KeyvisualForm, OrganizationUpdateForm, SectorsFormV2, ProductsandServicesForm, IndicatorsFormV2, ContactsForm } from './forms';
import { useCreateOrganizationContactMutation, useDeleteOrganizationContactMutation, useDeleteOrganizationKeyvisualMutation, useGetAllCountriesQuery, useGetCurrentUserQuery, useGetOrganizationKeyvisualListQuery, useGetOrganizationQuery, useGetOrganizationTransformedQuery, useMakePrimaryOrganizationContactMutation, useRemovePrimaryOrganizationContactMutation, useSetOrganizationKeyvisualMutation, useUpdateOrganizationContactMutation, useUpdateOrganizationMutation, useUpdateOrganizationSectorRelationsMutation, useUpdateOrganizationUrlNameMutation, useUploadAwardPictureMutation, useUploadOrganizationKeyvisualMutation, useUploadOrganizationLogoMutation } from '../../services';
import getPublicUrl from '../../helpers/getPublicUrl';
import { organizationPolicies } from '../../authorization';
import { BaseAPIError, CurrentUser, Id, Language, Organization, OrganizationAbout, OrganizationContact, OrganizationWithoutRelations, ValidationErrors, isValidationErrorsResponse } from '../../types';
import { Loading, NotFound } from '../../errors';
import { OrganizationUpdateData, prepareOrganizationUpdateData, useAppDispatch } from '../../helpers';
import { GoBackButton, ToggleAutosaveButton } from '../../components/Button';
import { resetKeyvisual } from '../../slices';
// import {} from 'pages/organizations/forms';

const keyvisuals = [
  'fileadmin/content/keyvisuals/default/businessregulation_default.jpg',
  'fileadmin/content/keyvisuals/default/economic_default.jpg',
  'fileadmin/content/keyvisuals/default/companies_keyvisual.jpg',
  'fileadmin/content/keyvisuals/default/countries_keyvisual.jpg',
];
const placeholderImageUrl = getPublicUrl(`fileadmin/templates/assets/images/placeholder_photo.png`);

interface OrganizationEditV2Props {
  currentUser: CurrentUser;
}
export const OrganizationEditV2: FC<{}> = ({ }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { org_id } = useParams();

  const allCountries = useGetAllCountriesQuery(0);
  const keyvisualList = useGetOrganizationKeyvisualListQuery({ org_id });
  const org = useGetOrganizationTransformedQuery({ org_id });
  const organization = org.data?.organization;
  const relations = org.data?.relations;

  const productsAndServicesData = useMemo<{ products?: string, services?: string } | undefined>(() => {
    if (!org.data) return undefined;
    return {
      products: org.data.organization.products,
      services: org.data.organization.services,
    }
  }, [organization?.products, organization?.services]);

  const { data: currentUser } = useGetCurrentUserQuery();
  const [deleteOrganizationKeyvisual, resultDeleteKeyvisual] = useDeleteOrganizationKeyvisualMutation();
  const [setOrganizationKeyvisual, resultSetKeyvisual] = useSetOrganizationKeyvisualMutation();
  const [updateUrlName, resultUpdateUrlName] = useUpdateOrganizationUrlNameMutation();
  const [updateOrganization, resultUpdateOrganization] = useUpdateOrganizationMutation();
  const [updateOrganizationSectors, resultUpdateOrganizationSectors] = useUpdateOrganizationSectorRelationsMutation();
  const [updateContact, resultUpdateContact] = useUpdateOrganizationContactMutation();
  const [createContact, resultCreateContact] = useCreateOrganizationContactMutation();
  const [deleteContact, resultDeleteContact] = useDeleteOrganizationContactMutation();
  const [makePrimaryContact, resultMakePrimaryContact] = useMakePrimaryOrganizationContactMutation();
  const [removePrimaryContact, resultRemovePrimaryContact] = useRemovePrimaryOrganizationContactMutation();

  const [uploadAwardPicture, resultUploadAwardPicture] = useUploadAwardPictureMutation();
  const [uploadKeyvisual, resultUploadKeyvisual] = useUploadOrganizationKeyvisualMutation();
  const [uploadLogo, resultUploadLogo] = useUploadOrganizationLogoMutation();

  const [contactsValidationErrors, setContactsValidationErrors] = useState<{ [key: Id]: ValidationErrors<OrganizationContact> | undefined }>({});
  function handleUpdateContact(contact_id: Id, contact: Partial<OrganizationContact>) {
    if (!org_id) return;
    updateContact({ org_id, contact_id, contact }).then((result) => {
      if ("error" in result) {
        const err = result.error;
        if (isValidationErrorsResponse(err)) {
          setContactsValidationErrors((errors) => ({
            ...errors,
            [contact_id]: err.errors,
          }));
          console.log('handleUpdateContact', { error: err })
          return;
        }
    }
    console.log('handleUpdateContact', { result: result })
    if (contactsValidationErrors[contact_id] !== undefined) {
      setContactsValidationErrors((errors) => ({
        ...errors,
        [contact_id]: undefined,
      }));
      return;
    }
    return;
  });
}
const [key, setKey] = useState(location.state?.key || 'home');
const [autosave, setAutosave] = useState(true);

const canUpload = organizationPolicies.upload(currentUser, org.data);
const canEditStorage = organizationPolicies.editStorage(currentUser, org.data);

if (!org_id) return <NotFound message='Organization not found.' />;
return (<>
  <h3><GoBackButton id={'orgs-edit-go-back-button'}
    fontSize={'1.5rem'} onClick={() => {
      if (location.state?.from) {
        navigate(location.state.from);
      } else if (organization?.url_name) {
        navigate(`/${organization?.url_name}`);
      } else {
        dispatch(resetKeyvisual());
        navigate('/orgs');
      }
    }} />{" "}Edit Organization (<ToggleAutosaveButton disabled id={"toggle-autosave"} fontSize={"1.5rem"} toggle={autosave} onToggle={() => setAutosave(v => !v)} />)</h3>
  <Tabs
    activeKey={key}
    onSelect={(k) => {
      setKey(k);
    }}
    fill
  >
    <Tab key="home" eventKey="home" title="Home">
      {organization && <Card body><UrlNameForm
        org_id={org_id} url_name={organization?.url_name || ''} error={resultUpdateUrlName.error as BaseAPIError}
        onSubmit={handleSubmitUrlName} isSaving={org.isFetching}
        autosave={autosave}
      /></Card>}
      {/* {keyvisualList.IsError && <Alert variant="danger">Error loading keyvisual list</Alert>}
        {keyvisualList.isFetching && <Alert variant="info">Loading keyvisual list</Alert>}
        {keyvisualList.isSuccess && <Alert variant="success">Keyvisual list loaded</Alert>}
        {keyvisualList.isError && <Button onClick={() => refetchKeyvisualList()}>Refetch Keyvisual List</Button>} */}
      {organization && <Card body><KeyvisualForm
        keyvisualList={keyvisualList.data}
        canUpload={canUpload} onUpload={(file) => handleUploadKeyvisual(file)}
        canSelect={canUpload} onSelect={(path) => handleSelectKeyvisual(path)}
        canDelete={canEditStorage} onDelete={(path) => handleDeleteKeyvisual(path)}
      /></Card>}
      {organization && <Card body>
        <Form>
          <Form.Group id="upload-logo">
            <Image width="200" src={getPublicUrl(organization.logo, placeholderImageUrl)} />
            <Form.Label>Upload Organization Logo:</Form.Label>
            <Form.Control
              type="file"
              onChange={e => {
                if (!('files' in e.target && e.target.files?.length)) return;
                handleUploadLogo(e.target.files[0])
              }}
            />
          </Form.Group>
          <br />
        </Form>
      </Card>}
      {organization && <OrganizationUpdateForm data={organization} onSubmit={handleUpdateOrganization} isSaving={org.isFetching} />}
    </Tab>
    <Tab key="sectors" eventKey="sectors" title="Sectors">
      {relations && <SectorsFormV2 sector_ids={relations?.sectors ?? []} onSubmit={(sector_ids) => updateOrganizationSectors({ sector_ids, org_id })} autosave={autosave} />}
    </Tab>
    {/* <Tab key="manager" eventKey="manager" title="Manager">
        {key === "manager" && manager && <Card body key="Manager">
          {manager.map((contact, index) =>
            <ContactForm key={index}
              data={contact} is_primary={contact.is_primary}
              removeContact={() => handleRemoveContact(contact)}
              onPrimaryChange={(is_primary: boolean) => handleSetPrimaryContact(contact, is_primary)}
            />
          )}
          {!manager.length ? <Button onClick={() => handleAddManager()}>Add Manager</Button> : null}
        </Card>}
      </Tab> */}
    <Tab key="contacts" eventKey="contacts" title="Contacts">
      {relations && key === "contacts" ? <ContactsForm
        contacts={relations.contacts ?? []}
        errors={contactsValidationErrors}
        autosave={autosave}
        isFetching={org.isFetching}
        onAddContact={(data) => createContact({ org_id, contact: data })}
        onRemoveContact={(contact_id) => deleteContact({ org_id, contact_id })}
        onSetPrimaryContact={
          ({ contact_id, is_primary }) => is_primary
            ? makePrimaryContact({ org_id, contact_id })
            : removePrimaryContact({ org_id, contact_id })
        }
        onUpdateContact={({ contact_id, data }) => handleUpdateContact(contact_id, data)}
      ></ContactsForm> : null}
    </Tab>
    <Tab key="about" eventKey="about" title="About Us">
      {relations && <AboutForm data={relations.about ?? {}} onSubmit={(about) => updateOrganization({ org_id, data: { about } })} isSaving={org.isFetching} />}
    </Tab>
    {/* <Tab key="awards" eventKey="awards" title="References">
        {org.data?.awards.map((award, index) =>
          <AwardForm
            id={`awards[${index}]`}
            data={award}
            removeAward={handleRemoveAward}
            upload={(file: File, purpose: string, _set: (path: string) => unknown) => handleUploadAwardPicture(award.id, file, _set)}
          />
        )}
        <Card body key="newAward">
          <Button onClick={() => handleAddAward()}>Add Award</Button>
        </Card>
      </Tab> */}
    <Tab key="productsAndServices" eventKey="productsAndServices" title="Products / Services">
      {org.data && productsAndServicesData && <ProductsandServicesForm
        productsAndServices={productsAndServicesData}
        autosave={autosave}
        onSubmit={(productsAndServices) => updateOrganization({ org_id, data: productsAndServices })}
      />}
    </Tab>
    {/* <Tab key="" eventKey="management" title="Management">
                <Card body key="management">
                    No management!
                </Card>
            </Tab> */}
    <Tab key="indicators" eventKey="indicators" title="Indicators">
      {relations && <IndicatorsFormV2 data={relations.indicators || {}} autosave={autosave} onSubmit={(indicators) => updateOrganization({ data: { indicators }, org_id })} />}
    </Tab>
    {/* <Tab key="rating" eventKey="rating" title="Rating">
            </Tab> */}
  </Tabs>
</>);

function handleAddAward() {
}
function handleRemoveAward(award: any) {
}

function handleAddManager() {
}
function handleAddContact() {
}
function handleRemoveContact(contact: OrganizationContact) {
}
function handleSetPrimaryContact(contact: OrganizationContact, is_primary: boolean) {
}
function generateFormdata(file: File, name = 'file') {
  const formData = new FormData();
  formData.append(name, file);
  return formData;
}
function handleUploadAwardPicture(file: File, award: any, _set: (path: string) => unknown) {
  if (!org_id || !award.id) return;
  uploadAwardPicture({ org_id, award_id: award.id, file: generateFormdata(file) });
}
function handleUploadKeyvisual(file: File) {
  if (!org_id) return;
  uploadKeyvisual({ org_id, file: generateFormdata(file) });
}
function handleUploadLogo(file: File) {
  if (!org_id) return;
  uploadLogo({ org_id, file: generateFormdata(file) });
}
function handleSelectKeyvisual(path: string) {
  if (!org_id) return;
  setOrganizationKeyvisual({ org_id, path });
}
function handleDeleteKeyvisual(path: string) {
  if (!org_id) return;
  deleteOrganizationKeyvisual({ org_id, path });
}
function handleSubmitUrlName(url_name: string) {
  if (!org_id) return;
  updateUrlName({ org_id, url_name });
}
function handleUpdateOrganization(data: OrganizationWithoutRelations) {
  if (!org_id) return;
  updateOrganization({ org_id, data });
  // console.debug('handleUpdateOrganization', { data })
}
}

