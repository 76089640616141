import { organizationConstants } from '../constants';

export function organizations(state = {}, action) {
    switch (action.type) {
        case organizationConstants.CLEAR_ALL:
            return {};
        case organizationConstants.SET_FILTER:
            return {
                filter: action.filter,
            };
        case organizationConstants.GETALL_REQUEST:
            return {
                filter: action.filter,
                loading: true,
            };
        case organizationConstants.GETALL_SUCCESS:
            const {links, path ,...meta} = action.response.meta;
            return {
                filter: action.filter,
                items: action.response.data,
                pagination: meta,
            };
        case organizationConstants.GETALL_FAILURE:
            return {
                filter: action.filter,
                error: action.error,
            };
        case organizationConstants.DELETE_REQUEST:
            // add 'deleting:true' property to organization being deleted
            return {
                ...state,
                items: state.items.map(org =>
                    org.id === action.id
                        ? { ...org, deleting: true }
                        : org
                )
            };
        case organizationConstants.DELETE_SUCCESS:
            // remove deleted organization from state
            return {
                items: state.items.filter(organization => organization.id !== action.id)
            };
        case organizationConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to organization
            return {
                ...state,
                items: state.items.map(organization => {
                    if (organization.id === action.id) {
                        // make copy of organization without 'deleting:true' property
                        const { deleting, ...organizationCopy } = organization;
                        // return copy of organization with 'deleteError:[error]' property
                        return { ...organizationCopy, deleteError: action.error };
                    }

                    return organization;
                })
            };
        default:
            return state
    }
}
