import { useState, useRef, useMemo, useEffect } from 'react';

const DEFAULT_SAVE_DELAY_MS = 1000;
const IGNORED_KEYS = ['id', 'created_at', 'updated_at'];
interface UseAutoSaveValueParams<T> {
  initialValue: T;
  shouldAutosave?: boolean;
  saveDelayMs?: number;
  onSave?: (value: T) => void;
}
export function useAutoSaveValue<T>({
  initialValue,
  shouldAutosave = true,
  saveDelayMs = DEFAULT_SAVE_DELAY_MS,
  onSave,
}: UseAutoSaveValueParams<T>) {
  const [value, setValue] = useState(initialValue);
  const timeoutId = useRef<number | undefined>();

  const isDirty = useMemo(() => value !== initialValue, [value, initialValue]);

  const removeTimeout = (): void => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  };
  const createTimeout = (func: Function, t = saveDelayMs): void => {
    timeoutId.current = window.setTimeout(func, t);
  };
  const handleChange = (newValue: T, preventSave = false): void => {
    removeTimeout();
    if (value === newValue) { return; }
    setValue(newValue);
    if (!shouldAutosave || preventSave) { return; }
    createTimeout(() => onSave?.(newValue));
  };

  const resetValue = (): void => {
    removeTimeout();
    setValue(initialValue);
  };
  const saveManually = (): void => {
    removeTimeout();
    if (value === initialValue) { return; }
    onSave?.(value);
  }

  return {
    value,
    isDirty,

    handleChange,
    resetValue,
    saveManually,
  } as const;
};

type FormFieldValues<T> = {
  [P in keyof T]: T[P] extends undefined ? any : T[P];
};

interface UseAutoSaveFormParams<TFormData extends FormFieldValues<TFormData>> {
  initialValues: TFormData;
  shouldAutosave?: boolean;
  saveDelayMs?: number;
  ignoreKeys?: string[];

  onSave?: (formData: TFormData) => void;
}
export function useAutoSaveForm<TFormData extends FormFieldValues<TFormData>>(
  { initialValues, shouldAutosave = true, saveDelayMs = DEFAULT_SAVE_DELAY_MS, onSave, ignoreKeys = IGNORED_KEYS }: UseAutoSaveFormParams<TFormData>
) {
  const [formData, setFormData] = useState(initialValues);
  const timeoutId = useRef<number | undefined>();

  const isDirty = !areEqual(formData, initialValues, ignoreKeys);

  const removeTimeout = (): void => {
    if (timeoutId.current) {
      window.clearTimeout(timeoutId.current);
      timeoutId.current = undefined;
    }
  };
  const createTimeout = (func: Function, t = saveDelayMs): void => {
    timeoutId.current = window.setTimeout(func, t);
  };

  const getNewFormData = (name: keyof TFormData, value: TFormData[keyof TFormData]): TFormData & {
    [x: string]: TFormData[keyof TFormData];
  } => {
    if (formData[name] === value) { return formData; }
    const newFormData = {
      ...formData,
      [name]: value,
    };
    return newFormData;
  }

  const handleFieldChange = <K extends keyof TFormData>(name: K, value: TFormData[K], preventSave = false): void => {
    removeTimeout();

    const newFormData = getNewFormData(name, value);
    setFormData(newFormData);

    if (!shouldAutosave || preventSave) { return; }
    if (areEqual(newFormData, initialValues, ignoreKeys)) { return; }
    createTimeout(() => onSave?.(newFormData));
  };
  const handleFieldChangeWithEvent = <K extends keyof TFormData>(name: K, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
    const { value } = event.target;
    handleFieldChange(name, value as any);
  };
  const handlePartialFormDataChange = (newFormData: Partial<TFormData>): void => {
    removeTimeout();
    const _newFormData = {
      ...formData,
      ...newFormData,
    };
    setFormData(_newFormData);

    if (!shouldAutosave) { return; }
    if (areEqual(_newFormData, initialValues, ignoreKeys)) {
      return;
    }
    createTimeout(() => onSave?.(_newFormData));
  }
  const handleFormDataChange = (newFormData: TFormData) => {
    removeTimeout();
    setFormData(newFormData);
    if (!shouldAutosave) { return; }
    if (areEqual(newFormData, initialValues, ignoreKeys)) {
      return;
    }
    createTimeout(() => onSave?.(formData));
  }

  const resetForm = (): void => {
    removeTimeout();
    setFormData(initialValues);
  };

  const saveManually = (): void => {
    removeTimeout();
    if (!isDirty) { return; }
    onSave?.(formData);
  }

  return {
    formData,
    isDirty,

    handleFieldChange,
    handlePartialFormDataChange,
    handleFormDataChange,

    resetForm,
    saveManually,

  } as const;
}

function areEqual(a: any, b: any, ignoreKeys: string[] = []): boolean {
  if (a === b) {
    // console.debug('areEqual: strictly Equal', true, a, b)
    return true;
  }
  if (a === undefined || b === undefined) {
    // console.debug('areEqual: one is undefined', false, a, b)
    return false;
  }
  if (a === null || b === null) {
    // console.debug('areEqual: one is null', false, a, b)
    return false;
  }
  if (typeof a !== typeof b) {
    // console.debug('areEqual: different types', false, a, b)
    return false;
  }
  if (typeof a !== 'object') {
    // console.debug('areEqual: not objects (only objects may be not strictly equal)', false, a, b)
    return false;
  }
  if (Array.isArray(a) !== Array.isArray(b)) {
    // console.debug('areEqual: one is array, the other is not', false, a, b)
    return false;
  }
  if (Array.isArray(a)) {
    if (a.length !== b.length) {
      // console.debug('areEqual: arrays have different lengths', false, a, b)
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (!areEqual(a[i], b[i], ignoreKeys)) {
        // console.debug('areEqual: arrays have different values at index', i, false, a, b)
        return false;
      }
    }
    return true;
  }
  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    // console.debug('areEqual: objects have different number of keys', false, a, b)
    return false;
  }
  for (const key of aKeys) {
    if (ignoreKeys.includes(key)) { continue; }
    if (!areEqual(a[key], b[key], ignoreKeys)) {
      // console.debug('areEqual: objects have different values for key', key, false, a, b)
      return false;
    }
  }
  return true;
}
