import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner } from "react-bootstrap";
import {
    CountryTranslateFormTabCountry,
    CountryTranslateFormTabEconomy,
    CountryTranslateFormTabPolitics,
    CountryTranslateFormTabRegions,
    CountryTranslateFormTabTravel,
} from './';

export function CountryTranslateForm(props) {
    const [key, setKey] = useState('info');
    const [regions, setRegions] = useState(props.data.regions);

    return (<Tabs
        activeKey={key}
        onSelect={(k) => {
            setKey(k);
            props.setKeyvisual(k);
        }}
    >
        <Tab eventKey="info" title="General Information">
            <CountryTranslateFormTabCountry data={props.data.country} primary_language_id={props.primary_language_id} translate_language_id={props.translate_language_id}/>
        </Tab>
        <Tab eventKey="economy" title="Economy">
            {props.data.economy
            ? <CountryTranslateFormTabEconomy data={props.data.economy} primary_language_id={props.primary_language_id} translate_language_id={props.translate_language_id} />
            : <>
                This Country has no economy data<br/>
                <Button onClick={()=>props.create.economy()}>Add Economy</Button>
            </>}
        </Tab>
        <Tab eventKey="politics" title="Politics">
            {props.data.politics
            ? <CountryTranslateFormTabPolitics data={props.data.politics} primary_language_id={props.primary_language_id} translate_language_id={props.translate_language_id} />
            : <>
            This Country has no politics data<br/>
            <Button onClick={()=>props.create.politics()}>Add Politics</Button>
        </>}
        </Tab>
        <Tab eventKey="travel" title="Travel Information">
            {props.data.travel ? <CountryTranslateFormTabTravel data={props.data.travel} primary_language_id={props.primary_language_id} translate_language_id={props.translate_language_id} />
            : <>
                This Country has no travel data<br/>
                <Button onClick={()=>props.create.travel()}>Add Travel</Button>
            </>}
        </Tab>
        {/* <Tab eventKey="regions" title="Regions">
            {props.data.regions
            ? <CountryTranslateFormTabRegions data={props.data.regions}
                createRegion={()=>props.create.region()} removeRegion={region=>props.remove.region(region)}
            />
            : <Card body>
                This Country has no region data<br/>
                <Button onClick={()=>props.create.regions()}>Add Regions</Button>
            </Card>}
        </Tab> */}
    </Tabs>);
}
