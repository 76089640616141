import React, { FC } from 'react';
import { ArrowLeftCircleFill } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';

export const GoBackButton: FC<CustomButtonProptypes> = ({
  // id = 'go-back-button',
  color = "blue",
  fontSize = '1rem',
  tooltip= "Go Back",
  ...props
}) => {
  return <GenericButton
    // id={id}
    color={color}
    tooltip={tooltip}
    {...props}
  >
    <ArrowLeftCircleFill color={color} fontSize={fontSize} />
  </GenericButton>
}
