import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Spinner } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { organizationActions } from '../../actions';
import { Sectors } from './profile';
import { getPublicUrl, getWebUrl, useAppDispatch, useAppSelector } from '../../helpers';
import { OrganizationRatings } from './profile';
import OrganizationProfileProducts from './profile/OrganizationProfileProducts';
import { organizationPolicies } from '../../authorization/organization.policies';
import { useDeleteOrganizationMutation, useGetAllCountriesQuery, useGetAllSectorsQuery, useGetCurrentUserQuery, useGetOrganizationQuery, useHideOrganizationMutation, useUnhideOrganizationMutation } from '../../services';
import { DeleteButton, EditButton, GoBackButton, ToggleHiddenButton, UserListButton } from '../../components/Button';
import { ConfirmModal, defaultConfirmModalConfig } from '../../components/Custom';
import { getOrganizationUrl, getOrganizationUrlPath } from '../../helpers';
import { getRandomSectorDefaultKeyvisual } from './helpers';
import { resetKeyvisual, setKeyvisual, setKeyvisualTitle, setKeyvisualUrl } from '../../slices/keyvisual.slice';

import { isNil } from 'lodash';
import { Helmet } from 'react-helmet';
import { CustomMap } from '../../components/Map';
import { latLng } from 'leaflet';


export function OrganizationProfile({
}) {
  const { org_id, org_name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery();
  // const organization = useAppSelector(state => state.organizationDeprecated);
  const { data: organization, error: orgError, isLoading: orgIsLoading } = useGetOrganizationQuery({ org_id, org_name });
  const { data: allCountries, isLoading: allCountriesIsLoading, isError: allCountriesIsError } = useGetAllCountriesQuery(0);
  const { data: allSectors, isLoading: allSectorsIsLoading, isError: allSectorsIsError } = useGetAllSectorsQuery(0);
  const keyvisual = useAppSelector(state => state.keyvisual);
  const dispatch = useAppDispatch();
  const [key, setKey] = useState(location.state?.key || 'home');

  const loaded = useMemo(
    () => organization && !orgIsLoading && !orgError,
    [organization, orgIsLoading, orgError]
  );

  useEffect(() => {
    return () => {
      dispatch(resetKeyvisual());
    };
  }, []);

  useEffect(() => {
    if (!loaded) return;
    if (keyvisual.url && keyvisual.url === organization.keyvisual) return;
    if (organization.keyvisual) {
      dispatch(setKeyvisualUrl(organization.keyvisual));
      return;
    }
    if (organization.sectors && allSectors) {
      const keyvisualUrl = getRandomSectorDefaultKeyvisual(organization.sectors, allSectors);
      dispatch(setKeyvisualUrl(keyvisualUrl));
      return;
    }
    dispatch(setKeyvisualUrl(undefined));
  }, [loaded, organization?.keyvisual || (allSectors && organization?.sectors), keyvisual.url]);
  useEffect(() => {
    if (!loaded) return;
    if (keyvisual.title !== organization.name) {
      dispatch(setKeyvisualTitle(organization.name));
    };
  }, [loaded, organization?.name, keyvisual.title]);

  // useEffect(() => {
  //   if (orgError) console.log('organization error:', orgError);
  // }, [orgError]);

  const [deleteOrg, { isLoading: deleteOrgIsLoading, error: deleteOrgError }] = useDeleteOrganizationMutation();
  const [hideOrg, { isLoading: hideOrgIsLoading, error: hideOrgError }] = useHideOrganizationMutation();
  const [unhideOrg, { isLoading: unhideOrgIsLoading, error: unhideOrgError }] = useUnhideOrganizationMutation();
  const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig);
  function handleDeleteOrg() {
    setConfirmModalConfig({
      show: true,
      title: 'Delete Organization?',
      body: <>Deleting an organization is not Reversible. Please Confirm.</>,
      onCancel: () => { setConfirmModalConfig(defaultConfirmModalConfig) },
      onConfirm: () => { deleteOrg({ org_id: organization.id }); navigate("/orgs") },
    });
  }
  function handleToggleHidden() {
    if (!organization) {
      return;
    }
    if (organization.hidden_at) {
      unhideOrg({ org_id: organization.id }).unwrap().then(
        () => dispatch(organizationActions.clear()),
        () => dispatch(organizationActions.clear()),
      );
    } else {
      hideOrg({ org_id: organization.id }).unwrap().then(
        () => dispatch(organizationActions.clear()),
        () => dispatch(organizationActions.clear()),
      );
    }
  }
  return (<Card body>
    <Helmet>
      <title>{`${organization?.name} | ${APP_NAME}`}</title>
      <meta name="description" content={organization?.description || ''} />
    </Helmet>
    <ConfirmModal {...confirmModalConfig} />
    <Row>
      <Col md={3}>
        {orgIsLoading && <Spinner animation="border" />}
        <h6>
          <GoBackButton onClick={() => { navigate("/orgs"); dispatch(organizationActions.clear()); dispatch(resetKeyvisual()); }} />
          {/* {orgError && <>{' Error'} <Button onClick={() => { dispatch(organizationActions.getByIdDeprecated(org_id)); dispatch(resetKeyvisual()); }}>Reload</Button></>} */}
          {/* {organization && organization.deleted && organization.id == org_id && ' Organization deleted'} */}
          {/* {loaded && ' ' + organization.name + ' | '} */}
          {" | "}
          {loaded && organizationPolicies.update(currentUser, organization) &&
            <EditButton onClick={() => navigate({ pathname: `/orgs/${organization.id}/edit`, state: { key: key } })} />
          } {" "}
          {loaded
            && organizationPolicies.viewAnyUser(currentUser, organization)
            && <UserListButton onClick={() => navigate({ pathname: `/orgs/${organization.id}/users` })} />
          }
          {" "}
          {loaded && organizationPolicies.deleteOrg(currentUser, organization) && <>
            <DeleteButton onClick={() => handleDeleteOrg(organization.id)} />{" "}
            <ToggleHiddenButton
              tooltip={organization.hidden_at ? 'Make visible' : 'Hide'}
              toggle={organization.hidden_at}
              onToggle={handleToggleHidden}
              canToggle={() => organizationPolicies.deleteOrg(currentUser, organization)}
            />
          </>}
        </h6>
        {loaded && <Card>
          <Card.Img variant="top" src={getPublicUrl(organization.logo)} rounded />
          <Card.Body>
            Country: {allCountries && allCountries.items && !!organization.country_id &&
              allCountries.items.find(country => country.id == organization.country_id)?.name} <br />
            Sectors: <Sectors sectors={organization.sectors} />
            <br />
            {organization.collected_by && `Collected by: ${organization.collected_by}`}
          </Card.Body>
        </Card>}
      </Col>
      <Col>
        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
        >
          <Tab id='home' eventKey="home" title="Home">
            <Card body>
              <Card.Title>Name</Card.Title>
              {loaded ? <Card.Text>{organization.name}</Card.Text> : <Spinner animation="border" />}
              <Card.Title>Description</Card.Title>
              {loaded && <Card.Text style={{ whiteSpace: "pre-wrap" }}>
                {'Test\n' + organization.description}
              </Card.Text>}
              {loaded && <Card.Text>Url to Profile: <Link to={getOrganizationUrlPath(organization.url_name)}>{getOrganizationUrl(organization.url_name)}</Link></Card.Text>}
            </Card>
          </Tab>
          {loaded && organization.contacts.length > 0
            && <Tab id="contacts" eventKey="contacts" title="Contacts">
              {key === "contacts" && organization.contacts.map((contact, index) => <Card body key={"contacts." + index}>
                <Row>
                  <Col md="auto">
                    {contact.coordinates ? (
                      <CustomMap
                        initialMarker={{
                          lat: contact.coordinates.latitude,
                          lng: contact.coordinates.longitude,
                        }}
                        height="15rem"
                        width="15rem"
                        zoom={12}
                        draggable={false}
                        scrollWheelZoom={false}
                        disabled
                      />
                    ) : null}
                  </Col>
                  <Col><span>{contact.directions}</span></Col>
                </Row>
                <hr />
                <Row>
                  <Col>
                    {contact.name}<br />
                    {contact.street}<br />
                    {(contact.postal_code || '') + ' ' + (contact.city || '')}<br />
                    {contact.district}<br />
                  </Col>
                  <Col>
                    {contact.phones.map((phone) => <React.Fragment key={phone.id}>Phone: {phone.number}<br /></React.Fragment>)}
                    {contact.email && 'Email: ' + contact.email}<br />
                    {contact.website && 'Website: '}<a href={getWebUrl(contact.website)}>{contact.website}</a><br />
                  </Col>
                </Row>
              </Card>)}
            </Tab>}
          {loaded
            && organization.about
            && (organization.about.about_statement || organization.about.mission_statement
              || organization.about.guiding_principles || organization.about.investment_opportunities)
            && <Tab id="about" eventKey="about" title="About Us">
              {organization.about.about_statement && <Card body key="about.about_statement">
                <Card.Title>About</Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }} >{organization.about.about_statement}</Card.Text>
              </Card>}
              {organization.about.mission_statement && <Card body key="about.mission_statement">
                <Card.Title>Mission Statement</Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }} >{organization.about.mission_statement}</Card.Text>
              </Card>}
              {organization.about.guiding_principles && <Card body key="about.guiding_principles">
                <Card.Title>Guiding principles</Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }} >{organization.about.guiding_principles}</Card.Text>
              </Card>}
              {organization.about.investment_opportunities && <Card body key="about.investment_opportunities">
                <Card.Title>Investment Opportunities</Card.Title>
                <Card.Text style={{ whiteSpace: 'pre-wrap' }}>{organization.about.investment_opportunities}</Card.Text>
              </Card>}
            </Tab>
          }
          {loaded && organization.awards.length > 0
            && <Tab id="awards" eventKey="awards" title="References">
              {organization.awards.map((award, index) =>
                <Card body key={"awards." + index}><Row>
                  <Col md="3">{award.award_picture && <Image width="200" src={getPublicUrl(award.award_picture)}></Image>}</Col>
                  <Col>
                    <Card.Title>{award.name + (award.date ? `(${award.date})` : '')}</Card.Title>
                    <Card.Text>Institution: {award.institution}</Card.Text>
                    <br />
                    <Card.Text style={{ whiteSpace: 'pre-wrap' }}>{award.description}</Card.Text>
                  </Col>
                </Row></Card>
              )}
            </Tab>}
          {loaded && (organization.products || organization.services || organization.product_details)
            && <Tab id="products" eventKey="products" title="Products / Services">
              <OrganizationProfileProducts organization={organization} product_details={organization.product_details} />
            </Tab>}
          {/* <Tab id="management" eventKey="management" title="Management">
                        <Card body key="management">
                            No management!
                        </Card>
                    </Tab> */}
          {loaded && organization.indicators && <Tab id="indicators" eventKey="indicators" title="Indicators">
            <Card body>
              <Card.Text>
                {!isNil(organization.indicators.company_size) && `Company size: ${organization.indicators.company_size}`}
                {!isNil(organization.indicators.company_size) && <br />}
                {!isNil(organization.indicators.investment_volume) && `Investment volume: ${organization.indicators.investment_volume}`}
                {!isNil(organization.indicators.investment_volume) && <br />}
                {!isNil(organization.indicators.annual_sales) && `Annual sales: ${organization.indicators.annual_sales}`}
                {!isNil(organization.indicators.annual_sales) && <br />}
                {!isNil(organization.indicators.annual_revenue) && `Annual revenue: ${organization.indicators.annual_revenue}`}
                {!isNil(organization.indicators.annual_revenue) && <br />}
                <br />
                {!isNil(organization.indicators.number_branches) && `Number of branches: ${organization.indicators.number_branches}`}
                {!isNil(organization.indicators.number_branches) && <br />}
                <br />
                {!isNil(organization.indicators.num_employees_production) && `Number of employees in production: ${organization.indicators.num_employees_production}`}
                {!isNil(organization.indicators.num_employees_production) && <br />}
                {!isNil(organization.indicators.num_administrative_staff) && `Number of administrative staff: ${organization.indicators.num_administrative_staff}`}
                {!isNil(organization.indicators.num_administrative_staff) && <br />}
                {!isNil(organization.indicators.num_freelancers) && `Number of freelancers: ${organization.indicators.num_freelancers}`}
                {!isNil(organization.indicators.num_freelancers) && <br />}
                {!isNil(organization.indicators.num_total_employees) && `Total number of employees: ${organization.indicators.num_total_employees}`}
                {!isNil(organization.indicators.num_total_employees) && <br />}
                <br />
                {!isNil(organization.indicators.time_interval_between_training) && `Time interval between training: ${organization.indicators.time_interval_between_training}`}
                {!isNil(organization.indicators.time_interval_between_training) && <br />}
                {!isNil(organization.indicators.last_training) && `Last training: ${organization.indicators.last_training}`}
                {!isNil(organization.indicators.last_training) && <br />}
              </Card.Text>
            </Card>
          </Tab>}
          <Tab id="rating" eventKey="rating" title="Rating">
            <Card body>
              {organization?.rating_data && <OrganizationRatings data={organization.rating_data} />}
            </Card>
          </Tab>
        </Tabs>
      </Col>
    </Row>
  </Card>);
}

