import React, { FC, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { ErrorPageProptypes } from '.';
import { LoginPage } from '../pages/authentication';
import { RegisterPage } from '../pages/authentication';
import { Helmet } from 'react-helmet';

export const Unauthenticated: FC<ErrorPageProptypes> = ({
  message = "You have to be log in to access this page.",
}) => {
  const location = useLocation();
  const { from } = location.state || {};
  const [loginOrRegister, setLoginOrRegister] = useState(true);

  return <>
    <Card body>
      <Helmet>
        <title>{`401 Unauthenticated | ${APP_NAME}`}</title>
        <meta name="description" content={message} />
      </Helmet>
      <h2>Error 401: Unauthenticated.</h2>
      <p>{message}</p>
      <noindex />
      <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
      <p>Or login:</p>
    </Card>
    {loginOrRegister ? <LoginPage onToRegister={() => setLoginOrRegister(!loginOrRegister)} /> : <RegisterPage onToLogin={() => setLoginOrRegister(!loginOrRegister)} />}
  </>
}
