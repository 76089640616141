import { organizationConstants } from '../constants';

export function organizationDeprecated(state = {}, action) {
  switch (action.type) {
    case organizationConstants.CLEAR:
      return {};
    case organizationConstants.CLEAR_ALL:
      return {};
    case organizationConstants.GETBYID_REQUEST:
      return {
        loading: true,
        id: action.id
      };
    case organizationConstants.GETBYID_SUCCESS:
      return {
        id: action.id,
        data: action.response.data
      };
    case organizationConstants.GETBYID_FAILURE:
      return {
        id: action.id,
        error: action.error
      };

    case organizationConstants.STORE_REQUEST:
      return {
        request: action.data,
        loading: true
      };
    case organizationConstants.STORE_SUCCESS:
      return {
        data: action.response.data
      };
    case organizationConstants.STORE_FAILURE:
      return {
        request: action.data,
        error: action.error
      };

    case organizationConstants.UPDATE_REQUEST:
      return {
        id: action.id,
        request: action.data,
        loading: true
      };
    case organizationConstants.UPDATE_SUCCESS:
      return {
        id: action.id,
        data: action.response.data
      };
    case organizationConstants.UPDATE_FAILURE:
      return {
        id: action.id,
        error: action.error
      };

    case organizationConstants.DELETE_REQUEST:
      return {
        id: action.id,
        loading: true
      };
    case organizationConstants.DELETE_SUCCESS:
      return {
        deleted: true,
        id: action.id
      };
    case organizationConstants.DELETE_FAILURE:
      return {
        error: action.error,
        id: action.id
      };
    default:
      return state;
  }
}
