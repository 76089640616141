
import React, { useState, useEffect, FC } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form } from "react-bootstrap";
import { useAutoSaveForm } from '../../../hooks';
import { OrganizationAbout } from '../../../types';

interface AboutFormProps {
  data: OrganizationAbout;
  onSubmit?: (about: OrganizationAbout) => void;
  isSaving?: boolean;
  autosave?: boolean;
}
export const AboutForm: FC<AboutFormProps> = ({
  data: initialData,
  onSubmit,
  autosave = true,
}) => {
  const { formData, handleFieldChange, resetForm, saveManually, isDirty } = useAutoSaveForm({
    initialValues: initialData,
    shouldAutosave: autosave,
    onSave: onSubmit
  });
  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    saveManually();
  }
  return (<Form onSubmit={handleFormSubmit}>
    <Card body>
      <InputGroup>
        <Form.Label md={3}>Legal form</Form.Label>
        <FormControl id="about.legal_form"
          value={formData.legal_form  }
          onChange={e => handleFieldChange('legal_form', e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <Form.Label>About Statement</Form.Label>
        <FormControl id="about.about_statement" as="textarea" rows={5}
          value={formData.about_statement}
          onChange={e => handleFieldChange('about_statement', e.target.value)}
        />
      </InputGroup>
    </Card>
    <Card body>
      <InputGroup>
        <Form.Label>Vision</Form.Label>
        <FormControl id="about.vision" as="textarea" rows={5}
          value={formData.vision}
          onChange={(e) => handleFieldChange('vision', e.target.value)}
        />
      </InputGroup>
    </Card>
    <Card body>
      <InputGroup>
        <Form.Label>Mission Statement</Form.Label>
        <FormControl id="about.mission_statement" as="textarea" rows={5}
          value={formData.mission_statement}
          onChange={(e) => handleFieldChange('mission_statement', e.target.value)}
        />
      </InputGroup>
    </Card>
    <Card body>
      <InputGroup>
        <Form.Label>Guiding principles</Form.Label>
        <FormControl id="about.guiding_principles" as="textarea" rows={5}
          value={formData.guiding_principles}
          onChange={(e) => handleFieldChange('guiding_principles', e.target.value)}
        />
      </InputGroup>
    </Card>
    <Card body>
      <InputGroup>
        <Form.Label>Investment Opportunities</Form.Label>
        <FormControl id="about.investment_opportunities" as="textarea" rows={5}
          value={formData.investment_opportunities}
          onChange={(e) => handleFieldChange('investment_opportunities', e.target.value)}
        />
      </InputGroup>
    </Card>
  </Form>);
}
