
import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Table } from "react-bootstrap";

export function CountryTranslateFormTabEconomy(props) {

    const [data, setData] = useState(props.data);
    const set = {};

    [data.text_main_exports[props.primary_language_id], set.primary_text_main_exports] = useState(props.data.text_main_exports[props.primary_language_id] || '');
    [data.text_main_exports[props.translate_language_id], set.translate_text_main_exports] = useState(props.data.text_main_exports[props.translate_language_id] || '');

    [data.gdp, set.gdp] = useState(props.data.gdp || '');
    [data.gdp_growth, set.gdp_growth] = useState(props.data.gdp_growth || '');
    [data.gdp_export, set.gdp_export] = useState(props.data.gdp_export || '');
    [data.gdp_import, set.gdp_import] = useState(props.data.gdp_import || '');
    [data.stock_exchange, set.stock_exchange] = useState(props.data.stock_exchange || '');
    [data.inflation_rate, set.inflation_rate] = useState(props.data.inflation_rate || '');
    [data.growth_rate, set.growth_rate] = useState(props.data.growth_rate || '');
    [data.fdi, set.fdi] = useState(props.data.fdi || '');
    [data.gdi, set.gdi] = useState(props.data.gdi || '');
    [data.ppp, set.ppp] = useState(props.data.ppp || '');

    return (
        <Card body>
            <Row>
                <Col md="2">Main Exports :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_main_exports">
                    <Form.Control type="text"
                        value={data.text_main_exports[props.primary_language_id]}
                        onChange={e=>set.primary_text_main_exports(e.target.value)}
                    />
                </Form.Group></Col>
                <Col md="5"><Form.Group controlId="translate_economy_main_exports">
                    <Form.Control type="text"
                        value={data.text_main_exports[props.translate_language_id]}
                        onChange={e=>set.translate_text_main_exports(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">GDP :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_gdp">
                    <Form.Control type="text"
                        value={data.gdp}
                        onChange={e=>set.gdp(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">GDP growth :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_gdp_growth">
                    <Form.Control type="text"
                        value={data.gdp_growth}
                        onChange={e=>set.gdp_growth(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">GDP export :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_gdp_export">
                    <Form.Control type="text"
                        value={data.gdp_export}
                        onChange={e=>set.gdp_export(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">GDP import :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_gdp_import">
                    <Form.Control type="text"
                        value={data.gdp_import}
                        onChange={e=>set.gdp_import(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Sock exchange :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_stock_exchange">
                    <Form.Control type="text"
                        value={data.stock_exchange}
                        onChange={e=>set.stock_exchange(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Inflation rate :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_inflation_rate">
                    <Form.Control type="text"
                        value={data.inflation_rate}
                        onChange={e=>set.inflation_rate(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Growth rate :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_growth_rate">
                    <Form.Control type="text"
                        value={data.growth_rate}
                        onChange={e=>set.growth_rate(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Foreign direct investment (FDI):</Col>
                <Col md="4"><Form.Group controlId="primary_economy_fdi">
                    <Form.Control type="text"
                        value={data.fdi}
                        onChange={e=>set.fdi(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Gross National Income (GNI):</Col>
                <Col md="4"><Form.Group controlId="primary_economy_gni">
                    <Form.Control type="text"
                        value={data.gni}
                        onChange={e=>set.gni(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
            <Row>
                <Col md="2">Public private partnership (PPP) :</Col>
                <Col md="4"><Form.Group controlId="primary_economy_ppp">
                    <Form.Control type="text"
                        value={data.ppp}
                        onChange={e=>set.ppp(e.target.value)}
                    />
                </Form.Group></Col>
            </Row>
        </Card>
    );
}
