import React, { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

export function SendInviteForm({
  onSubmit = (email) => { },
}) {
  const [inviteEmail, setInviteEmail] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onSubmit(inviteEmail);
  };
  return <Card body>
    <h4>Invite User:</h4>
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formInviteUser">
        <Form.Label hidden>Email</Form.Label>
        <Form.Control type="email" placeholder='Email Address' value={inviteEmail} onChange={e => setInviteEmail(e.target.value)} />
        <Form.Text className="text-muted">
          We will send a unique invitation-link to the entered email.<br />
          The email address may or may not be registered when sending the mail but has to register/login using this address to accept the invitation.
        </Form.Text>
        
        <Button type='submit'>Send Invite</Button>
      </Form.Group>
    </Form>
  </Card>;
}
