export const organizationTypeConstants = {
    COMPANY: 1,
    NGO: 2,
    DIASPORA: 3,
    MINISTRY: 4,

    1:'Company',
    2:'NGO',
    3:'Diaspora',
    4:'Ministry',
} as const;
