import { forEach } from "lodash";

export type Id = number | string;

export interface BaseModel {
  id?: Id;

  created_by?: number;
  updated_by?: number;

  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  hidden_at?: string;
}

export type ValidationErrors<T> = { [P in keyof T]?: string[] }
export type BaseAPIError = {
  status: 422,
  data: {
    message: string,
    errors?: {
      [key: string]: string[],
    }
  }
} | {
  status: 400 | 401 | 403 | 404 | 500 | 503,
  data?: {
    message?: string,
  },
};
export function isValidationErrorsResponse<T>(
  error: unknown
): error is { status: 422, errors: ValidationErrors<T>} {
  if (error === null || typeof error !== 'object') return false;
  if (!('status' in error) || error.status !== 422) return false;
  if (!('errors' in error) || typeof error.errors !== 'object' || error.errors === null) return false;
  return Object.entries(error.errors).every(([key, value]) => {
    if (value === null || typeof value !== 'object') return false;
    if (!Array.isArray(value)) return false;
    return value.every((item) => typeof item === 'string');
  });
}
