
// import { createLogger } from 'redux-logger';
import rootReducer from '../reducers';
import { configureStore } from '@reduxjs/toolkit';
import { abibApi } from '../services';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

// const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const loggerMiddleware = createLogger();

export const store = configureStore({
  reducer: rootReducer,
  // enhancers: composeEnhancer(applyMiddleware(
  //   thunkMiddleware,
  //   loggerMiddleware
  // )),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(abibApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
