import { BasePlacement } from '@popperjs/core';
import { Property } from 'csstype';
import React, { FC, InputHTMLAttributes, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { CalendarDate, X } from 'react-bootstrap-icons';
import { CustomTooltipPropTypes } from '../Custom';
import { GenericInput, GenericInputProps, position } from './GenericInput';


export interface DatePickerProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  labelPlacement?: BasePlacement,
  icon?: React.ReactNode,
  tooltip?: React.ReactNode,
  tooltipColor?: Property.Color,
  tooltipProps?: CustomTooltipPropTypes,
}
export const DatePicker : FC<GenericInputProps> = ({
  id,
  name = id,
  tooltipColor = 'blue',
  // isValid,
  // isInvalid,
  label = "",
  labelPlacement = 'top',
  tooltip = "Click to pick a date",
  ...props
}) => {
  return <GenericInput
    id={id}
    label={label}
    labelPlacement={labelPlacement}
    type="date"
    tooltip={tooltip}
    tooltipColor={tooltipColor}
    icon={<CalendarDate fontSize={'1rem'} color={tooltipColor} />}
    name={name}
    {...props}
  />
}
