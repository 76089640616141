import { Icon, LatLng, LatLngExpression, LeafletEventHandlerFnMap, Marker as LeafletMarker } from "leaflet"
import React, { FC, useCallback, useMemo, useRef, useState } from "react"
import { Marker, MarkerProps, Popup } from "react-leaflet"
import { getPublicUrl } from "../../helpers";


const markerIcon = generateMarkerIcon();

function generateMarkerIcon() {
  return new Icon({
    iconUrl: getPublicUrl('images/vendor/leaflet/dist/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: getPublicUrl('images/vendor/leaflet/dist/marker-shadow.png'),
    shadowSize: [41, 41],
    shadowAnchor: [13, 41],
  });
}

interface DraggableMarkerProps extends MarkerProps {
  draggable?: boolean
  setDraggable?: (value: boolean | ((prevValue: boolean) => boolean)) => unknown
  onDrag?: (position: LatLng) => unknown
  onDragEnd?: (position: LatLng) => unknown
  onDragStart?: (position: LatLng) => unknown
  disabled?: boolean
}
export const DraggableMarker: FC<DraggableMarkerProps> = ({
  draggable,
  setDraggable,
  onDrag,
  onDragEnd,
  onDragStart,
  eventHandlers: propsEventHandlers,
  ...props
}) => {
  // const [draggable, setDraggable] = useState(false)
  const markerRef = useRef<null | LeafletMarker>(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          onDragEnd?.(marker.getLatLng())
        }
      },
      drag() {
        const marker = markerRef.current
        if (marker != null) {
          const latlng = marker.getLatLng();
          onDrag?.(latlng)
        }
      },
      dragStart() {
        const marker = markerRef.current
        if (marker != null) {
          const latlng = marker.getLatLng();
          onDragStart?.(latlng)
        }
      }
    } as LeafletEventHandlerFnMap),
    [],
  )
  const toggleDraggable = useCallback(() => {
    setDraggable?.((prevValue) => !prevValue)
  }, [])

  return (
    <Marker
      draggable={draggable}
      eventHandlers={{...eventHandlers, ...propsEventHandlers}}
      ref={markerRef}
      {...props}
    >
      <Popup minWidth={90}>
        <span onClick={toggleDraggable}>
          {draggable
            ? 'Marker is draggable'
            : 'Click here to make marker draggable'}
        </span>
      </Popup>
    </Marker>
  )
}
