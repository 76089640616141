import React, { FC } from 'react';
import { CheckCircle, CheckCircleFill, Pencil } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';
import { Property } from 'csstype';

export const SelectButton: FC<CustomButtonProptypes & { isSelected?: boolean, isSelectedProps?: CustomButtonProptypes }> = ({
  // id = 'edit-button',
  color = 'DarkCyan',
  isSelected = false,
  fontSize = '1rem',
  tooltip = "Select",
  isSelectedProps,
  ...props
}) => {
  const {
    color: isSelectedColor = 'green',
    fontSize: isSelectedFontSize = '1.5rem',
    tooltip: isSelectedTooltip = "Currently selected",
    ...isSelectedPropsRest
  } = isSelectedProps || {};
  const propsInUse = (isSelected && isSelectedProps) ? isSelectedPropsRest : props;
  const colorInUse = isSelected ? isSelectedColor : color;
  const fontSizeInUse = isSelected ? isSelectedFontSize : fontSize;
  const tooltipInUse = isSelected ? isSelectedTooltip : tooltip;

  return <GenericButton
    // id={id}
    color={colorInUse}
    tooltip={tooltipInUse}
    {...propsInUse}
  >
    {(isSelected) ? (
      <CheckCircleFill color={isSelectedColor} fontSize={isSelectedFontSize} />
    ) : (
      <CheckCircle color={color} fontSize={fontSize} />
    )}
  </GenericButton>
}
