
import { createSlice } from '@reduxjs/toolkit';
import { apiAuthentication } from '../services';

export interface AuthenticationState {
  token?: string;
  user?: Auth;
  loggedIn?: boolean;
}
export interface Auth {
  id: number;
  global_role_id: number;
  token: string;
  name?: string;
}
function isAuth(o: any): o is Auth {
  return o.id !== undefined && o.global_role_id !== undefined && o.token !== undefined;
}
function isAuthenticationState(o: any): o is AuthenticationState {
  if (o === null || typeof o !== 'object' || Array.isArray(o)) {
    return false;
  }
  if (
    ("token" in o && typeof o.token !== 'string')
    || ("user" in o && !isAuth(o.user))
    || ("loggedIn" in o && typeof o.loggedIn !== 'boolean')
  ) {
    return false;
  }
  return true;
}
const authFromLocalStorage = JSON.parse(localStorage.getItem('authentication') ?? '{}');
const auth = isAuthenticationState(authFromLocalStorage) ? authFromLocalStorage : undefined;
const unauthorized: AuthenticationState = { loggedIn: false, user: undefined, token: undefined };

const slice = createSlice({
  name: 'authentication',
  initialState: auth || unauthorized,
  reducers: {
    setCredentials: (state, { payload: { loggedIn = true, user, token } }) => {
      const obj = {
        loggedIn,
        user,
        token
      };
      localStorage.setItem('authentication', JSON.stringify(obj));
      return obj;
    },
    clearCredentials: (state, action) => {
      localStorage.removeItem('authentication');
      return unauthorized;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      apiAuthentication.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        const authentication = {
          loggedIn: true,
          token: payload.token,
          user: payload,
        };
        localStorage.setItem('authentication', JSON.stringify(authentication));
        return authentication;
      }
    );
    builder.addMatcher(
      apiAuthentication.endpoints.register.matchFulfilled,
      (state, { payload }) => {
        const authentication = {
          loggedIn: true,
          token: payload.token,
          user: payload,
        };
        localStorage.setItem('authentication', JSON.stringify(authentication));
        return authentication;
      }
    );
    builder.addMatcher(
      apiAuthentication.endpoints.logout.matchPending,
      () => {
        localStorage.removeItem('authentication');
        return unauthorized;
      }
    );
    // builder.addMatcher(
    //   apiAuthentication.endpoints.getCurrentUser.matchFulfilled,
    //   (state, { payload }) => {
    //     if (payload && payload.status == 401) {
    //       localStorage.removeItem('authentication');
    //       return unauthorized;
    //     }
    //   }
    // );
    builder.addMatcher(
      apiAuthentication.endpoints.getCurrentUser.matchRejected,
      (state, { payload }) => {
        if (payload && payload.status === 401) {
          localStorage.removeItem('authentication');
          return unauthorized;
        }
        return state;
      }
    );
  },
})

export const { setCredentials, clearCredentials } = slice.actions

export default slice.reducer

// export const useAuthentication = (state: RootState) => state.authentication
