
import { CurrentUser, Organization, OrganizationRole, OrganizationUser } from "../types";
import { globalRole, organizationRole } from "./authorization.constants";

export const organizationRolePolicies = {
  viewAny,
  view,
  create,
  update,
  deleteRole,
  forceDelete,
};
function viewAny(currentUser?: CurrentUser, organization?: Organization) {
  return true;
};
function view(currentUser?: CurrentUser, organization?: Organization, role?: OrganizationRole) {
  return true;
};
function create(currentUser?: CurrentUser, organization?: Organization) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role[globalRole.can_edit_all_organizations]) {
    return true;
  }
  const orgRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (orgRole) {
    return orgRole[organizationRole.can_edit_user_relation_roles];
  }
  return false;
};
function update(currentUser?: CurrentUser, organization?: Organization, role?: OrganizationRole, orgUsers?: OrganizationUser[]) {
  if (!currentUser || !organization || !role || !orgUsers) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role[globalRole.can_edit_all_organizations]) {
    return true;
  }
  if (orgUsers.find(orgUser => orgUser.id == organization.owner_id && orgUser.role_id == role.id)) {
    return false;
  }
  const orgRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (orgRole) {
    return orgRole[organizationRole.can_edit_organization];
  }
  return false;
};
function deleteRole(currentUser?: CurrentUser, organization?: Organization, role?: OrganizationRole, orgUsers?: OrganizationUser[]) {
  //
  if (!currentUser || !organization || !role || !orgUsers) {
    return false;
  }
  if (orgUsers.find(orgUser => orgUser.role_id == role.id)) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role[globalRole.can_edit_all_organizations]) {
    return true;
  }
  const orgRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (orgRole) {
    return orgRole[organizationRole.can_edit_organization];
  }
  return false;
};
function forceDelete(currentUser?: CurrentUser, organization?: Organization, role?: OrganizationRole, relations?: OrganizationUser[]) {
  //
  return deleteRole(currentUser, organization, role, relations);
};
