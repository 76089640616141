import React, { FC } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from './';

export const DeleteButton: FC<CustomButtonProptypes> = ({
  // id = 'delete-button',
  color = 'red',
  fontSize = '1rem',
  tooltip= "Delete",
  ...props
}) => {
  return <GenericButton
    // id={id}
    color={color}
    tooltip={tooltip}
    {...props}
  >
    <Trash color={color} fontSize={fontSize} />
  </GenericButton>
}
