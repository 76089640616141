import React, { FC, useState } from 'react';
import { Button, Carousel, Col, Form, Image, ImageProps, Row } from 'react-bootstrap';
import { KeyvisualList, Organization } from '../../../types';
import { getPublicUrl, useAppDispatch } from '../../../helpers';

import { alertActions } from '../../../actions';
import { DeleteButton, EditButton, GenericButton, SelectButton } from '../../../components/Button';
import { Property } from 'csstype';
import '../profile/styles.css';

const placeholderPhoto = `${APP_FILEADMIN_URL}/fileadmin/templates/assets/images/placeholder_photo.png`;
interface KeyvisualFormProps {
  keyvisualList?: KeyvisualList;
  canUpload?: boolean;
  canSelect?: boolean;
  canDelete?: boolean;
  onUpload: (file: File) => void;
  onSelect: (path: string) => void;
  onDelete: (path: string) => void;
}
enum Status {
  None = 'None',
  UploadFile = 'UploadFile',
  SelectKeyvisual = 'SelectKeyvisual',
}

type KeyvisualFormSelectVersion = 'minimalist' | 'preview-carousel' | 'preview-grid';
interface KeyvisualFormSelectProps {
  id: string;
  label?: string;
  version?: KeyvisualFormSelectVersion;
  current?: string;
  keyvisuals: string[];
  canDelete?: boolean;
  canSelect?: boolean;
  onDelete?: (path: string) => void;
  onSelect?: (path: string) => void;
  interval?: number;
}
interface KeyvisualCarouselProps {
  id: string;
  onDelete?: (path: string) => void;
  onSelect?: (path: string) => void;
  current?: string;
  keyvisuals: string[];
  canDelete?: boolean;
  canSelect?: boolean;
  interval: number;
  width: Property.Width;
  height: Property.Height;
}
const KeyvisualCarousel: FC<KeyvisualFormSelectProps & { width?: Property.Width, height?: Property.Height }> = ({
  id = "select-keyvisual-carousel",
  onDelete = () => { }, onSelect = () => { },
  current = "", keyvisuals,
  canDelete = false,
  canSelect = false,
  interval = 3000,
  width = 300, height = 150,
}) => {
  ;
  return (<Carousel
    touch pause="hover"
    style={{ width: width, height: height }}
  >
    {keyvisuals.map(
      (path, index) => {
        const isSelected = getPublicUrl(path) === getPublicUrl(current);
        return (<Carousel.Item key={index} interval={interval} style={{ width: "100%" }}>
          <div style={{ width, height, overflow: "hidden", backgroundColor: 'lightgrey', }}>
            <Image className='centered' src={getPublicUrl(path, placeholderPhoto)} style={{ maxWidth: width, maxHeight: height }} />
            <div className='centered' style={{
              position: 'absolute',
              top: '1rem',
            }}>
              {!isSelected && canDelete && <DeleteButton
                id={`${id}-delete-${index}`}
                tooltip="Permanently Delete Keyvisual"
                onClick={() => onDelete(path)}
              />} {" "}
              {(isSelected || canSelect) && <SelectButton
                id={`${id}-select-${index}`}
                isSelected={isSelected} disabled={isSelected}
                onClick={() => onSelect(path)}
              />}
            </div>
          </div>
        </Carousel.Item>)
      }
    )}
  </Carousel>);
};

const KeyvisualFormSelect: FC<KeyvisualFormSelectProps> = ({
  label, id = "select-keyvisual", version = 'minimalist',
  onDelete = () => { }, onSelect = () => { },
  current, keyvisuals,
  canDelete = false,
  canSelect = false,
}) => {
  if (version === 'minimalist') {
    return (<Form.Group id={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <Form.Control as="select" onChange={e => onSelect(e.target.value)}>
        <option disabled={!current} value="">Select keyvisual</option>
        {keyvisuals.map((path, index) => <option disabled={current === path} key={index} value={path}>{path}</option>)}
      </Form.Control>
    </Form.Group>);
  }
  if (version === 'preview-carousel') {
    return (<Form.Group id={id}>
      {label && <Form.Label>{label}</Form.Label>}
      <KeyvisualCarousel
        id={id} label={label}
        current={current} keyvisuals={keyvisuals}
        canDelete={canDelete} onDelete={onDelete}
        canSelect={canSelect} onSelect={onSelect}
      />
    </Form.Group>)
  }
  if (version === 'preview-grid') {
    return (<>
      <h3>Implementation probably coming at some point</h3>
    </>)
  }
  return <>Version Not Implemented.</>
};

export const KeyvisualForm: FC<KeyvisualFormProps> = ({
  onSelect = () => { }, onDelete = () => { }, onUpload = () => { },
  canDelete = false, canSelect = false, canUpload = false,
  keyvisualList: { keyvisual, previousKeyvisuals, standardKeyvisuals } = { standardKeyvisuals: [] },
}) => {
  const dispatch = useAppDispatch();
  return (
    <Form>
      <h3>Keyvisual</h3>
      <Row>
        <Col><Image style={{ maxHeight: '150pt', maxWidth: '100%', }} src={getPublicUrl(keyvisual, placeholderPhoto)} /></Col>
        {keyvisual && <Col><Button variant='danger' onClick={() => onSelect('')}>Clear Keyvisual</Button></Col>}
      </Row>
      <Row>
        {(previousKeyvisuals?.length || false) && (canSelect || canDelete) && <Col md={4}><KeyvisualFormSelect
          id='select-previous-keyvisual' label="Pick one of your uploaded keyvisuals:"
          version='preview-carousel'
          current={keyvisual} keyvisuals={previousKeyvisuals || []}
          canDelete={canDelete} onDelete={onDelete}
          canSelect={canSelect} onSelect={onSelect}
        /></Col>}
        {(standardKeyvisuals.length || false) && canSelect && <Col md={4}><KeyvisualFormSelect
          id='select-standard-keyvisual' label="Or Pick a standard keyvisual."
          version='preview-carousel'
          current={keyvisual} keyvisuals={standardKeyvisuals}
          canSelect={canSelect} onSelect={onSelect}
        /></Col>}
        {canUpload && <Col>
          <Form.Group id="upload-keyvisual">
            <Form.Label>Or Upload a keyvisual:</Form.Label>
            <Form.Control
              type="file"
              onChange={e => {
                if ('files' in e.target && e.target.files?.[0]) {
                  onUpload(e.target.files[0]);
                } else {
                  dispatch(alertActions.error('No files found.'));
                }
              }}
            />
          </Form.Group>
        </Col>}
      </Row>
    </Form>
  );
}
