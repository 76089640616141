import React, { FC, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ErrorPageProptypes } from '.';
import { useAppSelector } from '../helpers';

import { useGetCurrentUserQuery, useLogoutMutation } from '../services';
import { AuthenticationState } from '../slices/authentication.slice';
import { Helmet } from 'react-helmet';


export const Forbidden: FC<ErrorPageProptypes> = ({
  message = "You are not allowed to access this page or resource.",
  ...props
}) => {
  const { from } = useLocation().state || {};
  const [logout, { }] = useLogoutMutation();
  const authentication : AuthenticationState = useAppSelector(state => state.authentication);
  const { data: currentUser, error, isLoading } = useGetCurrentUserQuery();
  const navigate = useNavigate();

  return <Card body>
    <Helmet>
      <title>{`403 Forbidden | ${APP_NAME}`}</title>
      <meta name="description" content={message} />
    </Helmet>
    <h2>Error 403: Forbidden</h2>
    <p>{message}</p>
    <noindex />
    <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
    {(authentication.loggedIn && currentUser) && <>
      <p>Or change account: </p>
      <PersonCircle />
      {' ' + currentUser?.name + ' '} <Button onClick={() => logout()}>Logout</Button>
    </>}
  </Card>
}
