import { Property } from 'csstype';
import React, { FC } from 'react';
import { IconProps, Star, StarFill, StarHalf } from 'react-bootstrap-icons';

interface StarRatingProps extends IconProps {
  rating?: 0 | 0.5 | 1 | 1.5 | 2 | 2.5 | 3 | 3.5 | 4 | 4.5 | 5
}

const StarRating: FC<StarRatingProps> = ({
  rating = 0,
  color = 'orange',
  fontSize = '1.5rem',
  ...props
}) => {
  const stars = [];
  let i = 0;
  // display filled stars
  while (i <= rating - 1) {
    stars.push(
      <StarFill key={i} color={color} fontSize={fontSize} {...props} />
    );
    i++;
  }
  // display partially filled star
  if (i < rating) {
    stars.push(
      <StarHalf key={i} color={color} fontSize={fontSize} {...props} />
    );
    i++;
  }
  // fill up with empty stars
  while (i < 5) {
    stars.push(
      <Star key={i} color={color} fontSize={fontSize} {...props} />
    );
    i++;
  }
  return <>{stars}</>;
}

export default StarRating;
