
import { Property } from 'csstype';
import React, { FC, MouseEventHandler, useMemo, useRef } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { CustomTooltip } from '../Custom';
export interface GenericButtonProptypes extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: Property.Color,
  tooltip?: React.ReactNode,
  tooltipProps?: object,
}
export const GenericButton: FC<GenericButtonProptypes> = ({
  // id = "custom-button",
  className = "btn btn-link",
  type = "button",
  color = "grey",
  tooltip = "",
  tooltipProps = {},
  children = "",
  style = {},
  ...props
}) => {
  const targetRef = useRef<HTMLButtonElement>(null);
  return <OverlayTrigger overlay={
    (overlayProps) => <CustomTooltip
      {...overlayProps}
      target={targetRef.current} backgroundColor={color} {...tooltipProps}
    >
      {tooltip}
    </CustomTooltip>
  }>
    <button
      {...props}
      className={className}
      style={{
        padding: 0,
        margin: 0,
        ...style,
      }}
      type={type}
      ref={targetRef}
    >
      {children}
    </button>
  </OverlayTrigger>;
}
