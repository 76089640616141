import React, { useState } from 'react';
import { Card, Col, Form, FormControl, Row, Table } from 'react-bootstrap';
import { AddButton } from '../../components/Button';
import { defaultConfirmModalConfig } from '../../components/Custom';
import {
  useCreateOrganizationRoleMutation,
  useDeleteOrganizationRoleMutation,
  useSetDefaultOrganizationRoleMutation,
  useUpdateOrganizationRoleMutation,
} from '../../services';
import { OrganizationRoleTableRow } from './OrganizationRoleTableRow';

const orgRights = [
  'can_edit_organization',
  'can_view_history',
  'can_submit_data',
  'can_approve_data',
];
const relationRights = [
  'can_view_organization_users',
  'can_view_organization_invites',
  'can_invite_users_to_organization',
  'can_accept_and_reject_join_requests',
  'can_remove_users_from_organization',
  'can_assign_roles',
  'can_edit_user_relation_roles',
];
const storageRights = [
  'can_download_storage',
  'can_upload_storage',
  'can_edit_storage',
];
const rights = [
  'can_view_organization_users',
  'can_view_organization_invites',
  'can_invite_users_to_organization',
  'can_accept_and_reject_join_requests',
  'can_remove_users_from_organization',
  'can_assign_roles',
  'can_edit_organization',
  'can_edit_user_relation_roles',
  'can_view_history',
  'can_submit_data',
  'can_approve_data',
  'can_download_storage',
  'can_upload_storage',
  'can_edit_storage',
];
export function OrganizationRoles({
  roles = [],
  currentUser = {},
  org_id = roles?.find((value) => value.organization_id)?.organization_id,

  error = null,
  isLoading = false,

  checkCanCreate = () => false,
  checkCanEdit = (role) => false,
  checkCanDelete = (role) => false,
  checkCanSetDefault = () => false,

  checkIsDefault = (role) => false,

  initialConfirmModalConfig = defaultConfirmModalConfig,
  resetConfirmModalConfig = () => { setConfirmModalConfig(initialConfirmModalConfig) },
  setConfirmModalConfig = ({ }) => { },
}) {
  const [createRole, { data: createRoleData, error: createRoleError, isLoading: createRoleIsLoading }] = useCreateOrganizationRoleMutation();
  const [deleteRole, { data: deleteRoleData, error: deleteRoleError, isLoading: deleteRoleIsLoading }] = useDeleteOrganizationRoleMutation();
  const [updateRole, { data: updateRoleData, error: updateRoleError, isLoading: updateRoleIsLoading }] = useUpdateOrganizationRoleMutation();
  const [setDefaultRole, { data: setDefaultRoleData, error: setDefaultRoleError, isLoading: setDefaultRoleIsLoading }] = useSetDefaultOrganizationRoleMutation();

  const [showCreateRoleForm, setShowCreateRoleForm] = useState(false);
  function toggleCreateRoleForm() {
    setShowCreateRoleForm(!showCreateRoleForm);
  }
  function roleIsValid(checkRole) {
    return checkRole && checkRole.name && roles && !roles.some(role => checkRole.name.toLowerCase() == role.name.toLowerCase() && role.id != checkRole.id)
  }
  function handleUpdateRole(role_id, role) {
    if (!org_id || !role_id || !role) return false;
    const r = currentUser?.organization_roles?.find(x => x.organization_id == org_id);
    if (!r) return false;
    if (r.id == role_id) {
      return setConfirmModalConfig({
        show: true,
        body: <>You are about to change your own role.<br />You might not be able to reverse this.</>,
        onCancel: resetConfirmModalConfig,
        onConfirm: () => {
          updateRole({ org_id, role_id, role });
          resetConfirmModalConfig();
        },
      });
    }
    updateRole({ org_id, role_id, role });
  }

  return <Table striped bordered hover size="sm">
    <thead>
      <tr>
        <th style={{ width: "5%" }}>Default</th>
        <th style={{ width: "15%" }}>Role</th>
        <th style={{ width: "20%" }}>Organization</th>
        <th style={{ width: "30%" }}>Users</th>
        <th style={{ width: "20%" }}>Storage</th>
        <th style={{ width: "10%" }}></th>
      </tr>
    </thead>
    <tbody>
      {error ? (
        <tr><td colSpan={6}>Oh no, there was an error</td></tr>
      ) : isLoading ? (
        <tr><td colSpan={6}>Loading...</td></tr>
      ) : (roles) && roles.map((role, index) => <OrganizationRoleTableRow
        key={index}
        role={role}
        rightStructure={[orgRights, relationRights, storageRights]}

        canEdit={checkCanEdit(role)}
        canDelete={checkCanDelete(role)}
        canSetDefault={checkCanSetDefault()}

        isValid={roleIsValid}
        isDefault={checkIsDefault(role)}

        onSetDefault={() => setDefaultRole({ org_id, roleName: role.name })}
        onSave={(r) => handleUpdateRole(role.id, r)}
        onDelete={() => { deleteRole({ org_id, role_id: role.id }) }}
      />)}
      {!error && !isLoading && checkCanCreate() && (showCreateRoleForm ? <OrganizationRoleTableRow
        rightStructure={[orgRights, relationRights, storageRights]}
        initializeEdit={true}
        canEdit={true}
        canDelete={false}
        onCancel={() => setShowCreateRoleForm(false)}
        onSave={(r) => { createRole({ org_id, role: r }); toggleCreateRoleForm(); }}
        isValid={roleIsValid}
      /> : <tr><td></td><td colSpan={5}><AddButton onClick={() => setShowCreateRoleForm(true)} /></td></tr>)}
    </tbody>
  </Table>
}
