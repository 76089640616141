import React, { FC, useMemo } from 'react';
import { Eye, EyeFill, EyeSlash, EyeSlashFill } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';

export interface ToggleHiddenButtonProptypes extends CustomButtonProptypes {
  toggle?: boolean,
  canToggle?: () => boolean,
  onToggle?: (toggle: boolean) => void,
}
export const ToggleHiddenButton: FC<ToggleHiddenButtonProptypes> = ({
  id = "toggle-hidden",
  color = 'black',
  fontSize = '1rem',
  disabled = false,
  tooltip,
  toggle = false,
  onToggle = () => { },
  canToggle = () => true,
  ...props
}) => {
  const tooltipInternal = useMemo(
    () => !disabled && canToggle() && (tooltip || (toggle ? 'Make Visible' : 'Hide')),
    [tooltip, toggle, disabled, canToggle()]
  );
  return <GenericButton
    id={id}
    color={color}
    disabled={disabled}
    tooltip={tooltipInternal}
    onClick={handleToggle}
    {...props}
  >
    {toggle
      ? <Eye color={color} fontSize={fontSize}  />
      : <EyeSlashFill color={color} fontSize={fontSize} />
    }
  </GenericButton>

  function handleToggle() {
    if (!disabled && canToggle()) onToggle(!toggle);
  }
}
