// Need to use the React-specific entry point to import createApi
import { BaseQueryFn, createApi, EndpointDefinitions, FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { RootState } from '../helpers';

export const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
} as const;
export const tags = {
  authentication: 'authentication',
  currentUser: 'currentUser',

  country: 'country',
  countryList: 'countryList',
  countryRatingList: 'countryRatingList',

  globalRole: 'globalRole',
  globalRoleList: 'globalRoleList',

  language: 'language',
  languageList: 'languageList',

  organization: 'organization',
  organizationBlockedJoinRequests: 'organizationBlockedJoinRequests',
  organizationInvites: 'organizationInvites',
  organizationJoinRequests: 'organizationJoinRequests',
  organizationList: 'organizationList',
  organizationRatingList: 'organizationRatingList',
  organizationUsers: 'organizationUsers',
  organizationTypes: 'organizationTypes',

  sector: 'sector',
  sectorList: 'sectorList',

  user: 'user',
  userList: 'userList',
}

export const isBaseAPIError = (o: any): o is BaseAPIError => {
  if (!o || typeof o !== 'object' || Array.isArray(o)) {
    return false;
  }
  if (
    ("status" in o && typeof o.status === 'number')
    || ("data" in o && typeof o.data === 'object' && o.data !== null)
  ) {
    return true;
  }
  return false;
};

export type BaseAPIError = {
  status: 400 | 401 | 403 | 404 | 422 | 500 | 503,
  data: {
    message?: string,
    data?: any,
    errors?: {
      [key: string]: string[],
    }
  },
};

// Define a service using a base URL and expected endpoints
export const abibApi = createApi({
  reducerPath: 'abibApi',
  baseQuery: ({ uploadFile = false, ...args }, ...moreArgs) => {
    const temp = fetchBaseQuery({
      baseUrl: APP_API_URL,
      prepareHeaders: (headers, { getState }) => {
        const { authentication, ...state } = getState() as RootState;
        if (!uploadFile) {
          headers.set('Content-Type', 'application/json');
        };
        headers.set('Accept', 'application/json');
        headers.set('Cookie', 'XDEBUG_SESSION=PHPSTORM',)
        // By default, if we have a token in the store, let's use that for authenticated requests
        const token = authentication.token;
        if (token) {
          headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
      },
    });
    return temp(args, ...moreArgs);
  },
  tagTypes: Object.values(tags),
  endpoints: (builder) => ({}),
  // endpoints: (builder): EndpointDefinitions  => ({} as EndpointDefinitions),
});

