
import React, { useState, useEffect, useMemo } from 'react';
import { Card, Row, Col, Form, Table, Pagination, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { getPublicUrl, useAppDispatch, useAppSelector } from '../../helpers';
import { useGetCountryListQuery, useLazyGetCountryListQuery } from '../../services';
import { CustomPagination } from '../../components/Custom';
import { CountryListFilter } from '../../types';

function CountryList(props: {
  filter?: CountryListFilter;
}) {
  const language = useAppSelector(state => state.language);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const countryParams = useMemo(() => {
    return {
      page,
      perPage,
      filter: props.filter,
    }
  }, [page, perPage, props.filter]);
  const { data: countries, isLoading: countriesIsLoading, isError: countriesIsError } = useGetCountryListQuery(countryParams);

  function handlePaginationChange(newPage = page, newPerPage = perPage) {
    setPage(newPage);
    setPerPage(newPerPage);
  }

  return (<Card body><Row>
    <Col md={3}>
      {/* <SearchOptions /> */}
    </Col>
    <Col>
      <h3>List Countries:
        {/* <Link to={`/createCountry`} onClick={()=>dispatch(countryActions.clear())}>new</Link> */}
      </h3>
      {/* <span>{JSON.stringify(countries)}</span> */}
      <Table striped bordered hover>
        {/* <thead>
                        <tr>
                            <th key="flag">Flag</th>
                            <th key="name">Name</th>
                        </tr>
                    </thead> */}
        <tbody>
          {countriesIsLoading && <tr><td><Spinner animation="border" /></td></tr>}
          {countries?.items && countries.items.map((country, index) =>
            <tr key={index}>
              {/* <td key="flag"><Link to={`/countries/${country.id}`}></Link></td> */}
              <td key="name"><Link to={`/countries/${country.id}`}><img src={getPublicUrl(country.flag)} height="30" /> {country.text_name[language.id] || country.name}</Link></td>
              <td key="continent">{country.continent}</td>
            </tr>
          )}
        </tbody>
      </Table>
      {countries?.pagination &&
        <CustomPagination pagination={countries.pagination} onChange={handlePaginationChange} />
      }
    </Col>
  </Row></Card>);
}

export { CountryList };
