import { CurrentUser, Organization, OrganizationListItem, User } from "../types";
import { organizationRole } from "./authorization.constants";

export const organizationPolicies = {
  viewAny, // immer true wenn hidden_at == null
  view, // immer true wenn hidden_at == null
  create,
  createMany,
  /**
   * zu update:
   * für die App eigentlich alles, was an einer Organisation geändert werden könnte
   * betrifft alle Eingabedaten der Organisation, die im Profil geändert werden können und aller einzig von dieser Organisation abhängigen Beziehungen (About, Contacts, Products, ...)
   *  -> also einschließlich ändern, hinzufügen und löschen von Contacts, Products, ...
   * - jedoch nicht Daten, die generell mit Userbeziehungen/-rechten zu tun haben oder die seperat vom Server behandelt werden (UserRelations, Roles, Ratings, ...)
   */
  update,
  deleteOrg,
  upload, // im moment noch nur ein upload-check, egal ob oder was überschrieben wird (bspw. logo, keyvisual etc.)
  editStorage,
  viewHistory, // wahrscheinlich nicht für app relevant
  submitData, // noch keine Anwendung, aber evtl zukünftig
  viewAnyUser, // weiß nicht, wie relevant
  viewAnyInvite,
  inviteUser,
  withdrawInvite,
  joinRequest,
  withdrawJoinRequest,
  viewAnyJoinRequest,
  acceptJoinRequest,
  rejectJoinRequest,
  viewAnyBlockedJoinRequest,
  blockJoinRequest,
  unblockJoinRequest,
  assignRole,
  removeUser,
};
function viewAny(currentUser?: CurrentUser, showIsHidden: boolean = false) {
  {
    //
    if (showIsHidden && !currentUser) {
      return false;
    }
    if (showIsHidden) {
      return currentUser?.is_owner || currentUser?.global_role.can_edit_all_organizations || false;
    }
    return true;
  }
};
function view(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  return true;
};
function create(currentUser?: CurrentUser) {
  if (!currentUser) {
    return false;
  }
  return currentUser.is_owner || currentUser.global_role.can_create_organizations;
};
function createMany(currentUser?: CurrentUser) {
  if (!currentUser) {
    return false;
  }
  return currentUser.is_owner || currentUser.global_role.can_edit_all_organizations;
};
function update(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_edit_organization;
  }
  return false;
};
function deleteOrg(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  //
  if (!currentUser || !organization) {
    return false;
  }
  return currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations;
};
// function restore(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
//   if (!currentUser || !organization) {
//     return false;
//   }
//   if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
//     return true;
//   }
//   // organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
//   if (organizationRole) {
//     //   return organizationRole.can_invite_users_to_organization;
//     // }
//     return false;
//   }
//   return false;
// };
function forceDelete(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  //
  if (!currentUser || !organization) {
    return false;
  }
  return currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations;
};
function setOrganizationOwner(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  return currentUser.is_owner || organization.owner_id === currentUser.id;
};
function upload(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_upload_storage;
  }
  return false;
};
function editStorage(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_edit_storage;
  }
  return false;
};
function viewHistory(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_view_history;
  }
  return false;
};
function submitData(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_submit_data;
  }
  return false;
};
function approveData(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_approve_data;
  }
  return false;
};
function viewAnyUser(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_view_all_users) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_view_organization_users;
  }
  return false;
};
function viewAnyInvite(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_view_organization_invites;
  }
  return false;
};
function inviteUser(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_invite_users_to_organization;
  }
  return false;
};
function withdrawInvite(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id);
  if (organizationRole) {
    return organizationRole.can_invite_users_to_organization;
  }
  return false;
};
function joinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  const orgRole = currentUser.organization_roles.find((value) => value.organization_id == organization.id);
  if (orgRole) {
    return false;
  }
  const joinRequest = currentUser.joinRequests?.find((value, index) => value.organization_id == organization.id);
  if (joinRequest) {
    return false;
  }
  if (!organization.allow_join_requests) {
    return false;
  }
  return true;
};
function withdrawJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  return true;
};
function viewAnyJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  if (!currentUser || !organization) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id || currentUser.global_role?.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_accept_and_reject_join_requests;
  }
  return false;
};
function acceptJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  if (!currentUser || !organization) {
    return false;
  }
  const global_role = currentUser.global_role;
  if (currentUser.is_owner || organization.owner_id === currentUser.id || global_role.can_edit_all_organizations) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id);
  if (organizationRole) {
    return organizationRole.can_accept_and_reject_join_requests;
  }
  return false;
};
function rejectJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  return acceptJoinRequest(currentUser, organization, targetUser);
};
function viewAnyBlockedJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem) {
  return viewAnyJoinRequest(currentUser, organization);
};
function blockJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  return acceptJoinRequest(currentUser, organization, targetUser);
};
function unblockJoinRequest(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  return acceptJoinRequest(currentUser, organization, targetUser);
};

function assignRole(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  if (!currentUser || !organization || !targetUser) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id) {
    return true;
  }
  if (organization.owner_id === targetUser.id) {
    return false;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_assign_roles;
  }
  return false;
};
function removeUser(currentUser?: CurrentUser, organization?: Organization | OrganizationListItem, targetUser?: User) {
  if (!currentUser || !organization || !targetUser) {
    return false;
  }
  if (organization.owner_id === targetUser.id) {
    return false;
  }
  if (currentUser.is_owner || organization.owner_id === currentUser.id) {
    return true;
  }
  const organizationRole = currentUser.organization_roles?.find(role => role.organization_id === organization.id)
  if (organizationRole) {
    return organizationRole.can_remove_users_from_organization;
  }
  return false;
};
