

// const config = {
//   baseUrl: "https://abib.q-con.eu",
//   apiUrl: "https://abib.q-con.eu/api",
//   storageUrl: "https://abib.q-con.eu/storage",
//   fileadminUrl: "https://abib.q-con.eu/storage",
// }

/**
 * Generates a correct URL from a path - depending on different predefined patterns it could match.
 * Generiert aus einem path eine korrekte URL, je nach welchem Muster der übergebene Path folgt.
 *
 * @param {string} path in der Regel in der DB gespeichert und über API bereitgestellt
 * @param {string} defaultTo falls kein passendes Muster gefunden wird, wird defaultTo zurückgegeben
 * @returns {string|null} url or defaultTo
 */
export default function getPublicUrl(path?: string, defaultTo?:string): string | undefined {
  if (!(path && typeof path === 'string')) {
    return defaultTo;
  }

  let path_array : (string|null)[] = path.split('/');
  const first = path_array[0];

  switch (first) {
    case 'http:':
    case 'https:':
      return path;
    case 'fileadmin':
      return `${APP_FILEADMIN_URL}/${path}`;
    case 'storage':
      path_array.shift();
      return `${APP_STORAGE_URL}/${path_array.join('/')}`;
    case 'public':
      path_array.shift();
      return `${APP_STORAGE_URL}/${path_array.join('/')}`;
    case 'images':
      return `${APP_URL}/${path}`;
    case '':
      path_array.shift();
      return getPublicUrl(path_array.join('/'), defaultTo)
    default:
      return defaultTo;
  }
}
export { getPublicUrl };
