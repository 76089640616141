import React, { FC, useState } from 'react';
import { Button, FormControl, InputGroup, Form, FormGroup } from "react-bootstrap";
import { Phone } from '../../../types';
import { useAutoSaveForm } from '../../../hooks';
import Feedback from 'react-bootstrap/esm/Feedback';

export interface PhoneFormProps {
  data: Phone,
  
  onDelete?: (phone: Phone) => void,
  onSubmit?: (phone: Phone) => void,
  autosave?: boolean,
}
export const PhoneFormV2: FC<PhoneFormProps> = (props) => {
  const autosave = props.autosave ?? true;
  const { formData: phone, handleFieldChange, resetForm, saveManually } = useAutoSaveForm({
    initialValues: props.data, onSave: (data) => {
      if (!data.number) return;
      props.onSubmit?.(data);
    }, shouldAutosave: autosave
  });
  return (<div>
    <InputGroup>
      <Form.Select value={phone.type} onChange={(e) => handleFieldChange('type', parseInt(e.target.value))}>
        <option value={1}>Mobile Phone</option>
        <option value={2}>Landline</option>
        <option value={3}>Facsimile</option>
      </Form.Select>
      <FormControl aria-label='Phone Number' isInvalid={!phone.number}
        value={phone.number} onChange={(e) => handleFieldChange('number', e.target.value)}
      />
      <Button variant="danger" onClick={() => { props.onDelete?.(phone); }}>Remove</Button>
    </InputGroup>
    {!phone.number ? <div style={{color: "red"}}>Phone number is required.</div> : null}
  </div>);
}
