
import { authHeader } from '../helpers';

export const countryService = {
    getAll,
    getAllSimplified,
    getById,
    store,
    update,
    uploadFlag,
};

function getAll(page, perPage, filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = `${APP_API_URL}/countries`;
    if (!page) page=1;
    if (!perPage) perPage=10;
    url+='?page='+page+'&perPage='+perPage;
    if (filter) url+=`&filter=${JSON.stringify(filter)}`;

    return fetch(url, requestOptions).then(handleResponse);
}

function getAllSimplified() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = `${APP_API_URL}/countries/simplified`;

    return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${APP_API_URL}/deprecated/countries/${id}/`, requestOptions).then(handleResponse);
}

function store(country) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(country)
    };

    return fetch(`${APP_API_URL}/deprecated/countries`, requestOptions).then(handleResponse);;
}

function update(country) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(country)
    };

    return fetch(`${APP_API_URL}/deprecated/countries/${country.id}`, requestOptions).then(handleResponse);;
}

function uploadFlag(id, file) {
    const {'Content-Type': contentType, ...headers } = authHeader();
    const requestOptions = {
        method: 'POST',
        headers: headers,
        body: file
    };
    return fetch(`${APP_API_URL}/countries/${id}/upload/flag`, requestOptions).then(handleResponse);
}


function handleResponse(resp) {
    return resp.text().then(text => {
        const data = text && JSON.parse(text);
        if (!resp.ok) {
            if (data.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                location.reload();
            }
            const error = (data && data.message) || data.statusText;
            return Promise.reject(error.toString());
        }

        return data;
    });
}
