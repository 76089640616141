import { SectorList } from '../../../types';

export default function getSectorKeyvisual(sector_id: number, sectorList: SectorList): string | undefined {
  const sector = sectorList.itemsById[sector_id];
  if (!sector) return undefined;
  if (sector.keyvisual) return sector.keyvisual;
  if (!sector.parent_sector_id) return undefined;
  return getSectorKeyvisual(sector.parent_sector_id, sectorList);
}
export function getRandomSectorDefaultKeyvisual(sectors: number[], allSectors: SectorList) {
  const availableSectorKeyvisuals = sectors.map(sector => getSectorKeyvisual(sector, allSectors)).filter(keyvisual => !!keyvisual);
  if (availableSectorKeyvisuals.length === 0) return undefined;
  if (availableSectorKeyvisuals.length === 1) return availableSectorKeyvisuals[0];
  return availableSectorKeyvisuals[Math.floor(Math.random() * availableSectorKeyvisuals.length)];
}
