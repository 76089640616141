
import React, { useEffect, useState } from 'react';
import { Button, Card, Tooltip } from 'react-bootstrap';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { useAcceptOrganizationInviteMutation, useGetCurrentUserQuery, useGetOrganizationQuery, useLogoutMutation } from '../../services';
import { LoginPage, RegisterPage } from '../authentication';

export function OrganizationAcceptInvite({

}) {
  const { org_id, org_name } = useParams();
  const params = new URLSearchParams(useLocation().search);
  const token = params.get('token');
  const expiresAt = params.get('expiresAt');
  const { data: org, error: orgError, isLoading: orgIsLoading } = useGetOrganizationQuery({ org_id, org_name });
  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery();
  const [logout, { }] = useLogoutMutation();
  const [acceptInvite, { isLoading, error }] = useAcceptOrganizationInviteMutation();
  const [loginRegister, setLoginRegister] = useState(true);
  const [orgRole, setOrgRole] = useState({})

  const expiresAtDate = new Date(expiresAt);
  const nowDate = new Date();

  useEffect(() => {
    if (currentUser) {
      setOrgRole(currentUser.organization_roles?.find(role => role.organization_id == org_id));
    }
  }, [currentUser]);

  function handleAccept() {
    acceptInvite({ org_id, token });
  }

  return <Card body>
    <h2>Accept Invite to Organization: {org && org.name}</h2>
    {/* <p>
      Hello!<br />
      Organization: {orgIsLoading ? <>...Loading</> : orgError ? <>Ooops, error</> : org && org.name}<br />
      Current User: {currentUserIsLoading ? <>...Loading</> : currentUserError ? <>Ooops, error</> : currentUser && <>{currentUser.name} | {currentUser.email}</>}<br />
      Token: {token} <br />
      Expires: {`${expiresAt}`}<br />
      Is Expired: {JSON.stringify(expiresAtDate < nowDate)} {nowDate.toISOString()}
    </p> */}
    {expiresAtDate < nowDate}
    {((!currentUser && !currentUserIsLoading) || currentUserError) && <>
      <h3>Please Login under the invited email to accept the invitation.</h3>
      {loginRegister ? <LoginPage onToRegister={() => setLoginRegister(!loginRegister)} /> : <RegisterPage onToLogin={() => setLoginRegister(!loginRegister)} />}
    </>}
    {currentUser && !currentUserError && !currentUserIsLoading && <>
      You are logged in as {currentUser.name} | {currentUser.email} <br />
      <Button variant='danger' onClick={logout}>Logout</Button> {" "}
    </>}
    {currentUser && !currentUserError && !currentUserIsLoading && !orgRole && <>
      <Button disabled={!token} onClick={handleAccept}>Accept Invite</Button>
    </>}
    {currentUser && !currentUserError && !currentUserIsLoading && orgRole && <>
      Success. You are part of this organization.<br />
    </>}
    <br />
    <Link to="/orgs">Home</Link> | {org && <Link to={`/${org.url_name}`}>To the Inviting Organization.</Link>}
  </Card>
}
