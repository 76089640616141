
import { createSlice } from '@reduxjs/toolkit';
import { abibApi } from '../services';
import { OrganizationListFilter } from '../types';

const initialState = {
  page: 1,
  perPage: 10,
  filter: {
    organizationType: 0,
    country: 0,
    sector: 0,
    name: '',
    owner: 0,

    showIsHidden: false,
    showIsVisible: true,

    showHasLogo: true,
    showHasNoLogo: false,

    showHasKeyvisual: true,
    showHasNoKeyvisual: false,
  },
} as {
  page?: number;
  perPage?: number;
  filter?: OrganizationListFilter;
};
const slice = createSlice({
  name: 'organizationList',
  initialState: initialState,
  reducers: {
    setOrganizationList: (state, { payload }) => ({
      ...initialState,
      ...payload,
    }),
    clearOrganizationList: (state, action) => initialState,
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(
  //     abibApi.endpoints.getOrganizationList.matchPending,
  //     (state, { payload: { page, per_page, filter } }) => {
  //       return {
  //         page: page || 1,
  //         perPage: per_page || 10,
  //         filter: {
  //           ...initialState.filter,
  //           ...filter,
  //         }
  //       };
  //     }
  //   );
  //   builder.addMatcher(
  //     abibApi.endpoints.getOrganizationList.matchFullfilled,
  //     (state, { payload: { data } }) => {
  //       state.items = data;
  //     }
  //   );
  // },
})

export const { clearOrganizationList, setOrganizationList } = slice.actions

export default slice.reducer
