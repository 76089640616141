import { Form, Button, Spinner } from 'react-bootstrap';
import React, { FC, FormEvent, FormEventHandler, useEffect, useState } from 'react';
import { organizationTypeConstants } from '../../constants';
import { organizationActions } from '../../actions';
import { useGetAllCountriesQuery, useGetAllSectorsQuery, useGetCurrentUserQuery, useGetOrganizationListQuery } from '../../services';
import { organizationPolicies } from '../../authorization';
import { DatePicker } from '../../components/Input';
import { CancelButton } from '../../components/Button';
import { CurrentUserOrganizationListFilter } from '../../types';
import { useAppSelector } from '../../helpers';

const SearchOptions: FC<{
  showRelationFilter?: boolean;
  filter?: CurrentUserOrganizationListFilter;
  onChange?: (params: CurrentUserOrganizationListFilter) => void;
}> = ({
  showRelationFilter = false,
  filter = {
    organizationType: 0,
    country: 0,
    sector: 0,
    name: '',
    // owner: 0,

    createdBefore: undefined,
    createdSince: undefined,

    updatedBefore: undefined,
    updatedSince: undefined,

    showOwned: false,
    showNotOwned: true,

    showIsHidden: false,
    showIsVisible: true,

    showHasLogo: true,
    showHasNoLogo: false,

    showHasKeyvisual: true,
    showHasNoKeyvisual: false,
  },
  onChange = (filter) => { }
}) => {
    const { data: currentUser, isLoading: currentUserIsLoading, error: currentUserError } = useGetCurrentUserQuery();

    const { data: allSectors, isLoading: allSectorsIsLoading, isError: allSectorsIsError } = useGetAllSectorsQuery(0);
    const { data: allCountries, isLoading: allCountriesIsLoading, isError: allCountriesIsError } = useGetAllCountriesQuery(0);
    const [internalFilter, setInternalFilter] = useState(filter);
    function handleSetFilter(newFilter: CurrentUserOrganizationListFilter, triggerOnChange = true) {
      setInternalFilter(newFilter);
      if (triggerOnChange) {
        onChange(newFilter);
      }
    }
    const set = {
      organizationType: (organizationType: number) => handleSetFilter({ ...internalFilter, organizationType }),
      country: (country: number) => handleSetFilter({ ...internalFilter, country }),
      sector: (sector: number) => handleSetFilter({ ...internalFilter, sector }),
      name: (name: string) => handleSetFilter({ ...internalFilter, name }, false),
      // owner: (owner) => handleSetFilter({ ...internalFilter, owner }),

      createdBefore: (createdBefore: string | undefined) => handleSetFilter({ ...internalFilter, createdBefore }),
      createdSince: (createdSince: string | undefined) => handleSetFilter({ ...internalFilter, createdSince }),
      updatedBefore: (updatedBefore: string | undefined) => handleSetFilter({ ...internalFilter, updatedBefore }),
      updatedSince: (updatedSince: string | undefined) => handleSetFilter({ ...internalFilter, updatedSince }),

      showOwned: (showOwned: boolean) => handleSetFilter({
        ...internalFilter,
        showOwned,
        showNotOwned: showOwned ? filter.showNotOwned : true
      }),
      showNotOwned: (showNotOwned: boolean) => handleSetFilter({
        ...internalFilter,
        showNotOwned,
        showOwned: showNotOwned ? filter.showOwned : true
      }),
      showIsHidden: (showIsHidden: boolean) => handleSetFilter({
        ...internalFilter,
        showIsHidden,
        showIsVisible: showIsHidden ? filter.showIsVisible : true
      }),
      showIsVisible: (showIsVisible: boolean) => handleSetFilter({
        ...internalFilter,
        showIsVisible,
        showIsHidden: showIsVisible ? filter.showIsHidden : true
      }),

      showHasLogo: (showHasLogo: boolean) => handleSetFilter({
        ...internalFilter,
        showHasLogo,
        showHasNoLogo: showHasLogo ? filter.showHasNoLogo : true
      }),
      showHasNoLogo: (showHasNoLogo: boolean) => handleSetFilter({
        ...internalFilter,
        showHasNoLogo,
        showHasLogo: showHasNoLogo ? filter.showHasLogo : true
      }),

      showHasKeyvisual: (showHasKeyvisual: boolean) => handleSetFilter({
        ...internalFilter,
        showHasKeyvisual,
        showHasNoKeyvisual: showHasKeyvisual ? filter.showHasNoKeyvisual : true
      }),
      showHasNoKeyvisual: (showHasNoKeyvisual: boolean) => handleSetFilter({
        ...internalFilter,
        showHasNoKeyvisual,
        showHasKeyvisual: showHasNoKeyvisual ? filter.showHasKeyvisual : true
      }),
    };
    useEffect(() => {
      setInternalFilter(filter);
    }, [filter]);
    function handleSubmit(e: FormEvent<HTMLFormElement>) {
      e.preventDefault();
      e.stopPropagation();

      onChange(internalFilter);
    }
    return (
      <Form onSubmit={handleSubmit}>
        <h3>Filter Organizations</h3>
        <DatePicker
          id='filterUpdatedSince'
          label='Updated Since'
          labelPlacement='top'
          value={internalFilter.updatedSince}
          onChange={(e) => set.updatedSince(e.target.value)}
        />
        {showRelationFilter && (
          <Form.Group>
            <Form.Check label="showOwned" checked={internalFilter.showOwned} onChange={e => set.showOwned(e.target.checked)} />
            <Form.Check label="showNotOwned" checked={internalFilter.showNotOwned} onChange={e => set.showNotOwned(e.target.checked)} />
          </Form.Group>
        )}
        {showRelationFilter && <br />}
        {organizationPolicies.viewAny(currentUser, true) && (<>
          <Form.Group>
            <Form.Check label="showIsHidden" checked={internalFilter.showIsHidden} onChange={e => set.showIsHidden(e.target.checked)} />
            <Form.Check label="showIsVisible" checked={internalFilter.showIsVisible} onChange={e => set.showIsVisible(e.target.checked)} />
          </Form.Group>
          <br />
        </>)}
        <Form.Group>
          <Form.Check label="showHasKeyvisual" checked={internalFilter.showHasKeyvisual} onChange={e => set.showHasKeyvisual(e.target.checked)} />
          <Form.Check label="showHasNoKeyvisual" checked={internalFilter.showHasNoKeyvisual} onChange={e => set.showHasNoKeyvisual(e.target.checked)} />
        </Form.Group>
        <br />
        <Form.Group>
          <Form.Check label="showHasLogo" checked={internalFilter.showHasLogo} onChange={e => set.showHasLogo(e.target.checked)} />
          <Form.Check label="showHasNoLogo" checked={internalFilter.showHasNoLogo} onChange={e => set.showHasNoLogo(e.target.checked)} />
        </Form.Group>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="input" value={internalFilter.name} onChange={e => set.name(e.target.value)} />
        </Form.Group>
        {/* <Form.Group>
      <Form.Label>Owner / Manager</Form.Label>
      <Form.Control type="input" value={internalFilter.owner} onChange={e => set.owner(e.target.value)} disabled />
    </Form.Group> */}
        <Form.Group>
          <Form.Select value={internalFilter.organizationType} onChange={e => set.organizationType(Number(e.target.value))}>
            <option value={0}>Organization Type</option>
            <option value={organizationTypeConstants.COMPANY}>Company</option>
            <option value={organizationTypeConstants.NGO}>NGO</option>
            <option value={organizationTypeConstants.DIASPORA}>Diaspora</option>
          </Form.Select>
          {/* {filter.organizationType ? <CancelButton onClick={() => set.organizationType(0)} tooltip='Clear Organization Type' /> : null} */}
        </Form.Group>
        <Form.Group>
          <Form.Select value={internalFilter.country} onChange={e => set.country(Number(e.target.value))}>
            <option value={0}>Country</option>
            {allCountriesIsLoading && <option><Spinner animation="border" /></option>}
            {allCountries?.items && allCountries.items.map(country =>
              <option key={country.id} value={country.id}>{country.name}</option>
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group>
          <Form.Select value={internalFilter.sector} onChange={e => set.sector(Number(e.target.value))}>
            <option value={0}>Sector</option>
            {allSectorsIsLoading && <option><Spinner animation="border" /></option>}

            {allSectors?.items && allSectors.items.map(pair => (
              <optgroup key={pair.rootSector.id} label={pair.rootSector.name}
              // value={pair.rootSector.id}
              >
                {pair.subSectors.map(
                  sector => <option key={sector.id} value={sector.id}>{sector.name}</option>
                )}</optgroup>
            ))}
          </Form.Select>
        </Form.Group>
        <Button type='submit'>Apply Filter</Button>
      </Form>
    );
  }

export { SearchOptions };
