import React, { useState, useEffect, useRef, useMemo, FC } from 'react';
import { Alert, Button, Card, Form, FormGroup, Modal, Overlay, OverlayTrigger, Popover, Spinner } from 'react-bootstrap';
import { useLoginMutation } from '../../services';
import { LoginForm, RegisterForm } from '.';

export const AuthenticationButton: FC<{}> = () => {
  const [show, setShow] = useState(false);

  function handleCancel() {
    setShow(false);
  }

  const [toggleLogin, setToggleLogin] = useState(true);
  const handleToggle = () => setToggleLogin((v) => !v);

  return <>
    <Modal
      show={show}
      onHide={handleCancel}
      onEscapeKeyDown={handleCancel}
    >
      <Modal.Header closeButton><Modal.Title>{toggleLogin ? 'Login' : 'Register'}</Modal.Title></Modal.Header>
      {toggleLogin
        ? <LoginForm onToRegister={handleToggle} onSuccess={() => setShow(false)} />
        : <RegisterForm onToLogin={handleToggle} onSuccess={handleToggle} />}
    </Modal>
    <Button variant="primary" onClick={() => setShow(!show)}>Login / Register</Button>
  </>;
}
