import React, { useEffect, useState } from 'react';
import { Card, Form, Tab, Table, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { CancelButton } from '../../components/Button';
import { organizationPolicies, organizationRolePolicies } from '../../authorization';
import { ConfirmModal, defaultConfirmModalConfig } from '../../components/Custom';
import {
  useAssignOrganizationRoleMutation,
  useGetCurrentUserQuery,
  useGetOrganizationQuery,
  useLazyGetOrganizationRolesQuery,
  useLazyGetOrganizationUsersQuery,
  useRemoveOrganizationUserMutation,
  useSendOrganizationInviteMutation,
} from '../../services';
import { SendInviteForm } from './forms/SendInviteForm';
import { OrganizationInvites } from './OrganizationInvites';
import { OrganizationRoles } from './OrganizationRoles';

export function OrganizationUsers() {
  const { org_id, org_name } = useParams();
  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: org, error: orgError, isLoading: orgIsLoading } = useGetOrganizationQuery({ org_id, org_name });
  const [getOrgRoles, { data: rolesData, error: rolesError, isLoading: rolesIsLoading }] = useLazyGetOrganizationRolesQuery();
  const roles = rolesData?.items;
  const [getOrgUsers, { data: orgUsersData, error: orgUsersError, isLoading: orgUsersIsLoading }] = useLazyGetOrganizationUsersQuery();
  const orgUsers = orgUsersData?.items;
  const [sendInvite, { }] = useSendOrganizationInviteMutation();

  useEffect(() => {
    if (org?.id) {
      getOrgRoles({ org_id: org.id });
      getOrgUsers({ org_id: org.id });
    }
  }, [org?.id]);

  const [assignRole, { }] = useAssignOrganizationRoleMutation();
  const [removeUser, { }] = useRemoveOrganizationUserMutation();

  const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig);
  const [tabKey, setTabKey] = useState("users");

  function handleAssignRole(user, roleName) {
    if (!user || !currentUser) return false;
    if (user.id === currentUser.id) {
      return setConfirmModalConfig({
        show: true,
        body: <>You are about to change your own role.<br />You might not be able to reverse this.</>,
        onCancel: () => setConfirmModalConfig(defaultConfirmModalConfig),
        onConfirm: () => {
          assignRole({ org_id: org.id, user_id: user.id, roleName: roleName });
          setConfirmModalConfig(defaultConfirmModalConfig);
        },
      });
    }
    assignRole({ org_id: org.id, user_id: user.id, roleName: roleName });
  }
  function handleRemoveUser(user) {
    if (!user || !currentUser || user.id === currentUser.id) return false;
    return setConfirmModalConfig({
      show: true,
      body: <>You are about to Remove user {user.name} from this Organization.<br />Please Confirm.</>,
      onCancel: () => setConfirmModalConfig(defaultConfirmModalConfig),
      onConfirm: () => {
        removeUser({ org_id: org.id, user_id: user.id });
        setConfirmModalConfig(defaultConfirmModalConfig);
      },
    });
  }
  function handleSendInvite(inviteEmail) {
    if (org?.id, inviteEmail) {
      return sendInvite({ org_id: org.id, email: inviteEmail });
    }
  }
  return <>
    {org && <h3>{org.name} / Users</h3>}
    <ConfirmModal {...confirmModalConfig} />

    <Tabs
      activeKey={tabKey}
      onSelect={(k) => setTabKey(k)}
    >
      <Tab eventKey="users" title="Users and Roles"><Card body>
        {org?.id && <OrganizationRoles
          org_id={org.id}
          currentUser={currentUser}
          roles={roles}

          error={orgError || orgUsersError || rolesError}
          isLoading={orgIsLoading || orgUsersIsLoading || rolesIsLoading}

          checkCanCreate={() => organizationRolePolicies.create(currentUser, org)}
          checkCanEdit={(role) => organizationRolePolicies.update(currentUser, org, role, orgUsers)}
          checkCanDelete={(role) => organizationRolePolicies.deleteRole(currentUser, org, role, orgUsers)}
          checkCanSetDefault={() => organizationPolicies.assignRole(currentUser, org, currentUser)}

          checkIsDefault={(role) => org.default_role_id == role.id}

          initialConfirmModalConfig={defaultConfirmModalConfig}
          setConfirmModalConfig={setConfirmModalConfig}
          resetConfirmModalConfig={() => setConfirmModalConfig(defaultConfirmModalConfig)}
        />}
        <Table striped bordered hover size='sm'>
          <thead>
            <tr>
              <th style={{ width: "5%" }}>ID</th>
              <th style={{ width: "45%" }}>Username</th>
              <th style={{ width: "40%" }}>Role</th>
              <th style={{ width: "10" }}></th>
            </tr>
          </thead>
          <tbody>
            {orgUsersError ? (
              <tr><td colSpan={3}>Oh no, there was an error</td></tr>
            ) : (orgUsersIsLoading || !orgUsers) ? (
              <tr><td colSpan={3}>Loading...</td></tr>
            ) : orgUsers.map((user, index) => {
              return <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{currentUser && org && roles && (organizationPolicies.assignRole(currentUser, org, user)
                  ? (
                    <Form.Select size="sm" value={user.roleName} onChange={(e) => handleAssignRole(user, e.target.value)}>
                      {roles.map(role => <option key={role.name} value={role.name}>{role.name}</option>)}
                    </Form.Select>
                  ) : (
                    user.roleName
                  ))}</td>
                <td>
                  {organizationPolicies.removeUser(currentUser, org, user) && <CancelButton fontSize={20} color='red' tooltip={"Remove"} onClick={() => handleRemoveUser(user)} />}
                </td>
              </tr>
            })}
          </tbody>
        </Table>
      </Card></Tab>
      {false && organizationPolicies.viewAnyJoinRequest(currentUser, org) && <Tab eventKey="JoinRequests" title="Join Requests">
        <Card body>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Requested At</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr><td></td></tr>
            </tbody>
          </Table>
        </Card>
        <Card body>
          <h4>Blocked</h4>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Blocked At</th>
                <th>Blocked By</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Card>
      </Tab>}
      {organizationPolicies.viewAnyInvite(currentUser, org) && <Tab eventKey="invites" title="Invites">
        <OrganizationInvites
          org_id={org.id}
          orgUsers={orgUsers}

          error={orgError || orgUsersError}
          isLoading={orgIsLoading || orgUsersIsLoading}

          checkCanWithdrawInvite={() => organizationRolePolicies.create(currentUser, org)}

          initialConfirmModalConfig={defaultConfirmModalConfig}
          setConfirmModalConfig={setConfirmModalConfig}
          resetConfirmModalConfig={() => setConfirmModalConfig(defaultConfirmModalConfig)}
        />
      </Tab>}
    </Tabs>
    {organizationPolicies.inviteUser(currentUser, org) && <SendInviteForm onSubmit={handleSendInvite} />}
  </>

}
