import React, { FC, useCallback, useState } from 'react';
import { Card, Col, Form, FormControl, Row, Table } from 'react-bootstrap';
import { GlobalRoleTableRow } from './GlobalRoleTableRow';
import { CurrentUser, GlobalRole, Id } from '../../types';
import { AddButton } from '../../components/Button';

interface GlobalRolesProps {
  roles?: GlobalRole[];
  current_role_id?: Id;

  error?: any;
  isLoading?: boolean;

  canCreate?: boolean;
  checkCanEdit?: (role: GlobalRole) => boolean;
  checkCanDelete?: (role: GlobalRole) => boolean;

  onUpdate?: (role_id: Id, role: Partial<GlobalRole>) => void;
  onCreate?: (role: Omit<GlobalRole, 'id'>) => void;
  onDelete?: (role_id: Id) => void;
}
export const GlobalRoles: FC<GlobalRolesProps> = ({
  roles = [],

  error,
  isLoading = false,

  canCreate = false,
  checkCanEdit = () => false,
  checkCanDelete = () => false,

  onUpdate,
  onCreate,
  onDelete,
}) => {
  const [createRoleIndex, setCreateRoleIndex] = useState(1);
  function newCreateRole() {
    setCreateRoleIndex(i => i + 1);
  }
  const roleIsValid = useCallback((checkRole: Partial<GlobalRole>) => {
    if (!checkRole?.name) return false;
    if (!roles) return false;
    return roles.every(role => role.id != checkRole.id || checkRole.name?.toLowerCase() !== role.name.toLowerCase())
  }, [roles])
  function handleUpdateRole(role_id: Id, role: Partial<GlobalRole>) {
    if (!role_id || !role) return;
    onUpdate?.(role_id, role);
  }

  return <Table striped bordered hover size="sm">
    <thead>
      <tr>
        <th style={{ width: "20%" }}>Name</th>
        <th style={{ width: "20%" }}>General</th>
        <th style={{ width: "30%" }}>Organizations</th>
        <th style={{ width: "20%" }}>Users</th>
        <th style={{ width: "10%" }}></th>
      </tr>
    </thead>
    <tbody>
      {(roles) ? roles.map((role, index) => <GlobalRoleTableRow
        id={`global-role-${role.id}`}
        key={role.id}

        role={role}

        canEdit={checkCanEdit(role)}
        canDelete={checkCanDelete(role)}

        isValid={roleIsValid}

        onSave={(r: Partial<GlobalRole>) => handleUpdateRole(role.id, r)}
        onDelete={() => onDelete?.(role.id)}
      />) : null}
      {(!error && !isLoading && canCreate) ? (
        <tr style={{
          paddingBottom: "0.5rem",
          paddingTop: "0.5rem",
        }}>
          <td colSpan={6}>Add a new global role:</td>
        </tr>
      ) : null}
      {(!error && !isLoading && canCreate) ? (
        <GlobalRoleTableRow
          id={`crerate-global-role-${createRoleIndex}`}
          autosave={false}
          onSave={(r: Omit<GlobalRole, 'id'>) => {
            onCreate?.(r);
            newCreateRole();
          }}
          canEdit={true}
          resetCounter={createRoleIndex}
          isValid={roleIsValid}
        />
      ) : null}
    </tbody>
  </Table>
}
