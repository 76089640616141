import { sectorConstants } from '../constants';
import { sectorService } from '../services';
import { alertActions } from './';

export const sectorActions = {
    clear,
    getById,
    getAll,
    getAllSimplified,
};

function clear() {
    return {type:sectorConstants.CLEAR};
}

function getById(id) {
    return dispatch => {
        dispatch(request(id));

        sectorService.getById(id)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: sectorConstants.GETBYID_REQUEST, id } }
    function success(response) { return { type: sectorConstants.GETBYID_SUCCESS, response } }
    function failure(id, error) { return { type: sectorConstants.GETBYID_FAILURE, id, error } }
}

function getAll(page, perPage) {
    return dispatch => {
        dispatch(request());

        sectorService.getAll(page, perPage)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: sectorConstants.GETALL_REQUEST } }
    function success(response) { return { type: sectorConstants.GETALL_SUCCESS, response } }
    function failure(error) { return { type: sectorConstants.GETALL_FAILURE, error } }
}

function getAllSimplified() {
    return dispatch => {
        dispatch(request());

        sectorService.getAllSimplified()
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error))
            );
    };

    function request() { return { type: sectorConstants.GETALL_SIMPLIFIED_REQUEST } }
    function success(response) { return { type: sectorConstants.GETALL_SIMPLIFIED_SUCCESS, response } }
    function failure(error) { return { type: sectorConstants.GETALL_SIMPLIFIED_FAILURE, error } }
}
