import React, { FC, useEffect, useMemo, useState } from 'react';
import { Id, OrganizationContact, Phone, ValidationErrors } from "../../../types";
import { Button, Card, Col, Form, FormControl, InputGroup, Row, Spinner } from "react-bootstrap";
import { useGetAllCountriesQuery } from '../../../services';
import { useAutoSaveForm, useAutoSaveValue, useValidationErrors } from '../../../hooks';
import { personPrefix, phoneType } from '../../../constants';
import { PersonForm, PhoneForm, PhoneFormV2 } from '.';
import { CustomMap, CustomMapButton } from '../../../components/Map';
import { LatLng, latLng } from 'leaflet';

const deleteContact = false;
export interface ContactsFormProps {
  errors?: { [key: Id]: ValidationErrors<OrganizationContact> | undefined };
  contacts: OrganizationContact[];
  autosave?: boolean;
  isFetching?: boolean;
  onAddContact?: (contact: Partial<OrganizationContact>) => void;
  onRemoveContact?: (contact_id: Id) => void;
  onSetPrimaryContact?: (v: { contact_id: Id, is_primary: boolean }) => void;
  onUpdateContact?: (values: { contact_id: Id, data: Partial<OrganizationContact> }) => void;
}
export const ContactsForm: FC<ContactsFormProps> = (props) => {
  const contacts = props.contacts;
  const { onAddContact, onRemoveContact, onSetPrimaryContact, onUpdateContact } = props;
  function handleAddContact() {
    onAddContact?.({});
  }
  return (<>
    {contacts.map((contact, index) =>
      <ContactForm key={contact.id}
        id={`contact_${contact.id}`}
        data={contact}
        errors={props.errors?.[contact.id]}
        autosave={props.autosave}
        isFetching={props.isFetching}
        onRemoveContact={() => onRemoveContact?.(contact.id)}
        onPrimaryChange={(is_primary) => onSetPrimaryContact?.({ contact_id: contact.id, is_primary })}
        onUpdateContact={(data) => onUpdateContact?.({ contact_id: contact.id, data })}
      />
    )}
    <Card body key="newContact">
      <Button disabled={props.isFetching} onClick={() => handleAddContact()}>Add Contact</Button>
    </Card>
  </>);
}

interface ContactFormProps {
  id: string;
  autosave?: boolean;
  isFetching?: boolean;
  data: Partial<OrganizationContact>;
  errors?: ValidationErrors<OrganizationContact>;
  // is_primary?: boolean;
  onPrimaryChange: (is_primary: boolean) => void;
  onRemoveContact: () => void;
  onUpdateContact: (contact: Partial<OrganizationContact>) => void;
}
const ContactForm: FC<ContactFormProps> = (props) => {
  const { onPrimaryChange, onRemoveContact, onUpdateContact, } = props;
  const autosave = props.autosave ?? true;
  const { phones: phonesData, ...contactData } = useMemo(() => props.data ?? {}, [props.isFetching]);
  const { data: allCountries, isLoading: allCountriesIsLoading, isError: allCountriesIsError } = useGetAllCountriesQuery(0);
  // const [searchAddress, { data: searchResults, isLoading: searchIsLoading, isError: searchIsError }] = useLazySearchAddressQuery();

  const { formData: contactFormData, isDirty, resetForm, handleFieldChange, saveManually: saveContactManually } = useAutoSaveForm({ initialValues: contactData, onSave: onUpdateContact, shouldAutosave: autosave });
  const { value: phones, handleChange: handlePhonesChange, resetValue: resetPhones, saveManually: savePhonesManually } = useAutoSaveValue({ initialValue: phonesData ?? [], onSave: handleSavePhones, shouldAutosave: autosave });
  const phonesInvalid = useMemo(() => phones.some(p => !p.number), [phones]);
  // const addPerson = () => {
  //   handleFieldChange('person', emptyPerson());
  // }
  // const removePerson = () => {
  //   handleFieldChange('person', null);
  //   handleFieldChange('person_id', undefined);
  // }

  const validationErrors = useValidationErrors(props.data, contactFormData, props.errors);

  function handleSavePhones(data: Phone[]) {
    if (data.some(p => !p.number)) { return; }
    onUpdateContact({ phones: data });
  }
  const marker = useMemo(() => contactData.coordinates ? coordinatesToLatLng(contactData.coordinates) : undefined, [contactData.coordinates]);

  return (<>
    <Card body id={props.id}>
      <Row>
        <Col md="auto">
          {allCountries?.items && <CustomMapButton
            id={`${props.id}-coordinates-map`}
            marker={marker}
            address={{
              street: contactFormData.street,
              postalcode: contactFormData.postal_code,
              city: contactFormData.city,
              country: allCountries?.items?.find(c => c.id == contactFormData.country_id)?.name,
              state: contactFormData.district,
            }}
            canEdit={true}
            onSubmit={(latLng) => {
              handleFieldChange('coordinates', latLng ? { latitude: latLng.lat, longitude: latLng.lng } : null)
            }} width={"15rem"} height={"15rem"} />}
        </Col>
        <Col>
          <InputGroup>
            <InputGroup.Text>Directions</InputGroup.Text>
            <FormControl
              as="textarea"
              rows={4}
              placeholder='How to find us:'
              value={contactFormData.directions || ''} onChange={(e) => handleFieldChange('directions', e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>
      {/* {person
        ? <PersonForm data={person} type={contact.type} disabled={deleteContact} removePerson={() => removePerson()} />
        : <Button disabled={deleteContact} onClick={() => addPerson()} variant="primary">Add Person</Button>} */}
      <hr />
      <Row md={12} >
        <Col xs={2}>
          <fieldset disabled={deleteContact}>
            <InputGroup >
              <InputGroup.Text>Primary</InputGroup.Text>
              <InputGroup.Checkbox checked={contactData.is_primary} onChange={(e: any) => onPrimaryChange(e.target.checked)} />
            </InputGroup>
          </fieldset>
          <br />
          {/* {deleteContact && <p>Contact will be deleted</p>}
          {deleteContact
            ? <Button variant="danger" onClick={() => {
              set_deleteContact(false);
            }}>Undo</Button>
            : <Button variant="danger" onClick={() => {
              if (contact.id) set_deleteContact(true);
              else removeContact();
            }}>Remove Contact</Button>
          } */}
          <Button variant="danger" onClick={onRemoveContact}>Remove Contact</Button>

        </Col>
        <Col md={5}><fieldset disabled={deleteContact}>
          <InputGroup>
            <InputGroup.Text>Name</InputGroup.Text>
            <FormControl value={contactFormData.name ?? ''} onChange={(e) => handleFieldChange('name', e.target.value || undefined)} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Street</InputGroup.Text>
            <FormControl value={contactFormData.street ?? ''} onChange={(e) => handleFieldChange('street', e.target.value || undefined)} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Postal Code</InputGroup.Text>
            <FormControl value={contactFormData.postal_code ?? ''} onChange={(e) => handleFieldChange('postal_code', e.target.value || undefined)} />
            <InputGroup.Text>City</InputGroup.Text>
            <FormControl value={contactFormData.city ?? ''} onChange={(e) => handleFieldChange('city', e.target.value || undefined)} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Postbox</InputGroup.Text>
            <FormControl value={contactFormData.postbox ?? ''} onChange={(e) => handleFieldChange('postbox', e.target.value || undefined)} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>District</InputGroup.Text>
            <FormControl value={contactFormData.district ?? ''} onChange={(e) => handleFieldChange('district', e.target.value || undefined)} />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text>Country</InputGroup.Text>
            {allCountriesIsLoading && <Spinner animation="border" />}
            {allCountriesIsError && <span>error</span>}
            {allCountries?.items && <Form.Select value={contactFormData.country_id ?? ''} onChange={(e) => handleFieldChange('country_id', e.target.value || '')}>
              <option value={''}>Select a Country</option>
              {allCountries.items.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)}
            </Form.Select>}
          </InputGroup>
        </fieldset></Col>
        <Col xs={5}><fieldset disabled={deleteContact}>
          <InputGroup hasValidation>
            <InputGroup.Text>Email</InputGroup.Text>
            <FormControl

              isInvalid={!!props.errors?.email?.length}
              value={contactFormData.email ?? ''} onChange={(e) => handleFieldChange('email', e.target.value || undefined)}
            />
            {props.errors?.email?.map((error, index) => <Form.Control.Feedback key={index} type="invalid">{error}</Form.Control.Feedback>)}
          </InputGroup>
          <InputGroup hasValidation>
            <InputGroup.Text>Website</InputGroup.Text>
            <FormControl
              isInvalid={!!props.errors?.website?.length}
              value={contactFormData.website ?? ''}
              onChange={(e) => handleFieldChange('website', e.target.value || undefined)}
            />
            {props.errors?.website?.map((error, index) => <Form.Control.Feedback key={index} type="invalid">{error}</Form.Control.Feedback>)}
          </InputGroup>
          <br />
          Phones Numbers: <br />
          {phones.map((phone, index, arr) => <PhoneFormV2 key={phone.id ?? ('new-' + index)} data={phone} onDelete={removePhone} onSubmit={handlePhoneUpdate(arr, index)} />)}
          <Button disabled={props.isFetching} onClick={addPhone}>Add Phone</Button>
        </fieldset></Col>
      </Row>
    </Card>
  </>);

  function handlePhoneUpdate(arr: Phone[], index: number): ((phone: Phone) => void) | undefined {
    return (data) => handleFieldChange('phones', arr.map((p, i) => i === index ? data : p));
  }
  function addPhone() {
    handlePhonesChange([...phones, emptyPhone()], true);
  };
  function removePhone(phone: Phone) {
    handlePhonesChange(phones.filter(p => p !== phone));
  };
}


function emptyPerson() {
  return {
    prefix: personPrefix.non_specified,
  };
};
function emptyPhone(contact_id?: Id) {
  return {
    contact_id,
    type: phoneType.mobile,
    number: '',
    is_primary: false,
  } as Phone;
};

function coordinatesToLatLng(coordinates: {
  latitude: number;
  longitude: number;
}): LatLng {
  return latLng({ lat: coordinates.latitude, lng: coordinates.longitude });
}
function latLngToCoordinates(latLng: LatLng): { latitude: number; longitude: number; } {
  return { latitude: latLng.lat, longitude: latLng.lng };
}
