
import { Country, CurrentUser } from "../types";
import { globalRole, organizationRole } from "./authorization.constants";

export const countryPolicies = {
  viewAny,
  view,
  create,
  update,
  deleteCountry,
  forceDelete,
};
function viewAny(currentUser?: CurrentUser, hidden = false) {
  if (!hidden) {
    return true;
  }
  if (!currentUser) {
    return false
  }
  return currentUser.is_owner || currentUser.global_role.can_edit_all_countries;
};
function view(currentUser?: CurrentUser, country?: Country) {
  if (!country) {
    return false;
  }
  if (!country.hidden_at) {
    return true;
  }
  if (!currentUser) {
    return false
  }
  return currentUser.is_owner || currentUser.global_role.can_edit_all_countries;
};
function create(currentUser?: CurrentUser) {
  return currentUser && (currentUser.is_owner || currentUser.global_role.can_edit_all_countries);
};
function update(currentUser?: CurrentUser, country?: Country) {
  return currentUser && (currentUser.is_owner || currentUser.global_role.can_edit_all_countries);
};
function deleteCountry(currentUser?: CurrentUser, country?: Country) {
  //
  return currentUser && (currentUser.is_owner || currentUser.global_role.can_edit_all_countries);
};
function forceDelete(currentUser?: CurrentUser, country?: Country) {
  //
  return deleteCountry(currentUser, country);
};
