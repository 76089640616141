
import React, { useState, useEffect } from 'react';
import { Button, Card, Form, InputGroup, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../helpers';
import { organizationTypeConstants } from '../../constants';
import { useCreateOrganizationMutation, useGetAllCountriesQuery } from '../../services';
import { Id } from '../../types';


export function OrganizationCreate() {
  const navigate = useNavigate();

  const { data: allCountries, isLoading: allCountriesIsLoading, isError: allCountriesIsError } = useGetAllCountriesQuery(0);
  const [name, setName] = useState('');
  const [organizationType, setOrganizationType] = useState(0 as Id);
  const [country, setCountry] = useState(0 as Id);
  const dispatch = useAppDispatch();
  const [createOrg, { data: createOrgData, error: createOrgError, isLoading: createOrgIsLoading }] = useCreateOrganizationMutation();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    // if (e.currentTarget.checkValidity() === false) {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }
    if (name && organizationType && country) {
      // TODO: retrieve currentUser from API with new owned org and organization
      createOrg({
        name: name,
        organization_type_id: organizationType,
        country_id: country,
      }).unwrap().then(org => {
        if (org?.id) navigate(`/orgs/${org.id}/edit`);
        else console.log('error creating org', org);
      }).catch(error => console.log(error));
    }
  };

  return (<Card body>
    <Form
      // noValidate
      validated={!!(name && organizationType && country)}
      onSubmit={handleSubmit}
    >
      <Form.Group key="name" controlId="name">
        <Form.Label>Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Company Name"
          value={name}
          isInvalid={!name}
          // feedback="name is required"
          onChange={e => {
            setName(e.target.value);
          }}
        />
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group key="organizationType" controlId="organizationType">
        <Form.Select required
          value={organizationType}
          isInvalid={!organizationType}
          // feedback="Select an organization type"
          onChange={e => setOrganizationType(e.target.value)}
        >
          <option value={0}>Organization Type</option>
          <option value={organizationTypeConstants.COMPANY}>Company</option>
          <option value={organizationTypeConstants.NGO}>NGO</option>
          <option value={organizationTypeConstants.DIASPORA}>Diaspora</option>
          <option value={organizationTypeConstants.MINISTRY}>Ministry</option>
        </Form.Select>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Select
          value={country}
          isInvalid={!country}
          // feedback="Select a country"
          onChange={e => setCountry(e.target.value)}
        >
          <option value={0}>Country</option>
          {allCountriesIsLoading && <option><Spinner animation="border" /></option>}
          {allCountries?.items && allCountries.items.map(country =>
            <option key={country.id} value={country.id}>{country.name}</option>
          )}
        </Form.Select>
        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
      </Form.Group>
      <Button type="submit">Create Organization</Button>
    </Form>
  </Card>);
}
