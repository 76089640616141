
import React, { FunctionComponent, useMemo, useState } from 'react';
import { CaretDown, CaretRight } from 'react-bootstrap-icons';



interface CollapsableListProps {
  children?: React.ReactNode,
  disabled?: boolean,
  header?: React.ReactNode,
  onToggle?: () => void,
  toggle?: boolean,
}
export const CollapsableList: FunctionComponent<CollapsableListProps> = ({
  children = <>Content</>,
  disabled = false,
  header = <>Title</>,
  onToggle = () => { },
  toggle = undefined
}) => {
  const [toggleLocal, setToggleLocal] = useState(false);

  function getToggle() {
    return (typeof toggle === 'boolean') ? toggle : toggleLocal;
  }
  function handleToggle() {
    if (typeof toggle !== 'boolean') {
      setToggleLocal(!toggleLocal);
      return;
    }
    onToggle();
  }

  return <div className='collapsable-list'>
    <div className='collapsable-toggle'>
      <a onClick={() => !disabled && handleToggle()}>{getToggle() ? <CaretDown fontSize={20} /> : <CaretRight fontSize={20} />}</a>
      {" "}{header}
    </div>
    <div className='collapsable-content' hidden={!getToggle()}>
      {children}
    </div>
  </div>
}
