import React from 'react';
import { Card, Table } from 'react-bootstrap';
import { CalendarDate } from 'react-bootstrap-icons';
import { DeleteButton } from '../../components/Button';
import { useGetOrganizationInvitesQuery, useWithdrawOrganizationInviteMutation } from '../../services';

export function OrganizationInvites({
  org_id = 0,
  orgUsers = [],

  error = null,
  isLoading = false,

  checkCanWithdrawInvite = () => false,
}) {
  const { data: invites, error: invitesError, isLoading: invitesIsloading } = useGetOrganizationInvitesQuery({org_id});
  const [withdrawInvite, { }] = useWithdrawOrganizationInviteMutation();

  function findUser(id) {
    return orgUsers?.find(u => u.id == id);
  }

  return (
    <Card body>
      <Table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Invited At <CalendarDate fontSize={20} color="blue" /></th>
            <th>Expires At <CalendarDate fontSize={20} color="blue" /></th>
            <th>Invited By</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {(isLoading || invitesIsloading) ? <tr><td colSpan={4}>...Loading</td></tr>
            : (error || invitesError) ? <tr><td>Oops there has been an error:<br />
              {JSON.stringify(invitesError)}
            </td></tr>
              : invites && invites.map((invite, index) => {
                const invitedAt = new Date(invite.invited_at);
                const expiresAt = new Date(invite.expires_at);
                return <tr key={index}>
                  <td>{invite.email}</td>
                  <td>{invitedAt.toLocaleDateString()} {invitedAt.toLocaleTimeString()}</td>
                  <td>{expiresAt.toLocaleDateString()} {expiresAt.toLocaleTimeString()}</td>
                  <td>{findUser(invite.invited_by)?.name || invite.invited_by}</td>
                  <td>
                    {checkCanWithdrawInvite() && <DeleteButton onClick={() => withdrawInvite({ org_id, email: invite.email })} />}
                  </td>
                </tr>
              })
          }
        </tbody>
      </Table>
    </Card>
  )
}
