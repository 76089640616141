import { useMemo } from "react";
import { ValidationErrors } from "../types";

export function useValidationErrors<T>(originalData: T, currentData: T, errors?: ValidationErrors<T>) {
  return useMemo(() => {
    if (!errors) return undefined;
    const errorsResult = {} as ValidationErrors<T>;
    for (const key in errors) {
      if (originalData[key] === currentData[key]) {
        errorsResult[key] === errors[key];
      }
    }
    return errorsResult;
  }, [errors, originalData, currentData]);
}
