const globalRole = {
  can_edit_all_countries: 'can_edit_all_countries',
  can_edit_all_languages: 'can_edit_all_languages',
  can_create_organizations: 'can_create_organizations',
  can_edit_all_organizations: 'can_edit_all_organizations',
  can_validate_or_reject_all_organization_data: 'can_validate_or_reject_all_organization_data',
  can_validate_or_reject_all_data: 'can_validate_or_reject_all_data',
  can_edit_all_users: 'can_edit_all_users',
  can_view_all_users: 'can_view_all_users',
  can_assign_user_global_roles: 'can_assign_user_global_roles',
  can_edit_user_global_roles: 'can_edit_user_global_roles',
} as const;
const globalRoleKeys = Object.keys(globalRole) as (keyof typeof globalRole)[];

const organizationRole = {
  can_view_organization_users: 'can_view_organization_users',
  can_view_organization_invites: 'can_view_organization_invites',
  can_invite_users_to_organization: 'can_invite_users_to_organization',
  can_accept_and_reject_join_requests: 'can_accept_and_reject_join_requests',
  can_remove_users_from_organization: 'can_remove_users_from_organization',
  can_assign_roles: 'can_assign_roles',
  can_edit_organization: 'can_edit_organization',
  can_edit_user_relation_roles: 'can_edit_user_relation_roles',
  can_view_history: 'can_view_history',
  can_submit_data: 'can_submit_data',
  can_approve_data: 'can_approve_data',
  can_download_storage: 'can_download_storage',
  can_upload_storage: 'can_upload_storage',
  can_edit_storage: 'can_edit_storage',
} as const;

export {organizationRole, globalRole, globalRoleKeys};
