import { SearchOptions } from "../organizations/SearchOptions";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";


function HomePage() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/orgs');
  }, []);
  return null;
  // return (
  //   <Redirect from="/" to="/orgs" />
  // );
}

export { HomePage };
