import React, { FC } from 'react';
import { Save } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';

export const SaveButton: FC<CustomButtonProptypes> = ({
  // id = 'save-button',
  color = 'blue',
  fontSize = '1rem',
  tooltip= "Save",
  ...props
}) => {
  return <GenericButton
    // id={id}
    color={color}
    tooltip={tooltip}
    {...props}
  >
    <Save color={color} fontSize={fontSize} />
  </GenericButton>
}
