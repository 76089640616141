import React, { useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Table } from "react-bootstrap";

export function CountryTranslateFormTabCountry(props) {

    const [data, setData] = useState(props.data);
    const set = {};

    [data.text_capital[props.primary_language_id], set.primary_text_capital] = useState(props.data.text_capital[props.primary_language_id] || '');
    [data.text_currency[props.primary_language_id], set.primary_text_currency] = useState(props.data.text_currency[props.primary_language_id] || '');
    [data.text_name[props.primary_language_id], set.primary_text_name] = useState(props.data.text_name[props.primary_language_id] || '');
    [data.text_full_name[props.primary_language_id], set.primary_text_full_name] = useState(props.data.text_full_name[props.primary_language_id] || '');
    [data.text_languages[props.primary_language_id], set.primary_text_languages] = useState(props.data.text_languages[props.primary_language_id] || '');
    [data.text_ethnic_groups[props.primary_language_id], set.primary_text_ethnic_groups] = useState(props.data.text_ethnic_groups[props.primary_language_id] || '');
    [data.text_religions[props.primary_language_id], set.primary_text_religions] = useState(props.data.text_religions[props.primary_language_id] || '');

    [data.text_capital[props.translate_language_id], set.translate_text_capital] = useState(props.data.text_capital[props.translate_language_id] || '');
    [data.text_currency[props.translate_language_id], set.translate_text_currency] = useState(props.data.text_currency[props.translate_language_id] || '');
    [data.text_name[props.translate_language_id], set.translate_text_name] = useState(props.data.text_name[props.translate_language_id] || '');
    [data.text_full_name[props.translate_language_id], set.translate_text_full_name] = useState(props.data.text_full_name[props.translate_language_id] || '');
    [data.text_languages[props.translate_language_id], set.translate_text_languages] = useState(props.data.text_languages[props.translate_language_id] || '');
    [data.text_ethnic_groups[props.translate_language_id], set.translate_text_ethnic_groups] = useState(props.data.text_ethnic_groups[props.translate_language_id] || '');
    [data.text_religions[props.translate_language_id], set.translate_text_religions] = useState(props.data.text_religions[props.translate_language_id] || '');

    [data.flag, set.flag] = useState(props.data.flag || '');
    [data.area, set.area] = useState(props.data.area || '');
    [data.population, set.population] = useState(props.data.population || '');
    [data.head_of_state, set.head_of_state] = useState(props.data.head_of_state || '');
    [data.prime_minister, set.prime_minister] = useState(props.data.prime_minister || '');
    [data.life_expectancy, set.life_expectancy] = useState(props.data.life_expectancy || '');
    [data.literacy, set.literacy] = useState(props.data.literacy || '');
    [data.mobile_phone_penetration, set.mobile_phone_penetration] = useState(props.data.mobile_phone_penetration || '');
    [data.population_growth_rate, set.population_growth_rate] = useState(props.data.population_growth_rate || '');
    [data.population_below_poverty_line, set.population_below_poverty_line] = useState(props.data.population_below_poverty_line || '');
    [data.urban_population, set.urban_population] = useState(props.data.urban_population || '');

    return (
        <Card body>
            <Card.Text>
                <Row>
                    <Col md="2">Name :</Col>
                    <Col><InputGroup controlId="country_name">
                        <Form.Control md="5" type="text" required feedback="Please enter a name"
                            value={data.text_name[props.primary_language_id]}
                            onChange={e=>set.primary_text_name(e.target.value)}
                        />
                        <Form.Control md="5" type="text" required feedback="Please enter a name"
                            value={data.text_name[props.translate_language_id]}
                            onChange={e=>set.translate_text_name(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Full Name :</Col>
                    <Col><InputGroup controlId="country_full_name">
                        <Form.Control md="5" type="text"
                            value={data.text_full_name[props.primary_language_id]}
                            onChange={e=>set.primary_text_full_name(e.target.value)}
                        />
                        <Form.Control md="5" type="text"
                            value={data.text_full_name[props.translate_language_id]}
                            onChange={e=>set.translate_text_full_name(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Flag :</Col>
                    <Col md="5"><InputGroup controlId="country_flag">
                        <Form.Control md="5" type="text" readOnly
                            value={data.flag}
                            onChange={e=>set.flag(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Area :</Col>
                    <Col md="5"><InputGroup controlId="country_area">
                        <Form.Control md="5" type="text"
                            value={data.area}
                            onChange={e=>set.area(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Capital :</Col>
                    <Col><InputGroup controlId="country_capital">
                        <Form.Control md="5" type="text"
                            value={data.text_capital[props.primary_language_id]}
                            onChange={e=>set.translate_text_capital(e.target.value)}
                        />
                        <Form.Control md="5" type="text"
                            value={data.text_capital[props.translate_language_id]}
                            onChange={e=>set.translate_text_capital(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Languages :</Col>
                    <Col><InputGroup controlId="country_languages">
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_languages[props.primary_language_id]}
                            onChange={e=>set.primary_text_languages(e.target.value)}
                        />
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_languages[props.translate_language_id]}
                            onChange={e=>set.translate_text_languages(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Ethnic Groups :</Col>
                    <Col><InputGroup controlId="country_ethnic_groups">
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_ethnic_groups[props.primary_language_id]}
                            onChange={e=>set.primary_text_ethnic_groups(e.target.value)}
                        />
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_ethnic_groups[props.translate_language_id]}
                            onChange={e=>set.translate_text_ethnic_groups(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Religions :</Col>
                    <Col><InputGroup controlId="country_religions">
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_religions[props.primary_language_id]}
                            onChange={e=>set.primary_text_religions(e.target.value)}
                        />
                        <Form.Control md="5" type="text" as="textarea"
                            value={data.text_religions[props.translate_language_id]}
                            onChange={e=>set.translate_text_religions(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Head of State :</Col>
                    <Col md="5"><InputGroup controlId="country_head_of_state">
                        <Form.Control md="5" type="text"
                            value={data.head_of_state}
                            onChange={e=>set.head_of_state(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Prime Minister :</Col>
                    <Col md="5"><InputGroup controlId="country_prime_minister">
                        <Form.Control md="5" type="text"
                            value={data.prime_minister}
                            onChange={e=>set.prime_minister(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Currency :</Col>
                    <Col><InputGroup controlId="country_currency">
                        <Form.Control md="5" type="text"
                            value={data.text_currency[props.primary_language_id]}
                            onChange={e=>set.primary_text_currency(e.target.value)}
                        />
                        <Form.Control md="5" type="text"
                            value={data.text_currency[props.translate_language_id]}
                            onChange={e=>set.translate_text_currency(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Life Expectancy :</Col>
                    <Col md="5"><InputGroup controlId="country_life_expectancy">
                        <Form.Control md="5" type="text"
                            value={data.life_expectancy}
                            onChange={e=>set.life_expectancy(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Literacy :</Col>
                    <Col md="5"><InputGroup controlId="country_literacy">
                        <Form.Control md="5" type="text"
                            value={data.literacy}
                            onChange={e=>set.literacy(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Mobile Phone penetration :</Col>
                    <Col md="5"><InputGroup controlId="country_mobile_phone_penetration">
                        <Form.Control md="5" type="text"
                            value={data.mobile_phone_penetration}
                            onChange={e=>set.mobile_phone_penetration(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Population :</Col>
                    <Col md="5"><InputGroup controlId="country_population">
                        <Form.Control md="5" type="text"
                            value={data.population}
                            onChange={e=>set.population(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Population growth rate :</Col>
                    <Col md="5"><InputGroup controlId="country_population_growth_rate">
                        <Form.Control md="5" type="text"
                            value={data.population_growth_rate}
                            onChange={e=>set.population_growth_rate(e.target.value)}
                        />
                        </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Population below poverty line :</Col>
                    <Col md="5"><InputGroup controlId="country_population_below_poverty_line">
                        <Form.Control md="5" type="text"
                            value={data.population_below_poverty_line}
                            onChange={e=>set.population_below_poverty_line(e.target.value)}
                        />
                    </InputGroup></Col>
                </Row>
                <Row>
                    <Col md="2">Urban Population :</Col>
                    <Col md="5"><InputGroup controlId="country_urban_population">
                        <Form.Control md="5" type="text"
                            value={data.urban_population}
                            onChange={e=>set.urban_population(e.target.value)}
                        />
                    </InputGroup>
                    </Col>
                </Row>
            </Card.Text>
        </Card>
    );
}
