export const sectorConstants = {
    CLEAR: 'SECTOR_CLEAR',

    GETBYID_REQUEST: 'SECTOR_GET_REQUEST',
    GETBYID_SUCCESS: 'SECTOR_GET_SUCCESS',
    GETBYID_FAILURE: 'SECTOR_GET_FAILURE',

    UPDATE_REQUEST: 'SECTOR_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'SECTOR_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'SECTOR_UPDATE_FAILURE',

    DELETE_REQUEST: 'SECTOR_DELETE_REQUEST',
    DELETE_SUCCESS: 'SECTOR_DELETE_SUCCESS',
    DELETE_FAILURE: 'SECTOR_DELETE_FAILURE',

    GETALL_REQUEST: 'SECTORS_GETALL_REQUEST',
    GETALL_SUCCESS: 'SECTORS_GETALL_SUCCESS',
    GETALL_FAILURE: 'SECTORS_GETALL_FAILURE',

    GETALL_SIMPLIFIED_REQUEST: 'SECTORS_GETALL_SIMPLIFIED_REQUEST',
    GETALL_SIMPLIFIED_SUCCESS: 'SECTORS_GETALL_SIMPLIFIED_SUCCESS',
    GETALL_SIMPLIFIED_FAILURE: 'SECTORS_GETALL_SIMPLIFIED_FAILURE',

    GETFILTERED_REQUEST: 'SECTORS_GETFILTERED_REQUEST',
    GETFILTERED_SUCCESS: 'SECTORS_GETFILTERED_SUCCESS',
    GETFILTERED_FAILURE: 'SECTORS_GETFILTERED_FAILURE',
};
