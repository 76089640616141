import React, { useState, useEffect, FC } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { useLogoutMutation, useRegisterMutation } from '../../services';

interface RegisterPagerProps {
  onToLogin?: () => void;
}
const RegisterPage: FC<RegisterPagerProps> = ({
  onToLogin,
}) => {
  const navigate = useNavigate();
  const [logout, { }] = useLogoutMutation();
  const [register, { isLoading }] = useRegisterMutation()
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });
  const [submitted, setSubmitted] = useState(false);

  // reset login status
  // useEffect(() => {
  //   logout();
  // }, []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
  }

  function toLoginPage() {
    if (location.pathname.includes("register") && !onToLogin) {
      return navigate('/login');
    }
    if (onToLogin) {
      return onToLogin();
    }
    return false;
  }
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setSubmitted(true);
    if (user.name && user.email && user.password) {
      logout();
      register(user).unwrap().then((response) => {
        if (!response.token) {
          return;
        }
        toLoginPage();
      });
    }
  }

  return (
    <Card body>
      <div className="col-lg-8 offset-lg-2">
        <h2>Register</h2>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" value={user.name} onChange={handleChange} className={'form-control' + (submitted && !user.name ? ' is-invalid' : '')} />
            {submitted && !user.name &&
              <div className="invalid-feedback">Name is required</div>
            }
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
            {submitted && !user.email &&
              <div className="invalid-feedback">Email is required</div>
            }
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (submitted && !user.password ? ' is-invalid' : '')} />
            {submitted && !user.password &&
              <div className="invalid-feedback">Password is required</div>
            }
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input type="password" name="password_confirmation" value={user.password_confirmation} onChange={handleChange} className={'form-control' + (user.password !== user.password_confirmation ? ' is-invalid' : '')} />
            {user.password !== user.password_confirmation &&
              <div className="invalid-feedback">Passwords don't match</div>
            }
          </div>
          <div className="form-group">
            <button className="btn btn-primary">
              {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Register
            </button>
            {<a onClick={toLoginPage} className="btn btn-link">Cancel</a>}
          </div>
        </form>
      </div>
    </Card>
  );
}

export { RegisterPage };
