

import { createSlice } from '@reduxjs/toolkit';

export interface Keyvisual {
  url?: string;
  title?: string;
  subtitle?: string;
};

const initialState = {} as Keyvisual;

const keyvisualSlice = createSlice({
  name: 'keyvisual',
  initialState,
  reducers: {
    resetKeyvisual: () => initialState,
    setKeyvisual: (state, { payload }: { payload: Keyvisual }) => {
      if (
        payload.url !== state.url ||
        payload.title !== state.title ||
        payload.subtitle !== state.subtitle
      ) {
        return payload;
      }
      return state;
    },
    setKeyvisualUrl: (state, { payload }: { payload: string }) => {
      if (payload !== state.url) {
        return {
          ...state,
          url: payload,
        };
      }
      return state;
    },
    setKeyvisualTitle: (state, { payload }: { payload: string }) => {
      if (payload !== state.title) {
        return {
          ...state,
          title: payload,
        };
      }
      return state;
    }
  },
});

export const { resetKeyvisual, setKeyvisual, setKeyvisualTitle, setKeyvisualUrl } = keyvisualSlice.actions;

export default keyvisualSlice.reducer;
