import { languageConstants } from '../constants';

export function allLanguages (state = {}, action) {
    switch (action.type) {
        case languageConstants.GETALL_SIMPLIFIED_REQUEST:
            return {
                loading: true
            };
        case languageConstants.GETALL_SIMPLIFIED_SUCCESS:
            return {
                items: action.response.data
            };
        case languageConstants.GETALL_SIMPLIFIED_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}
