import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Form, Pagination } from 'react-bootstrap';
import { PaginationJSON } from '../../types';

interface CustomPaginationPropTypes {
  pagination?: PaginationJSON,
  onChange?: (page: number, perPage: number) => void,
}

const CustomPagination : FunctionComponent<CustomPaginationPropTypes> = ({
  pagination: { current_page = 1, from = 0, last_page = 1, per_page = 0, to = 0, total = 0 } = {},
  onChange = () => { }
}) => {
  function handlePerPageChange(newPerPage: number | string) {
    const newPerPageNumber = Number(newPerPage);
    // newPerPage must be a represantation of a positive number (not 0)
    if (isNaN(newPerPageNumber)) return;
    if (newPerPageNumber <= 0) return;

    // land on the page with new number of entries per page that has the first entry of the current page
    let newPage = Math.ceil((1 + (current_page - 1) * per_page) / newPerPageNumber);
    onChange(newPage, newPerPageNumber);
  }
  const items : React.ReactNode[] = useMemo(() => {
    const temp : React.ReactNode[] = [];
    if (current_page < 8) {
      for (let p = 1; p <= current_page; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)} active={p === current_page}>{p}</Pagination.Item>
        );
      }
    } else {
      for (let p = 1; p <= 3; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)}>{p}</Pagination.Item>
        );
      }
      temp.push(<Pagination.Ellipsis key={'ellipsis01'} disabled />);
      for (let p = current_page - 2; p <= current_page; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)} active={p === current_page}>{p}</Pagination.Item>
        );
      }
    }
    if (current_page + 7 > last_page) {
      for (let p = current_page + 1; p <= last_page; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)}>{p}</Pagination.Item>
        );
      }
    } else {
      for (let p = current_page + 1; p <= current_page + 2; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)}>{p}</Pagination.Item>
        );
      }
      temp.push(<Pagination.Ellipsis key={'ellipsis02'} disabled />);
      for (let p = last_page - 2; p <= last_page; p++) {
        temp.push(
          <Pagination.Item key={p} onClick={() => onChange(p, per_page)}>{p}</Pagination.Item>
        );
      }

    }
    return temp;
  }, [current_page, last_page, per_page]);
  return <>
    <Pagination size="sm">{items}</Pagination>
    <Form.Group>
      <Form.Label>Per Page:</Form.Label>
      <Form.Select value={per_page} onChange={(e) => handlePerPageChange(e.target.value)}>
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
      </Form.Select>
    </Form.Group>
  </>
}

export { CustomPagination };
