
import React, { useState } from 'react';
import { Alert, Col, Container, Row, Tab, Tabs, Image, InputGroup, FormControl, Card, Nav, Button, Spinner, Badge } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../../helpers';

export function CountryProfileRegions(props) {

  const country = useAppSelector(state => state.country);
  const language = useAppSelector(state => state.language);
  const dispatch = useAppDispatch();
  const [region, setRegion] = useState(null);

  if (props.data) return (<Row>
    <Col md={3}><Card body >
      Regions in {country.data?.country.text_name[language.id]}: <br />
      <br />
      {props.data?.map(reg => <React.Fragment key={reg.id}>
        {reg.id !== region?.id
          ? <Link disabled={false} onClick={() => setRegion(reg)} >{reg.text_name[language.id]}</Link>
          : <u>{reg.text_name[language.id]}</u>}<br />
      </React.Fragment>)}
    </Card></Col>
    <Col md={9}>
      {region && <Card body>
        <Card.Title>Region : {region.text_name[language.id]}</Card.Title>
        Area : {region.area} <br />
        Population : {region.population} <br />
        <br />
        {region.text_description[language.id]} <br />
      </Card>}
    </Col>
  </Row>);
  else return <></>;
}
