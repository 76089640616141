import { countryConstants } from '../constants';

export function country(state = {}, action) {
  switch (action.type) {
    case countryConstants.CLEAR:
      return {};
    case countryConstants.GETBYID_REQUEST:
      return {
        loading: true,
        id: action.id
      };
    case countryConstants.GETBYID_SUCCESS:
      return {
        id: action.id,
        data: action.response.data
      };
    case countryConstants.GETBYID_FAILURE:
      return {
        id: action.id,
        error: action.error
      };

    case countryConstants.STORE_REQUEST:
      return {
        request: action.data,
        loading: true
      };
    case countryConstants.STORE_SUCCESS:
      return {
        data: action.response.data
      };
    case countryConstants.STORE_FAILURE:
      return {
        request: action.data,
        error: action.error
      };

    case countryConstants.UPDATE_REQUEST:
      return {
        id: action.id,
        request: action.data,
        loading: true
      };
    case countryConstants.UPDATE_SUCCESS:
      return {
        id: action.id,
        data: action.response.data
      };
    case countryConstants.UPDATE_FAILURE:
      return {
        id: action.id,
        error: action.error
      };

    case countryConstants.DELETE_REQUEST:
      return {
        id: action.id,
        loading: true
      };
    case countryConstants.DELETE_SUCCESS:
      return {
        deleted: true,
        id: action.id
      };
    case countryConstants.DELETE_FAILURE:
      return {
        error: action.error,
        id: action.id
      };
    default:
      return state;
  }
}
