import React, { FC, useEffect } from "react";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder.js";
import { useMap } from "react-leaflet";
import { LatLng } from "leaflet";

interface LeafletControlGeocorderProps {
  initialQuery?: string;
  onResult?: (result: {latlng: LatLng, zoom: number}) => void;
}
export const LeafletControlGeocoder: FC<LeafletControlGeocorderProps> = ({
  initialQuery,
  onResult,
}) => {
  const map = useMap();

  useEffect(() => {
    const geocoder = (L.Control as any).Geocoder.nominatim();

    const control = (L.Control as any).geocoder({
      query: initialQuery || '',
      placeholder: "Search here...",
      defaultMarkGeocode: false,
      geocoder
    }).on("markgeocode", function (e: any) {
      const latlng = e.geocode.center as LatLng;
      const zoom = map.getBoundsZoom(e.geocode.bbox);
      onResult?.({latlng, zoom});
      // L.marker(latlng, { icon })
      //   .addTo(map)
      //   .bindPopup(e.geocode.name)
      //   .openPopup();
      // map.fitBounds(e.geocode.bbox);
    });
    control.addTo(map);
    return () => {
      map.removeControl(control);
    }
  }, []);

  return null;
}
