import { Organization, OrganizationRelations, OrganizationUpdateRoute, OrganizationWithoutRelations } from '../types'

export interface OrganizationUpdateData {
  organization?: OrganizationUpdateRoute;
  relations?: OrganizationRelations;
}

const ignoredKeys = ['logo', 'keyvisual', 'url_name', 'owner_id', 'hidden_at', 'default_role_id'] as const;
const relationKeys = ['associations', 'about', 'awards', 'contacts', 'languages', 'indicators', 'media', 'news', 'organization_type', 'product_details', 'ratingData', 'rating_data', 'roles', 'sectors',] as const;
export const prepareOrganizationUpdateData = (data: Organization, originalData?: Organization) => {
  const dataTransformed = transformUpdateData(data);
  if (!originalData) {
    return dataTransformed;
  }
  const originalDataTransformed = transformUpdateData(originalData);
  return getDataToUpdate(dataTransformed, originalDataTransformed);
}
function getDataToUpdate(data: OrganizationUpdateData, originalData: OrganizationUpdateData) {
  const { organization, relations } = data;
  const { organization: originalOrganization, relations: originalRelations } = originalData;
  return {
    organization: !isEqualOrContainedIn(organization, originalOrganization) ? organization : undefined,
    relations: getRelationsToUpdate(relations, originalRelations)
  };
}
function getRelationsToUpdate(relations: OrganizationRelations | undefined, originalRelations: OrganizationRelations | undefined) {
  if (!relations) { return undefined; }
  const relationsAreEqual = isEqualOrContainedIn(relations, originalRelations);
  if (relationsAreEqual) { return undefined; }
  const keys = relationKeys.filter((key, index) => {
    return relations[key] && !isEqualOrContainedIn(relations[key], originalRelations?.[key])
  });
  return keys.reduce((acc, key) => {
    acc[key] = relations[key];
    return acc;
  }, {} as OrganizationRelations);
}
function isEqualOrContainedIn<T>(a?: T, b?: T): boolean {
  if (a === undefined) { return true; }
  if (typeof a !== typeof b) { return false; }
  if (typeof a === 'number') {
    if (typeof b !== 'number') { return false; }
    return a === b || (Number.isNaN(a) && Number.isNaN(b));
  }

  if (a == b) { return true; }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) { return false; }
    if (a.length !== b.length) { return false; }
    if (a.length === 0) { return true; }
    return a.every((itemA) => b.some((itemB) => isEqualOrContainedIn(itemA, itemB)));
  }
  if (typeof a === 'object') {
    if (typeof b !== 'object') { return false; }
    if (a === null) { return true; }
    if (b === null) { return false; }
    const keysA = Object.keys(a);
    return keysA.every(key => isEqualOrContainedIn((a as any)[key], (b as any)[key]));
  }
  return false;
}

function transformUpdateData(data: Organization) {
  const {
    // throw away because seperate routes or not relevant for Update route
    logo, keyvisual, owner_id, hidden_at, default_role_id, url_name, updated_at, created_at, id,
    // seperate relations
    about, indicators, associations, awards, contacts, languages, media, news, organization_type, product_details, rating_data, ratingData, roles, sectors,
    // organization data
    ...dataWithoutRelations
  } = data;
  const organization = Object.keys(dataWithoutRelations).length > 0 ? dataWithoutRelations : undefined;
  const relations = {
    about, indicators, associations, awards, contacts, languages, media, news, organization_type, product_details, rating_data, roles, sectors
  };
  return { organization, relations } as OrganizationUpdateData;
}

export function transformOrganizationData(data: Organization) {
  const {
    // seperate relations
    about, indicators, associations, awards, contacts, languages, media, news, organization_type, product_details, rating_data, ratingData, roles, sectors,
    // organization data
    ...dataWithoutRelations
  } = data;
  const relations = {
    about, indicators, associations, awards, contacts, languages, media, news, organization_type, product_details, rating_data, roles, sectors
  };
  return { id: dataWithoutRelations?.id, organization: dataWithoutRelations, relations };
}
