export const organizationConstants = {
    CLEAR: 'ORGANIZATION_CLEAR',
    CLEAR_ALL: 'ORGANIZATION_CLEAR_ALL',

    GETBYID_REQUEST: 'ORGANIZATION_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'ORGANIZATION_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ORGANIZATION_GETBYID_FAILURE',

    STORE_REQUEST: 'ORGANIZATION_STORE_REQUEST',
    STORE_SUCCESS: 'ORGANIZATION_STORE_SUCCESS',
    STORE_FAILURE: 'ORGANIZATION_STORE_FAILURE',

    UPDATE_REQUEST: 'ORGANIZATION_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'ORGANIZATION_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORGANIZATION_UPDATE_FAILURE',

    DELETE_REQUEST: 'ORGANIZATION_DELETE_REQUEST',
    DELETE_SUCCESS: 'ORGANIZATION_DELETE_SUCCESS',
    DELETE_FAILURE: 'ORGANIZATION_DELETE_FAILURE',

    GETALL_REQUEST: 'ORGANIZATIONS_GETALL_REQUEST',
    GETALL_SUCCESS: 'ORGANIZATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORGANIZATIONS_GETALL_FAILURE',

    SET_FILTER: 'ORGANIZATIONS_SET_FILTER',
};
