import React, { FC, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import { ErrorPageProptypes } from '.';
import { Helmet } from 'react-helmet';

export const NotImplemented: FC<ErrorPageProptypes> = ({
  message = "This page or resource is under construction. Please come back later.",
}) => {
  const location = useLocation();
  const { from } = location.state || {};

  return <Card body>
    <Helmet>
      <title>{`503 Not Implemented | ${APP_NAME}`}</title>
      <meta name="description" content={message} />
    </Helmet>
    <h1>Error: Not Implemented yet.</h1>
    <p>{message}</p>
    <noindex />
    <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
  </Card>
}
