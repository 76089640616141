
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Card, Form, Pagination, Table } from 'react-bootstrap';
import { globalRolePolicies, userPolicies } from '../../authorization';
import { AddButton, DeleteButton, EditButton } from '../../components/Button';
import { ConfirmModal, CustomPagination, defaultConfirmModalConfig } from '../../components/Custom';
import { useAssignGlobalRoleMutation, useCreateGlobalRoleMutation, useDeleteGlobalRoleMutation, useDeleteUserMutation, useGetAllGlobalRolesQuery, useGetAllUsersQuery, useGetCurrentUserQuery, useUpdateGlobalRoleMutation } from '../../services';
import { CurrentUser, User } from '../../types';
import { GlobalRoles } from './GlobalRoles';

interface UserListV2Props {
  currentUser: CurrentUser;
}
export const UserListV2: FC<UserListV2Props> = (props) => {
  const [userPagination, setUserPagination] = useState({ page: 1, perPage: 10 });
  const allUsers = useGetAllUsersQuery(userPagination);
  const [assignGlobalRole, assignGlobalRoleResult] = useAssignGlobalRoleMutation();
  const [deleteUser, deleteUserResponse] = useDeleteUserMutation();

  const allGlobalRoles = useGetAllGlobalRolesQuery();
  const [createGlobalRole, createGlobalRoleResponse] = useCreateGlobalRoleMutation();
  const [deleteGlobalRole, deleteGlobalRoleResponse] = useDeleteGlobalRoleMutation();
  const [updateGlobalRole, updateGlobalRoleResponse] = useUpdateGlobalRoleMutation();

  const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig)
  function handleDeleteUser(user: User) {
    setConfirmModalConfig({
      ...defaultConfirmModalConfig,
      show: true,
      onConfirm: () => {
        deleteUser({ user_id: user.id });
        setConfirmModalConfig(defaultConfirmModalConfig);
      },
      onCancel: () => {
        setConfirmModalConfig(defaultConfirmModalConfig);
      }
    })
  }
  function handleAssignRole(user: User, global_role_id: string): void {
    assignGlobalRole({ user_id: user.id, global_role_id: global_role_id });
  }

  const [alert, setAlert] = useState<{ type: "success" | "danger", message: string } | undefined>(undefined);
  function successAlert(message: string, timeout = 3000) {
    setAlert({ type: "success", message });
    setTimeout(() => setAlert(undefined), timeout);
  }
  function errorAlert(message: string, timeout = 3000) {
    setAlert({ type: "danger", message });
    setTimeout(() => setAlert(undefined), timeout);
  }

  useEffect(() => {
    if (assignGlobalRoleResult.error) {
      console.log("UserListV2 -> assignGlobalRoleResult.error", assignGlobalRoleResult.error)
      errorAlert("Failed to assign global role to user");
    }
  }, [assignGlobalRoleResult.error])
  useEffect(() => {
    if (deleteUserResponse.error) {
      console.log("UserListV2 -> deleteUserResponse.error", deleteUserResponse.error)
      errorAlert("Failed to delete user");
    }
  }, [deleteUserResponse.error])
  useEffect(() => {
    if (createGlobalRoleResponse.error) {
      console.log("UserListV2 -> createGlobalRoleResponse.error", createGlobalRoleResponse.error)
      errorAlert("Failed to create global role");
    }
  }, [createGlobalRoleResponse.error])
  useEffect(() => {
    if (deleteGlobalRoleResponse.error) {
      console.log("UserListV2 -> deleteGlobalRoleResponse.error", deleteGlobalRoleResponse.error)
      errorAlert("Failed to delete global role");
    }
  }, [deleteGlobalRoleResponse.error])
  useEffect(() => {
    if (updateGlobalRoleResponse.error) {
      console.log("UserListV2 -> updateGlobalRoleResponse.error", updateGlobalRoleResponse.error)
      errorAlert("Failed to update global role");
    }
  }, [updateGlobalRoleResponse.error])

  return (
    <Card body>
      {alert ? <div className={`alert alert-${alert.type}`}>{alert.message}</div> : null}
      <ConfirmModal {...confirmModalConfig} />
      <h2>Global User Roles (Autosave On)</h2>
      {allGlobalRoles.data?.items ? <GlobalRoles
        roles={allGlobalRoles.data.items}
        current_role_id={props.currentUser.global_role_id}

        onCreate={createGlobalRole}
        onUpdate={(global_role_id, globalRole) => updateGlobalRole({ global_role_id, globalRole })}
        onDelete={(global_role_id) => {
          setConfirmModalConfig({
            onCancel: () => setConfirmModalConfig(defaultConfirmModalConfig),
            onConfirm: () => {
              setConfirmModalConfig(defaultConfirmModalConfig);
              deleteGlobalRole({ global_role_id });
            },
            show: true,
            title: "Delete Global Role",
            body: <div>
              Are you sure you want to delete this global role? <br />
              <b>{allGlobalRoles.data?.items.find(role => role.id == global_role_id)?.name}</b> <br />
              <i>This might fail, if some users still have this role assigned.</i>
            </div>
          })
        }}

        canCreate={globalRolePolicies.create(props.currentUser)}
        checkCanEdit={(role) => globalRolePolicies.update(props.currentUser, role)}
        checkCanDelete={(role) => globalRolePolicies.delete(props.currentUser, role)}

        error={allGlobalRoles.error || createGlobalRoleResponse.error || deleteGlobalRoleResponse.error || updateGlobalRoleResponse.error}
      /> : null}
      <h2>User List:</h2>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Global Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allGlobalRoles.data && allUsers.data ? (
            allUsers.data?.items.map((user, index) => (
              <tr key={index}>
                <td>{user.id}</td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>
                  {(
                    user.id != props.currentUser.id
                    && allGlobalRoles.data
                    && userPolicies.assignGlobalRole(props.currentUser, user, { id: 0, name: "", })
                  ) ? (
                    <Form.Select size="sm" value={user.global_role_id} onChange={(e) => handleAssignRole(user, e.target.value)}>
                      {allGlobalRoles.data.items.map(role => <option
                        key={role.id}
                        value={role.id}
                        disabled={!userPolicies.assignGlobalRole(props.currentUser, user, role)}
                      >{role.name}</option>)}
                    </Form.Select>
                  ) : (
                    user.global_role?.name || allGlobalRoles.data?.items.find((role) => user.global_role_id == role.id)?.name
                  )}
                </td>
                <td>
                  {user.id != props.currentUser.id ? (
                    <DeleteButton id={`delete-user-${user.id}`} onClick={() => handleDeleteUser(user)} />
                  ) : null}
                </td>
              </tr>
            ))
          ) : <tr><td colSpan={5}>Loading...</td></tr>}
        </tbody>
        {/* <tr id="add-user-row">
          <form>
            <td>New User</td>
            <td><input /></td> name
            <td><input /></td> email
            <td></td> global Role
            <td>
              <AddButton id={'add-user'} tooltip="Add User" type='submit' />
            </td>
          </form>
        </tr> */}
      </Table >
      <CustomPagination
        pagination={allUsers.data?.pagination || {
          current_page: userPagination.page,
          per_page: userPagination.perPage
        }}
        onChange={(page, perPage) => {
          setUserPagination({ page, perPage });
        }}
      />
    </Card >
  );
}
