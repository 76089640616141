import React, { useState, useEffect, FC, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

import { isBaseAPIError, useLogoutMutation, useRegisterMutation } from '../../services';
import { isString } from 'lodash';

interface RegisterFormProps {
  onToLogin?: () => void;
  onSuccess?: () => void;
}
const RegisterForm: FC<RegisterFormProps> = ({
  onToLogin,
  onSuccess,
}) => {
  const navigate = useNavigate();
  const [logout, { }] = useLogoutMutation();
  const [register, { isLoading, isError, error, reset }] = useRegisterMutation()
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
  });
  const [submitted, setSubmitted] = useState(false);

  const apiError = useMemo(() => {
    if (isError && isBaseAPIError(error)) {
      if (error.status === 500) {
        console.error('Server error', error);
      }
      return error;
    }
    if (isError) {
      console.error('Unknown error', error);
    }
    return undefined;
  }, [isError, error]);
  const errorMessage = useMemo(() => {
    if (!isError) return undefined;
    if (!apiError) return "An unkown Error occured";
    if (apiError.status === 422) { return undefined; }
    if (apiError.status === 500) {
      let message = apiError?.data.message || '';

      if (message && apiError.data.data) {
        message += ' :\n' + (isString(apiError.data.data) ? apiError.data.data : JSON.stringify(apiError.data.data));
      }

      // message += '\n\nPlease contact the administrator.';
      return message;
    }
    return apiError?.data.message || "An unkown Error occured";
  }, [isError, apiError]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
    reset();
  }
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (user.password !== user.password_confirmation) { return; }
    setSubmitted(true);
    if (user.name && user.email && user.password) {
      register(user).unwrap().then((response) => {
        if (!response.token) {
          return;
        }
        onSuccess?.();
      });
    }
  }

  return (
    <Card body>
      <div className="col-lg-8 offset-lg-2">
        {errorMessage && <div className="alert alert-danger"><span style={{ whiteSpace: "pre-wrap" }}>{errorMessage}</span></div>}
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" value={user.name} onChange={handleChange} className={'form-control' + (apiError?.data.errors?.name ? ' is-invalid' : '')} />
            {apiError?.data.errors?.name &&
              <div className="invalid-feedback">{apiError?.data.errors?.name}</div>
            }
          </div>
          <div className="form-group">
            <label>Email</label>
            <input type="email" name="email" value={user.email} onChange={handleChange} className={'form-control' + (apiError?.data.errors?.email ? ' is-invalid' : '')} />
            {apiError?.data.errors?.email &&
              <div className="invalid-feedback">{apiError?.data.errors?.email}</div>
            }
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" name="password" value={user.password} onChange={handleChange} className={'form-control' + (apiError?.data.errors?.password ? ' is-invalid' : '')} />
            {apiError?.data.errors?.password &&
              <div className="invalid-feedback">{apiError?.data.errors?.password}</div>
            }
          </div>
          <div className="form-group">
            <label>Confirm Password</label>
            <input type="password" name="password_confirmation" value={user.password_confirmation} onChange={handleChange} className={'form-control' + (user.password !== user.password_confirmation ? ' is-invalid' : '')} />
            {user.password !== user.password_confirmation &&
              <div className="invalid-feedback">Password and Confirmation must be identical.</div>
            }
          </div>
          <br />
          <div className="form-group">
            <button id='register-button' disabled={user.password !== user.password_confirmation} className="btn btn-primary">
              {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Register
            </button>
            {onToLogin && <button id='to-login' onClick={onToLogin} className="btn btn-link">To Login</button>}
          </div>
        </form>
      </div>
    </Card>
  );
}

export { RegisterForm };
