import React, { FC, useState, useEffect, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authHeader, useAppDispatch, useAppSelector } from '../../helpers';

import { isBaseAPIError, useGetCurrentUserQuery, useLoginMutation, useLogoutMutation } from '../../services';
import { setCredentials } from '../../slices/authentication.slice';

interface LoginFormProps {
  onToRegister?: () => void;
  onSuccess?: () => void;
}
export const LoginForm: FC<LoginFormProps> = ({
  onToRegister,
  onSuccess,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [login, { isLoading, isError, error, reset }] = useLoginMutation();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      login({ email, password }).unwrap().then((response) => {
        clearInputs();
        if (!response.token) {
          return;
        }
        if (onSuccess) {
          onSuccess();
        }
      });
    } else {
      setSubmitted(true);
    }
  }
  function clearInputs() {
    setEmail('');
    setPassword('');
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    reset();
    switch (name) {
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
    }
  }

  const apiError = useMemo(() => {
    if (isError && isBaseAPIError(error)) {
      return error;
    }
    if (isError) {
      console.error('Unknown error', error);
    }
    return undefined;
  }, [isError, error]);

  return (<Card body>
    <div className="col-lg-8 offset-lg-2" >
      {isError && <div className="alert alert-danger">{
        (apiError?.status === 401
          ? 'Invalid email or password'
          : apiError?.data?.message || 'An error occurred')
      }</div>}
      <form name="form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Email</label>
          <input type="text" name="email" disabled={isLoading}
            value={email} onChange={handleChange}
            className={'form-control' + (submitted && !email ? ' is-invalid' : '')}
          />
          {submitted && !email &&
            <div className="invalid-feedback">email is required</div>
          }
        </div>
        <div className="form-group">
          <label>Password</label>
          <input type="password" name="password" disabled={isLoading}
            value={password} onChange={handleChange}
            className={'form-control' + (submitted && !password ? ' is-invalid' : '')}
          />
          {submitted && !password &&
            <div className="invalid-feedback">Password is required</div>
          }
        </div>
        <br />
        <div className="form-group">
          <button id='login-button' disabled={isLoading} className="btn btn-primary">
            {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
            Login
          </button>
          {onToRegister && <button id='to-register' onClick={onToRegister} className="btn btn-link">Not registered yet?</button>}
        </div>
      </form>
    </div>
  </Card>);
}
