import React, { useState, useEffect, useMemo, FC } from 'react';
import { Card, Row, Col, Table, Spinner, Image, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SearchOptions } from '.';
import { Sectors } from './profile';

import getPublicUrl from '../../helpers/getPublicUrl';
import { CustomPagination, ConfirmModal, defaultConfirmModalConfig } from '../../components/Custom';
import { DeleteButton, EditButton, ToggleHiddenButton, UserListButton } from '../../components/Button';
import {
  useDeleteOrganizationMutation,
  useGetAllCountriesQuery,
  useGetAllOrganizationTypesQuery,
  useGetAllSectorsQuery,
} from '../../services';
import { findId, getOrganizationUrl, getOrganizationUrlPath } from '../../helpers';
import { CurrentUser, CurrentUserOrganizationListFilter, Id, ListQuery, OrganizationListFilter, OrganizationListItem, OrganizationType, PaginationJSON } from '../../types';

export const OrganizationListData: FC<{
  title?: React.ReactNode;
  data?: OrganizationListItem[];
  currentUser?: CurrentUser;
  pagination?: PaginationJSON;
  showRelationFilter?: boolean;
  isLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  error?: any;

  onChange?: (params: ListQuery<OrganizationListFilter>) => void;
  filter?: CurrentUserOrganizationListFilter;
  canDeleteOrganization?: (organization: OrganizationListItem) => boolean | undefined;
  canEditOrganization?: (organization: OrganizationListItem) => boolean | undefined;
  canViewOrganizationUsers?: (organization: OrganizationListItem) => boolean | undefined;

  deleteOrganization?: (org_id: Id) => void,
  hideOrganization?: (org_id: Id) => void,
  unhideOrganization?: (org_id: Id) => void,
}> = ({
  title = <>List Organizations:</>,
  data: orgs = [],
  currentUser,
  pagination = { total: 0, per_page: 0, current_page: 0, last_page: 0 },
  showRelationFilter = false,
  isLoading: orgsIsLoading = false,
  isError: orgsIsError = false,
  isSuccess: orgsIsSuccess = true,
  onChange = ({ page, perPage, filter }) => { },
  filter = {
    showIsHidden: false,
    showIsVisible: true,

    showOwned: true,
    showNotOwned: true,

    showHasLogo: true,
    showHasNoLogo: true,

    showHasKeyvisual: true,
    showHasNoKeyvisual: true,
  },
  canDeleteOrganization = () => false,
  canEditOrganization = () => false,
  canViewOrganizationUsers = () => false,

  deleteOrganization = () => { },
  hideOrganization = () => { },
  unhideOrganization: unhideOrganiation = () => { },
  ...props
}) => {
    const navigate = useNavigate();

    const {
      data: allCountries,
      isError: allCountriesIsError, isLoading: allCountriesIsLoading, isSuccess: allCountriesIsSuccess,
      error: allCountriesError
    } = useGetAllCountriesQuery(0);
    const {
      data: allSectors,
      isError: allSectorsIsError, isLoading: allSectorsIsLoading, isSuccess: allSectorsIsSuccess,
      error: allSectorsError
    } = useGetAllSectorsQuery(0);
    const {
      data: allOrganizationTypes,
      isError: allOrgTypesIsError, isLoading: allOrgTypesIsLoading, isSuccess: allOrgTypesIsSuccess,
      error: allOrgTypesError
    } = useGetAllOrganizationTypesQuery(0);

    const isLoading = useMemo(
      () => orgsIsLoading || allCountriesIsLoading || allSectorsIsLoading,
      [orgsIsLoading, allCountriesIsLoading, allSectorsIsLoading]
    );
    const isError = useMemo(
      () => orgsIsError || allCountriesIsError || allSectorsIsError,
      [orgsIsError, allCountriesIsError, allSectorsIsError]
    );
    const isSuccess = useMemo(
      () => orgsIsSuccess && allCountriesIsSuccess && allSectorsIsSuccess,
      [orgsIsSuccess, allCountriesIsSuccess, allSectorsIsSuccess]
    );

    const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig);
    function handleDeleteOrg(org_id: Id) {
      setConfirmModalConfig({
        show: true,
        title: 'Delete Organization?',
        body: <>Deleting an organization is not Reversible. Please Confirm.</>,
        onCancel: () => { setConfirmModalConfig(defaultConfirmModalConfig) },
        onConfirm: () => { deleteOrganization(org_id) },
      });
    }

    return (<Card body>
      <ConfirmModal {...confirmModalConfig} />
      <Row>
        <Col md={3}>
          <SearchOptions showRelationFilter={showRelationFilter} filter={filter} onChange={(f) => onChange({ page: 1, perPage: pagination.per_page, filter: f })} />
        </Col>
        <Col>
          <h3>{title}</h3>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th key="name">Name</th>
                <th key="country_id">Country</th>
                <th key="sectors">Sectors</th>
                <th key="organizationType">Type</th>
                {currentUser && <th key="userRole">User Role</th>}
                {currentUser && <th key="owner">Owned</th>}
              </tr>
            </thead>
            <tbody>
              {isLoading && <tr><td colSpan={4}><Spinner animation="border" /></td></tr>}
              {isSuccess && orgs?.map((organization, index) => {
                const canUpdateOrg = canEditOrganization(organization);
                const canDeleteOrg = canDeleteOrganization(organization);
                const canViewUsers = canViewOrganizationUsers(organization);
                const orgType = findId(allOrganizationTypes?.items, organization.organizationType);
                return <tr key={index} style={{
                  opacity: organization.hidden_at ? 0.6 : 1
                }}>
                  <td key="name">
                    {canUpdateOrg &&
                      <EditButton id={`edit-${organization.id}`} onClick={() => navigate({ pathname: `/orgs/${organization.id}/edit` })} />
                    }
                    {canUpdateOrg && canViewUsers && " "}
                    {canViewUsers &&
                      <UserListButton id={`organization-userlist-${organization.id}`} onClick={() => navigate({ pathname: `/orgs/${organization.id}/users` })} />
                    }
                    {(canUpdateOrg || canViewUsers) && " "}
                    {canDeleteOrg && <>
                      <DeleteButton id={`delete-${organization.id}`} onClick={() => handleDeleteOrg(organization.id)} />{" "}
                      <ToggleHiddenButton
                        id={`toggle-hidden-${organization.id}`}
                        toggle={!!organization.hidden_at}
                        onToggle={() => organization.hidden_at ? unhideOrganiation(organization.id) : hideOrganization(organization.id)}
                      />
                    </>}
                    {(canUpdateOrg || canViewUsers || canDeleteOrg) && " | "}
                    <Link to={getOrganizationUrlPath(organization.url_name, organization.id)}>
                      {organization.logo && <img
                        src={getPublicUrl(organization.logo)}
                        style={{ maxHeight: 50, maxWidth: 100 }}
                      />}
                      {organization.name}
                    </Link>
                  </td>
                  <td key="country">{organization.country && allCountries?.items.find(country => country.id == organization.country)?.name}</td>
                  <td key="sectors"><Sectors sectors={organization.sectors} /></td>
                  <td key="organizationType">{orgType?.name}</td>
                  {currentUser && <td key="userRole">{organization.userRole?.name}</td>}
                  {currentUser && <td key="owned"><Form.Check disabled checked={organization.owner_id == currentUser.id} /></td>}
                </tr>
              })}
            </tbody>
          </Table>
          <CustomPagination
            pagination={pagination || {}}
            onChange={(page, perPage) => onChange({ page, perPage, filter })}
          />
        </Col>
      </Row>
    </Card>);
  }
