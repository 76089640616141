import { sectorConstants } from "../constants";

export function allSectors (state = {}, action) {
    switch (action.type) {
        case sectorConstants.GETALL_SIMPLIFIED_REQUEST:
            return {
                loading: true
            };
        case sectorConstants.GETALL_SIMPLIFIED_SUCCESS:
            // key "" (empty string) refers to parent sector (no parent for root sectors)
            const items = action.response.data[""].map(rootSector=>{
                const pair = {rootSector: rootSector}
                if (rootSector.id in action.response.data) {
                    pair.subSectors=action.response.data[rootSector.id];
                } else {
                    pair.subSectors=[];
                }
                return pair;
            });

            const itemsById = {};
            items.forEach(pair => {
                itemsById[pair.rootSector.id] = pair.rootSector;
                pair.subSectors.forEach(sector => {
                    itemsById[sector.id]=sector;
                });
            });

            return {
                items: items,
                itemsById: itemsById
            };
        case sectorConstants.GETALL_SIMPLIFIED_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}
