import React, { FC, useEffect, useMemo } from 'react';
import { Button, ButtonGroup, Card, Col, Dropdown, DropdownButton, Image, Nav, Navbar, Row } from 'react-bootstrap';
import { Person, PersonCircle, PersonFill } from 'react-bootstrap-icons';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { organizationPolicies, userPolicies } from '../authorization';
import { organizationTypeConstants } from '../constants';
import { findId, useAppDispatch, useAppSelector } from '../helpers';
import getPublicUrl from '../helpers/getPublicUrl';
import { useGetAllLanguagesQuery, useGetCurrentUserQuery, useGetOrganizationQuery, useLogoutMutation } from '../services';
import { Keyvisual, resetKeyvisual, setKeyvisual } from '../slices/keyvisual.slice';
import { defaultLanguage, setLanguage } from '../slices/language.slice';
import { setOrganizationList } from '../slices/organizationList.slice';
import { AuthenticationButton } from './authentication';

import { Helmet } from 'react-helmet';

const keyvisual_default = "fileadmin/content/keyvisuals/default/home_keyvisual.jpg";

export const Header: FC<{ hideUI?: boolean }> = ({ hideUI = false }) => {

  // var userLang = navigator.language || navigator.userLanguage;
  // alert ("The language is: " + userLang);
  const { org_id, org_name } = useParams();
  const { data: org, error: orgError, isError: orgIsError, isLoading: orgIsLoading, isSuccess: orgIsSuccess } = useGetOrganizationQuery({ org_id, org_name });

  const { data: allLanguages, error: allLanguagesError, isError: allLanguagesIsError, isLoading: allLanguagesIsLoading, isSuccess: allLanguagesIsSuccess } = useGetAllLanguagesQuery(0);
  const language = useAppSelector(state => state.language);
  const keyvisualState = useAppSelector(state => state.keyvisual);
  const dispatch = useAppDispatch();

  useEffect(() => {
    changeLanguage(language.id);
  }, [allLanguages?.items]);

  if (hideUI) {
    return <KeyvisualImg {...keyvisualState} />;
  }

  return (<>
    <Row>
      <Col>
        <Nav className="justify-content-start">
          <Nav.Item>
            {<select
              disabled className="selectpicker" data-width="fit"
              value={language.id}
              onChange={(e) => changeLanguage(e.target.value)}
            >
              <option value="de">German / Deutsch</option>
              <option value="en">English</option>
              <option value="fr">French / Français</option>
              {/* <option data-content='<span class="flag-icon flag-icon-mx"></span> Español'>Español</option> */}
            </select>}
          </Nav.Item>
          {/* <CurrentUserNavItem /> */}
        </Nav>
        <Nav className="justify-content-end">
          <CurrentUserNavItem />
        </Nav>
      </Col>
    </Row>
    <Row><Col>
      <KeyvisualImg {...keyvisualState} />
    </Col></Row>
    <Row><Col>
      <Card>
        <Nav fill>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/createOrg" onClick={() => dispatch(resetKeyvisual())}>Create Organization</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link as={NavLink} to="/importOrgs" onClick={() => dispatch(resetKeyvisual())}>Import Organization</Nav.Link>
          </Nav.Item> */}
          <Nav.Item>
            <Nav.Link as={NavLink} to="/orgs" onClick={() => dispatch(setKeyvisual({ title: "Organization List" }))}>Organization List</Nav.Link>
          </Nav.Item>
          <Nav.Item><Nav.Link as={NavLink} to="/orgs" onClick={() => {
            dispatch(setKeyvisual({ title: "Organization List" }));
            dispatch(setOrganizationList({ filter: { organizationType: organizationTypeConstants.COMPANY } }));
          }}>Companies</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link as={NavLink} to="/orgs" onClick={() => {
            dispatch(setKeyvisual({ title: "Organization List" }))
            dispatch(setOrganizationList({ filter: { organizationType: organizationTypeConstants.NGO } }));
          }}>NGOs</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link as={NavLink} to="/orgs" onClick={() => {
            dispatch(setKeyvisual({ title: "Organization List" }))
            dispatch(setOrganizationList({ filter: { organizationType: organizationTypeConstants.DIASPORA } }));
          }}>Diasporas</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link as={NavLink} to={`/countries`} onClick={() => {
            dispatch(setKeyvisual({ title: "Country List" }));
          }}
          >Countries</Nav.Link></Nav.Item>
          <Nav.Item><Nav.Link as={NavLink} to={`/countries_africa`} onClick={() => {
            dispatch(setKeyvisual({ title: "Country List" }));
          }}>Countries(Africa)</Nav.Link></Nav.Item>
          {/* <CurrentUserNavItem /> */}
        </Nav>
      </Card>
    </Col></Row>
  </>);

  function changeLanguage(id: string | number | undefined) {
    if (allLanguages?.items) {
      const temp = findId(allLanguages.items, id);
      dispatch(setLanguage(temp || defaultLanguage));
    }
  }
}

export const KeyvisualImg: FC<Keyvisual> = ({
  title = '',
  url = ''
}) => {
  return <div style={{ position: 'relative', width: '100%' }}>
    <Helmet>
      <title>{`${title} | ${APP_NAME}`}</title>
    </Helmet>
    <img src={getPublicUrl(url) || getPublicUrl(keyvisual_default)} style={{
      maxHeight: 500,
      width: '100%',
    }} />
    {title && <h1 className='bottom-left text-shadow-black' style={{
      position: 'absolute',
      bottom: '8px',
      left: '16px',

      color: 'white',

      textShadow: '0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black'
      // textShadow: '-2px 0 5px black, 0 2px 5px black, 2px 0 5px black, 0 -2px 5px black',
    }}>{title}</h1>}
  </div>;
}
export function CurrentUserNavItem({ ...props }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery();
  const auth = useAppSelector(state => state.authentication);
  const [logout, { }] = useLogoutMutation();

  const canCreateManyOrgs = useMemo(() => { return organizationPolicies.createMany(currentUser) }, [currentUser]);
  const canViewAnyUsers = useMemo(() => { return userPolicies.viewAny(currentUser) }, [currentUser]);

  return (<Nav.Item>
    {auth.loggedIn
      ? (<DropdownButton
        as={ButtonGroup}
        id={`current-user-dropdown`}
        variant={'primary'}
        title={<><PersonFill fontSize={20} /> {currentUser?.name || auth?.user?.name || ''}</>}
      >
        {/* <Dropdown.Item onClick={() => {
          navigate(`/currentUser`);
          dispatch(resetKeyvisual());
        }} >Profile</Dropdown.Item> */}
        <Dropdown.Item onClick={() => {
          navigate(`/currentUser/orgs`);
          dispatch(resetKeyvisual());
        }} >My Organizations</Dropdown.Item>
        <Dropdown.Divider />
        {canCreateManyOrgs || canViewAnyUsers ? (
          <>
            <Dropdown.Header>Admin Section</Dropdown.Header>
            {canCreateManyOrgs && <Dropdown.Item onClick={() => {
              dispatch(resetKeyvisual());
              navigate(`/importOrgs`);
            }} >Import Organizations</Dropdown.Item>}
            {canViewAnyUsers ? <Dropdown.Item onClick={() => {
              navigate(`/users`);
              dispatch(resetKeyvisual());
            }} >All Users</Dropdown.Item> : null}
            <Dropdown.Divider />
          </>
        ) : null}
        <Dropdown.Item onClick={() => logout()} color="red" >Logout</Dropdown.Item>
      </DropdownButton>)
      : <AuthenticationButton />
    }
  </Nav.Item>);
}

