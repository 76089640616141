import React, { FC, useState, useEffect } from 'react';
import { Button, Card } from 'react-bootstrap';
import { PersonCircle } from 'react-bootstrap-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authHeader, useAppDispatch, useAppSelector } from '../../helpers';

import { useGetCurrentUserQuery, useLoginMutation, useLogoutMutation } from '../../services';
import { setCredentials } from '../../slices/authentication.slice';

interface LoginPageProps {
  onToRegister?: () => void;
}
const LoginPage: FC<LoginPageProps> = ({
  onToRegister,
}) => {
  const [inputs, setInputs] = useState({
    email: '',
    password: ''
  });
  const token = useAppSelector(state => state.authentication.token);
  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading, refetch: refetchCurrentUser } = useGetCurrentUserQuery();
  const [submitted, setSubmitted] = useState(false);
  const [login, { isLoading }] = useLoginMutation();
  const [logout, { }] = useLogoutMutation();
  const { email, password } = inputs;
  const location = useLocation();
  const { from } = location.state || {};

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    setSubmitted(true);
    if (email && password) {
      login({ email, password })
    }
  }

  return (
    <Card body>
      {token && currentUser && <>
        <h2>Welcome {currentUser.name}:</h2>
        <PersonCircle />
        {' ' + currentUser.name + ' '} <Button onClick={() => logout()}>Logout</Button><br />
        <Link to="/orgs">Home</Link> {from && <> | <Link to={from} >Back</Link></>}
      </>}
      {!token && <div className="col-lg-8 offset-lg-2">
        <h2>Login</h2>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>email</label>
            <input type="text" name="email" value={email} onChange={handleChange} className={'form-control' + (submitted && !email ? ' is-invalid' : '')} />
            {submitted && !email &&
              <div className="invalid-feedback">email is required</div>
            }
          </div>
          <div className="form-group">
            <label>Password</label>
            <input type="password" name="password" value={password} onChange={handleChange} className={'form-control' + (submitted && !password ? ' is-invalid' : '')} />
            {submitted && !password &&
              <div className="invalid-feedback">Password is required</div>
            }
          </div>
          <div className="form-group">
            <button disabled={isLoading} className="btn btn-primary">
              {isLoading && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Login
            </button>
            {onToRegister && <button onClick={onToRegister} className="btn btn-link">Not registered yet?</button>}
            {location.pathname.includes('login') && !onToRegister && <Link to="/register" className="btn btn-link">Register</Link>}
          </div>
        </form>
      </div>}
    </Card>
  );
}

export { LoginPage };
