
import { authHeader } from '../helpers';

export const sectorService = {
    getAll,
    getAllSimplified,
    // getFiltered,
    getById,
};

function getAll(page, perPage) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = `${APP_API_URL}/sectors`;
    if (!page) page=1;
    if (!perPage) perPage=10;
    url+='?page='+page+'&perPage='+perPage;

    return fetch(url, requestOptions).then(handleResponse);
}

function getAllSimplified() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    let url = `${APP_API_URL}/sectors/simplified`;

    return fetch(url, requestOptions).then(handleResponse);
}

function getFiltered(filter, page, perPage) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(filter)
    };
    let url = `${APP_API_URL}/sectors`;
    if (!page) page=1;
    if (!perPage) perPage=10;
    url+='?page='+page+'&perPage='+perPage;


    return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${APP_API_URL}/sectors/${id}/`, requestOptions).then(handleResponse);
}

function handleResponse(resp) {
    return resp.text().then(text => {
        const data = text && JSON.parse(text);
        if (!resp.ok) {

            const error = (data && data.message) || data.statusText;
            return Promise.reject(error.toString());
        }
        return data;
    });
}
