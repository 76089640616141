import React, { FC, useMemo } from 'react';
import { Eye, EyeFill, EyeSlash, EyeSlashFill, Toggle2Off, Toggle2On } from 'react-bootstrap-icons';
import { CustomButtonProptypes, GenericButton } from '.';
import { Color } from 'react-bootstrap/esm/types';
import { Property } from 'csstype';
import { Form } from 'react-bootstrap';

export interface ToggleAutosaveButtonProps extends CustomButtonProptypes {
  colorOn?: Property.Color,
  colorOff?: Property.Color,
  toggle?: boolean,
  canToggle?: () => boolean,
  onToggle?: (toggle: boolean) => void,
}
export const ToggleAutosaveButton: FC<ToggleAutosaveButtonProps> = ({
  id = "toggle-autosave",
  fontSize = '1rem',
  disabled = false,
  tooltip,
  toggle = true,
  onToggle = () => { },
  canToggle = () => true,
  className = 'btn btn-text inline-text',
  ...props
}) => {
  const tooltipInternal = useMemo(
    () => !disabled && canToggle() && (tooltip || (toggle ? 'disable Autosave' : 'enable Autosave')),
    [tooltip, toggle, disabled, canToggle()]
  );
  return <GenericButton
    id={id}
    className={className}
    disabled={disabled}
    tooltip={tooltipInternal}
    onClick={handleToggle}
    {...props}
  >
    <span style={{ fontSize }}>
      <Form.Check
        type="switch"
        id={`${id}-switch`}
        checked={toggle}
        inline
        onChange={() => {}}
      // label={`Autosave ${toggle ? 'On' : 'Off'}`}
      />Autosave {toggle ? 'On' : 'Off'}
    </span>
  </GenericButton >

  function handleToggle() {
    if (!disabled && canToggle()) onToggle(!toggle);
  }
}
