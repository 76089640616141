import React, { useEffect, useState } from 'react';
import { Card, Nav, Button, Form, Spinner, Image, Alert, Accordion } from "react-bootstrap";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

import { useImportOrganizationsMutation } from '../../services';
import { ConfirmModal, defaultConfirmModalConfig } from '../../components/Custom';
import { organizationTypeConstants } from '../../constants';
import { InfoCircleFill } from 'react-bootstrap-icons';
import Feedback from 'react-bootstrap/esm/Feedback';

export function OrganizationImportCreateMany({
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { } = useParams();

  const [importOrgs, { data, error, isError, isLoading, isSuccess }] = useImportOrganizationsMutation();
  const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig);
  const resetConfirmModal = () => setConfirmModalConfig(defaultConfirmModalConfig);

  const [file, setFile] = useState(null);
  const [orgType, setOrgType] = useState(0);

  const uploadImportFile = () => {
    let formdata = new FormData();
    formdata.append('file', file);
    importOrgs({ file: formdata, orgType })
      .then(
        resp => {
          // dispatch(alertActions.success('Upload successful'));
        },
        error => {
          // dispatch(alertActions.error(error.toString()));
          console.log('importOrgsError', error);
        }
      );
  }
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setConfirmModalConfig({
      show: true,
      title: 'Please Confirm Upload',
      body: <>
        <strong>A new Organization will be created, for every row in the uploaded File</strong> (except the Header Row).<br />
        Even if there are duplicates within the file or organizations have already been imported.
      </>,
      onConfirm: () => {
        uploadImportFile();
        resetConfirmModal();
      },
      onCancel: () => {
        resetConfirmModal();
      },
    });
  }


  return (<>
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <h3><InfoCircleFill color='orange' /> Important Information</h3>
        </Accordion.Header>
        <Accordion.Body>
          <p style={{ whiteSpace: 'pre-wrap' }}>
            You may upload spreadsheet-files with a heading-row <br />
            <strong>Has only been tested with .xlsx file, but other allowed extensions should be similar</strong><br />
            Currently there are only 2 required values: <code><strong>name</strong></code> and <code><strong>country_code</strong></code>.<br />
            If at least one required value out of all rows is missing or the heading row is misspelled, no Organization will be created and you may upload the file again, after fixing the missing field.<br />
            The Heading row should contain the keys and nothing else - incl. spaces or might not be recognized.<br />
            <br />
            All non-required fields, can be filled, empty or not present at all. <br />
            All fields that are not listed here will be ignored  (more might be added in the future).<br />
            <br />
            <code><strong>Organization Type</strong></code> is optional. The selected type will be assigned to all created organizations.<br />
            If no organization Type is selected, Company will be assigned to all by default.<br />
            <br />
            The authenticated user will automatically be a member and the owner of all created organizations and you may find them under "My Organizations" in the user dropdown on the top right.<br />
            <br />
          </p>
          <h4>All currently available fields:</h4>
          <p>
            example: <code><strong>field</strong></code> - description<br />
            <br />
            <code><strong>id</strong></code> or ID - field is ignored for now
            <br />
            <code><strong>name</strong></code> - required<br />
            <code><strong>country_code</strong></code> - required in the format of ISO-3166: either Alpha-2-Code (2 letter-code) or numeric code<br />
            <code>country</code> - field ignored: use country_code to select country <br />
            <br />
            <code><strong>description, vision, mission, guiding_principles, services, legal_form, street, postbox, postal_code, city, email, telephone, telephone2, cellphone, facsimile, website</strong></code><br />
            - optional (nullable) fields<br />
            <code>sector</code> - currently ignored. May be set manually.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <ConfirmModal {...confirmModalConfig} />
    <Card body>
      {isLoading && <><Spinner animation="border" /> Please wait. Depending on the size of the file, this may take a while.<br /></>}
      {!isLoading && <>
        {isSuccess && <Alert variant='success'>Success. {data && `${data.length} Organizations created.`}</Alert>}
        {isError && <Alert variant='danger'>An Error Occured: <code>{error?.data?.message}</code></Alert>}
        <Link to={`/currentUser/orgs`} >To My Organizations</Link><br />
        <Form noValidate onSubmit={handleSubmit}>
          <Form.Group key="organizationType" controlId="organizationType">
            <Form.Label>Organization Type (optional)</Form.Label>
            <Form.Select
              value={orgType}
              onChange={e => setOrgType(e.target.value)}
            >
              <option value={0}>Organization Type</option>
              <option value={organizationTypeConstants.COMPANY}>Company</option>
              <option value={organizationTypeConstants.NGO}>NGO</option>
              <option value={organizationTypeConstants.DIASPORA}>Diaspora</option>
              <option value={organizationTypeConstants.MINISTRY}>Ministry</option>
            </Form.Select>
          </Form.Group>
          <Form.Group id="upload-logo">
            {/* <Image width="200" src={`${APP_FILEADMIN_URL}/fileadmin/templates/assets/images/placeholder_photo.png`} /> */}
            <Form.Label>Chose Upload File:</Form.Label>
            <Form.Control
              id='upload-logo-input'
              type='file'
              onChange={handleFileChange}
              isInvalid={!checkFileExtension(file)}
            />
            <Feedback type='info'><code style={{ color: checkFileExtension(file) ? 'black' : 'red' }}>Allowed extensions: .csv, .ods, .slk, .tsv, .xls, .xlsx, .xml</code></Feedback>
          </Form.Group>
          <br />
          <p style={{ whiteSpace: 'pre-wrap' }}>
          </p>
          <Button disabled={isLoading || !checkFileExtension(file)} type="submit">Upload File and Import Organizations</Button>
        </Form>
      </>}
    </Card>
  </>);
}

function checkFileExtension(file) {
  const ext = getFileExtension(file);
  return ext
    && ['csv', 'ods', 'slk', 'tsv', 'xls', 'xlsx', 'xml']
      .find(value => value === ext)
    && true;
}

function getFileExtension(filename) {
  if (!filename) {
    return null;
  }
  if (typeof filename === 'string' || filename instanceof String) {
    return filename.match(new RegExp('[^.]*$'))[0];
  }
  if (filename.name) {
    return filename.name.match(new RegExp('[^.]*$'))[0];
  }
  return null;
}

