import React, { FC, useMemo, useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form } from "react-bootstrap";
import { OrganizationIndicators } from '../../../types';
import { useAutoSaveForm } from '../../../hooks';

interface IndicatorsFormV2Props {
  data: OrganizationIndicators;
  autosave?: boolean;
  onSubmit?: (data: OrganizationIndicators) => void;
}

const numericFields = ['company_size', 'investment_volume', 'time_interval_between_training', 'num_employees_production', 'num_administrative_staff', 'num_freelancers', 'number_branches', 'num_total_employees'];

export const IndicatorsFormV2: FC<IndicatorsFormV2Props> = ({ data: indicators, autosave = true, onSubmit }) => {
  const { formData, isDirty, handleFieldChange, resetForm, saveManually } = useAutoSaveForm({ initialValues: indicators, onSave: onSubmit, shouldAutosave: autosave });

  const handleFormChange = <K extends keyof OrganizationIndicators>(name: K, value?: string) => {
    const num = Number(value);
    const shouldBeNumberic = numericFields.includes(name);
    if (value === '' || value === undefined) {
      handleFieldChange(name, undefined);
      return true;
    }
    if (shouldBeNumberic && isNaN(num)) {
      return false;
    }
    if (shouldBeNumberic) {
      handleFieldChange(name, num as OrganizationIndicators[K]);
      return true;
    }
    handleFieldChange(name, value as OrganizationIndicators[K]);
    return true;
  };

  return (<>
    <InputGroup key="indicators.company_size">
      <InputGroup.Text>Company Size</InputGroup.Text>
      <FormControl value={formData.company_size ?? ''}
        onChange={(e) => handleFormChange('company_size', e.target.value)}
      />
    </InputGroup>
    <InputGroup key="indicators.investment_volume">
      <InputGroup.Text>Investment Volume</InputGroup.Text>
      <FormControl value={formData.investment_volume ?? ''} onChange={(e) => handleFormChange('investment_volume', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.annual_sales">
      <InputGroup.Text>Annual Sales</InputGroup.Text>
      <FormControl value={formData.annual_sales ?? ''} onChange={(e) => handleFormChange('annual_sales', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.annual_revenue">
      <InputGroup.Text>Annual Revenue</InputGroup.Text>
      <FormControl value={formData.annual_revenue ?? ''} onChange={(e) => handleFormChange('annual_revenue', e.target.value)} />
    </InputGroup>
    <br />
    <InputGroup key="indicators.number_branches">
      <InputGroup.Text>Number of Branches</InputGroup.Text>
      <FormControl value={formData.number_branches ?? ''} onChange={(e) => handleFormChange('number_branches', e.target.value)} />
    </InputGroup>
    <br />
    <InputGroup key="indicators.num_employees_production">
      <InputGroup.Text>Number of Employees in Production</InputGroup.Text>
      <FormControl value={formData.num_employees_production ?? ''} onChange={(e) => handleFormChange('num_employees_production', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.num_administrative_staff">
      <InputGroup.Text>Number of Administrative Staff</InputGroup.Text>
      <FormControl value={formData.num_administrative_staff ?? ''} onChange={(e) => handleFormChange('num_administrative_staff', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.num_freelancers">
      <InputGroup.Text>Number of Freelancers</InputGroup.Text>
      <FormControl value={formData.num_freelancers ?? ''} onChange={(e) => handleFormChange('num_freelancers', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.num_total_employees">
      <InputGroup.Text>Total Number of Employees</InputGroup.Text>
      <FormControl value={formData.num_total_employees ?? ''} onChange={(e) => handleFormChange('num_total_employees', e.target.value)} />
    </InputGroup>
    <br />
    <InputGroup key="indicators.time_interval_between_training">
      <InputGroup.Text>Time Interval between Training</InputGroup.Text>
      <FormControl value={formData.time_interval_between_training ?? ''} onChange={(e) => handleFormChange('time_interval_between_training', e.target.value)} />
    </InputGroup>
    <InputGroup key="indicators.last_training">
      <InputGroup.Text>Last Training</InputGroup.Text>
      <FormControl type="date" value={formData.last_training} onChange={(e) => handleFieldChange('last_training', e.target.value)} />
    </InputGroup>
  </>);
}
