export const personPrefix = {
  non_specified: 10,
  mr: 11,
  mrs: 12,
} as const

export const contactType = {
  non_specified: 10,
  manager: 11,
  person: 12,
} as const

export const phoneType = {
  mobile: 1,
  landline: 2,
  facsimile: 3,
} as const
