import React, { FC } from 'react'
import { Alert } from 'react-bootstrap';

interface SaveStatusAlertProps {
  isSaving?: boolean;
  isDirty?: boolean;
}
export const SaveStatusAlert: FC<SaveStatusAlertProps> = (props) => {
  if (props.isSaving === true) {
    return <Alert variant='warning'>Saving...</Alert>
  }
  if (props.isDirty === true) {
    return <Alert variant='warning'>You have unsaved changes</Alert>
  }
  return null;
}
