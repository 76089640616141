
import React from 'react';
import { Card, FormControl, InputGroup } from 'react-bootstrap';
import { useAutoSaveForm, useAutoSaveValue } from '../../../hooks';

interface ProductsandServicesFormProps {
  productsAndServices: { products?: string, services?: string };

  onSubmit?: (productsAndServices: { products?: string, services?: string }) => void;
  autosave?: boolean;
}
const productsId = 'organization-products.products';
const servicesId = 'organization-products.services';

export const ProductsandServicesForm: React.FC<ProductsandServicesFormProps> = ({
  productsAndServices,
  onSubmit,
  autosave = true,
}) => {
  const { formData, handleFieldChange, saveManually } = useAutoSaveForm({ initialValues: productsAndServices, onSave: onSubmit, shouldAutosave: autosave });

  return (<>
    <Card body key={productsId}>
      <Card.Title><label htmlFor={productsId}>Products</label></Card.Title>
      <InputGroup>
        <FormControl id={productsId} as="textarea" rows={5} value={formData.products} onChange={(e) => handleFieldChange('products', e.target.value)} />
      </InputGroup>
    </Card>
    <Card body key={servicesId}>
      <Card.Title><label htmlFor={servicesId}>Services</label></Card.Title>
      <InputGroup>
        <FormControl id={servicesId} as="textarea" rows={5} value={formData.services} onChange={(e) => handleFieldChange('services', e.target.value)} />
      </InputGroup>
    </Card>
  </>);
};
