import React, { FC, useEffect, useState } from 'react';
import { Col, Container, Row, Tab, Tabs, InputGroup, FormControl, Card, Nav, Button, Form, Spinner, Image, Alert } from "react-bootstrap";
import { useGetAllCountriesQuery } from '../../../services';
import { CurrentUser, Id, Language, Organization, OrganizationAbout, OrganizationContact, OrganizationWithoutRelations } from '../../../types';
import { useAutoSaveForm } from '../../../hooks';
import { SaveStatusAlert } from '.';
// import {} from 'pages/organizations/forms';

const keyvisuals = [
  'fileadmin/content/keyvisuals/default/businessregulation_default.jpg',
  'fileadmin/content/keyvisuals/default/economic_default.jpg',
  'fileadmin/content/keyvisuals/default/companies_keyvisual.jpg',
  'fileadmin/content/keyvisuals/default/countries_keyvisual.jpg',
];

interface OrganizationUpdateFormProps {
  data: OrganizationWithoutRelations;
  autosave?: boolean;
  isSaving?: boolean;
  onSubmit?: (data: OrganizationWithoutRelations) => void;
}
export const OrganizationUpdateForm: FC<OrganizationUpdateFormProps> = (props) => {
  const autosave = props.autosave ?? true;
  const allCountries = useGetAllCountriesQuery(0);
  const { formData: orgForm, ...orgRest } = useAutoSaveForm({ initialValues: props.data, onSave: props.onSubmit, shouldAutosave: autosave });

  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    orgRest.saveManually();
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement & HTMLSelectElement>) {
    const { name, value } = e.target;
    orgRest.handleFieldChange(name as keyof OrganizationWithoutRelations, value);
  }
  function handleBlur(e: React.FocusEvent<HTMLInputElement & HTMLSelectElement>) {
    orgRest.saveManually();
  }

  return (<Form onSubmit={handleFormSubmit}>
    <Card body>
      <SaveStatusAlert isSaving={props.isSaving} isDirty={orgRest.isDirty} />
      <Card.Title>General Information</Card.Title>
      <InputGroup>
        <Form.Label>Name</Form.Label>
        {/* <Form.Label>Name</Form.Label> */}
        <FormControl value={orgForm.name} name='name' onChange={handleChange} onBlur={handleBlur} />
      </InputGroup>
      <InputGroup>
        <Form.Label>Registration Number</Form.Label>
        <FormControl value={orgForm.registration_number} name='registration_number' onChange={handleChange} onBlur={handleBlur} />
      </InputGroup>
      <InputGroup>
        <Form.Label>Country</Form.Label>
        {allCountries.isLoading && <Spinner animation="border" />}
        {allCountries.isError && <span>error</span>}
        {allCountries.data?.items && <Form.Select value={orgForm.country_id} name='country_id' onChange={handleChange} onBlur={handleBlur}>
          <option value={0} disabled>Select a Country</option>
          {allCountries.data?.items.map((country) => <option key={country.id} value={country.id}>{country.name}</option>)}
        </Form.Select>}
      </InputGroup>
      <InputGroup>
        <Form.Label>Collected By</Form.Label>
        <FormControl value={orgForm.collected_by} name='collected_by' onChange={handleChange} onBlur={handleBlur} />
      </InputGroup>
      <br />
      <Card.Title>Description</Card.Title>
      <InputGroup>
        <FormControl
          as="textarea" rows={5}
          name='description'
          value={orgForm.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </InputGroup>
    </Card>
  </Form>);
}

