import React, { FC, useState } from 'react';
import { InputGroup, Accordion, Button, Card, Spinner, Form } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../helpers';
import { sectorActions } from '../../../actions';
import { useGetAllSectorsQuery } from '../../../services';
import { useAutoSaveForm, useAutoSaveValue } from '../../../hooks';
import { CancelButton } from '../../../components/Button';

interface SectorsFormV2Props {
  sector_ids?: number[];
  onSubmit?: (sectors: number[]) => void;
  autosave?: boolean;
}
export const SectorsFormV2: FC<SectorsFormV2Props> = ({
  sector_ids = [],
  onSubmit,
  autosave = true,
}) => {
  const dispatch = useAppDispatch();
  const { data: allSectors, error: allSectorsError, isError: allSectorsIsError, isLoading: allSectorsIsLoading, isSuccess: allSectorsIsSuccess } = useGetAllSectorsQuery(0);
  const { formData, isDirty, handleFieldChange, saveManually, resetForm } = useAutoSaveForm<{ sector_ids: number[] }>({ initialValues: { sector_ids }, onSave: ({ sector_ids }) => onSubmit?.(sector_ids), shouldAutosave: autosave });

  return (allSectors?.items && allSectors?.itemsById ? <Card body>
    {formData.sector_ids.length > 0 ? <h4>Selected Sectors:</h4> : <h4>No Sectors Selected</h4>}
    {/* <Sectors sectors={sectors} /> */}
    {<ul>
      {formData.sector_ids.map((sector_id, index) => <li key={sector_id}>
        {allSectors.itemsById[sector_id].name}{" "}
        <CancelButton id={`remove-sector-${sector_id}`} tooltip={"Remove Sector"} color='red'
          onClick={() => { handleFieldChange('sector_ids', formData.sector_ids.filter(s => sector_id != s)); }}
        />
      </li>)}
    </ul>}
    <br />
    {/** @ts-ignore */}
    <hr noshade={true} size="20" />
    <Accordion>
      {allSectors.items.map(({ rootSector, subSectors }) => (
        <Accordion.Item key={rootSector.id} eventKey={`${rootSector.id}`} >
          <Accordion.Header>
            {rootSector.name}
          </Accordion.Header>
          <Accordion.Body>
            {subSectors.map(sector =>
              <InputGroup key={sector.id} >
                <InputGroup.Checkbox checked={formData.sector_ids.includes(sector.id)} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  // sector.checked = e.target.checked;
                  if (e.target.checked) {
                    handleFieldChange('sector_ids', [...formData.sector_ids, sector.id]);
                  } else {
                    handleFieldChange('sector_ids', formData.sector_ids.filter(s => sector.id != s));
                  }
                }} />
                <InputGroup.Text>{sector.name}</InputGroup.Text>
              </InputGroup>
            )}
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  </Card>
    : <>
      {allSectorsIsLoading && <Spinner animation="border" />}
      {allSectorsIsError && <span>Sectors encountered an error
        <Button onClick={() => dispatch(sectorActions.getAllSimplified())}>Try again.</Button></span>}
    </>);
}
