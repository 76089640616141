
import { CurrentUser, GlobalRole, Organization, OrganizationUser, User } from "../types";

export const globalRolePolicies = {
  viewAny,
  view,
  create,
  update,
  delete: delete_,
  restore,
};
function viewAny(currentUser?: CurrentUser): boolean {
  return true;
};
function view(currentUser?: CurrentUser, globalRole?: GlobalRole): boolean {
  return true;
}
function create(currentUser?: CurrentUser): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_user_global_roles) {
    return true;
  }
  return false;
}
function update(currentUser?: CurrentUser, globalRole?: GlobalRole): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_user_global_roles) {
    return true;
  }
  return false;
}
function delete_(currentUser?: CurrentUser, globalRole?: GlobalRole): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_user_global_roles) {
    return true;
  }
  return false;
}
function restore(currentUser?: CurrentUser, globalRole?: GlobalRole): boolean {
  if (!currentUser) {
    return false;
  }
  if (currentUser.is_owner || currentUser.global_role.can_edit_user_global_roles) {
    return true;
  }
  return false;
}
