import React, { FC, useEffect, useState } from 'react'
import getPublicUrl from '../../../helpers/getPublicUrl';
import './styles.css';

const mediaTypes = {
  audio: 'audio',
  application: 'application',
  image: 'image',
  video: 'video',
} as const;
interface MediaFileProps {
  file: {
    path: string,
    mime_type: string
  }
};
const MediaFile: FC<MediaFileProps> = ({ file: { mime_type, path } }) => {
  switch (mime_type.split('/')[0]) {
    case mediaTypes.image:
      return (
        <img
          className='centered'
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          src={getPublicUrl(path)}
          alt="Image unavailable"
        />
      );
    case mediaTypes.video:
      return (
        <video
          className='d-block centered'
          // width="320" height="240"
          style={{
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          controls autoPlay muted
        >
          <source src={getPublicUrl(path)} type={mime_type} />
          Video unavailable
        </video>
      );
    case mediaTypes.audio:
      return (
        <audio
          className='d-block w-100 centered'
          // width="320" height="240"
          style={{
            maxWidth: "100%",
            maxHeight: "100%"
          }}
          controls
        >
          <source src={getPublicUrl(path)} type={mime_type} />
          Audio unavailable
        </audio>
      );
    default:
      return <></>;
  }
}

export default MediaFile;
