export const countryConstants = {
    CLEAR: 'COUNTRY_CLEAR',
    CLEAR_ALL: 'COUNTRY_CLEAR_ALL',

    GETBYID_REQUEST: 'COUNTRY_GET_REQUEST',
    GETBYID_SUCCESS: 'COUNTRY_GET_SUCCESS',
    GETBYID_FAILURE: 'COUNTRY_GET_FAILURE',

    STORE_REQUEST: 'COUNTRY_STORE_REQUEST',
    STORE_SUCCESS: 'COUNTRY_STORE_SUCCESS',
    STORE_FAILURE: 'COUNTRY_STORE_FAILURE',

    UPDATE_REQUEST: 'COUNTRY_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'COUNTRY_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'COUNTRY_UPDATE_FAILURE',

    DELETE_REQUEST: 'COUNTRY_DELETE_REQUEST',
    DELETE_SUCCESS: 'COUNTRY_DELETE_SUCCESS',
    DELETE_FAILURE: 'COUNTRY_DELETE_FAILURE',

    GETALL_REQUEST: 'COUNTRIES_GETALL_REQUEST',
    GETALL_SUCCESS: 'COUNTRIES_GETALL_SUCCESS',
    GETALL_FAILURE: 'COUNTRIES_GETALL_FAILURE',

    GETALL_SIMPLIFIED_REQUEST: 'COUNTRIES_GETALL_SIMPLIFIED_REQUEST',
    GETALL_SIMPLIFIED_SUCCESS: 'COUNTRIES_GETALL_SIMPLIFIED_SUCCESS',
    GETALL_SIMPLIFIED_FAILURE: 'COUNTRIES_GETALL_SIMPLIFIED_FAILURE',

    SET_FILTER: 'COUNTRIES_SET_FILTER',
};
