import { orgRatingConstants } from '../constants';

export function allOrgRatings (state = {}, action) {
    switch (action.type) {
        case orgRatingConstants.GETALL_SIMPLIFIED_REQUEST:
            return {
                loading: true
            };
        case orgRatingConstants.GETALL_SIMPLIFIED_SUCCESS:
            return {
                items: action.response.data
            };
        case orgRatingConstants.GETALL_SIMPLIFIED_FAILURE:
            return {
                error: action.error
            };
        default:
            return state;
    }
}
