
import { authHeader } from '../helpers';

export const organizationService = {
  getAll,
  store,
  getById,
  update,
  // delete: _delete
  uploadOrganizationFile,
  uploadAwardPicture,
  uploadProductDetailMedia,

  getByIdDeprecated,
  updateDeprecated,
};

function uploadOrganizationFile(id, file, purpose) {
  const { 'Content-Type': contentType, ...headers } = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: file
  };
  return fetch(`${APP_API_URL}/orgs/${id}/upload/${purpose}`, requestOptions).then(handleResponse);
}

function uploadAwardPicture(award_id, file) {
  const { 'Content-Type': contentType, ...headers } = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: file
  };
  return fetch(`${APP_API_URL}/upload/award_picture/${award_id}`, requestOptions).then(handleResponse);
}
function uploadProductDetailMedia(product_detail_id, file) {
  const { 'Content-Type': contentType, ...headers } = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: headers,
    body: file
  };
  return fetch(`${APP_API_URL}/upload/productDetail/${product_detail_id}`, requestOptions).then(handleResponse);
}

function getAll(page, perPage, filter) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  let url = `${APP_API_URL}/orgs`;
  if (!page) page = 1;
  if (!perPage) perPage = 10;
  url += `?page=${page}&perPage=${perPage}`;
  if (filter) url += `&filter=${JSON.stringify(filter)}`;

  return fetch(url, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${APP_API_URL}/orgs/${id}/`, requestOptions).then(handleResponse);
}
function getByIdDeprecated(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${APP_API_URL}/deprecated/orgs/${id}/`, requestOptions).then(handleResponse);
}

function store(organization) {
  const requestOptions = {
    method: 'POST',
    headers: { ...authHeader() },
    body: JSON.stringify(organization)
  };

  return fetch(`${APP_API_URL}/orgs`, requestOptions).then(handleResponse);
}

function update(organization) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(organization)
  };

  return fetch(`${APP_API_URL}/orgs/${organization.id}`, requestOptions).then(handleResponse);;
}
function updateDeprecated(organization) {
  const requestOptions = {
    method: 'PUT',
    headers: { ...authHeader(), 'Content-Type': 'application/json' },
    body: JSON.stringify(organization)
  };

  return fetch(`${APP_API_URL}/deprecated/orgs/${organization.id}`, requestOptions).then(handleResponse);;
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader()
  };

  return fetch(`${APP_API_URL}/organization/${id}`, requestOptions).then(handleResponse);
}
function getUsers(id) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader()
  };

  return fetch(`${APP_API_URL}/orgs/${id}/users`, requestOptions).then(handleResponse);
}

function handleResponse(resp) {
  return resp.text().then(text => {
    const data = text && JSON.parse(text);
    if (!resp.ok) {
      if (data.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || data.error || data.statusText;
      return Promise.reject(error.toString());
    }

    return data;
  });
}
