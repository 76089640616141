import React, { useState, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { useAppSelector } from '../../helpers';

import { userActions } from '../../actions';

function EditUserPage() {

  let { user_id } = useParams();

  const currentUser = useAppSelector(state =>
    state.users.items.find(user => user.id == user_id)
  )

  /*
    const [title, setTitle] = useState(currentUser.email)
    const [content, setContent] = useState(currentUser.name)
  */
  const [user, setUser] = useState(currentUser);

  const [submitted, setSubmitted] = useState(false);
  // test 123 const editing = useAppSelector(state => state.edituser.editing);
  const dispatch = useAppDispatch();
  let editing = false;
  // reset login status
  useEffect(() => {

  }, []);

  function handleChange(e) {
    const { name, value } = e.target;
    setUser(user => ({ ...user, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setSubmitted(true);
    if (user.email && user.name) {
      dispatch(userActions.update(user));
    }
  }

  return (
    <Card body>
      <div className="col-lg-8 offset-lg-2">
        <h2>Edit</h2>
        <form name="form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input type="text" name="email" value={user.email} onChange={handleChange} className={'form-control' + (submitted && !user.email ? ' is-invalid' : '')} />
            {submitted && !user.username &&
              <div className="invalid-feedback">Username is required</div>
            }
          </div>
          <div className="form-group">
            <label>Name</label>
            <input type="text" name="name" value={user.name} onChange={handleChange} className={'form-control' + (submitted && !user.name ? ' is-invalid' : '')} />
            {submitted && !user.name &&
              <div className="invalid-feedback">First Name is required</div>
            }
          </div>
          <div className="form-group">
            <button className="btn btn-primary">
              {editing && <span className="spinner-border spinner-border-sm mr-1"></span>}
              Update
            </button>
            <Link to="/" className="btn btn-link">Cancel</Link>
          </div>
        </form>
      </div>
    </Card>
  );
}

export { EditUserPage };
