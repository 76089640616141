
import React, { useState } from 'react';
import { Button, Card, Form, InputGroup, Spinner } from 'react-bootstrap';

import { Link, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../helpers';
import { countryActions } from '../../actions';

export function CountryCreate() {
    const navigate = useNavigate();
    const country = useAppSelector(state => state.country);
    const [name, setName] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const dispatch = useAppDispatch();

    const handleSubmit = (e) => {
        if (e.currentTarget.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        if (name) {
            dispatch(countryActions.store({
                country: {
                    name: name,
                }
            }));
            setSubmitted(true);
        }
    };

    if ( !submitted && country.data ) dispatch(countryActions.clear());
    if ( submitted && country.data ) {
        navigate(`/countries/${country.data.id}/edit`);
    }
    if ( submitted && country.loading ) return (<Card body><Spinner animation="border" /></Card>);

    return (<Card body><Form noValidate validated={!!name} onSubmit={handleSubmit}>
        <Form.Group key="name" controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control
                required
                type="text"
                placeholder="Country Placeholder Name"
                value={name}
                isInvalid={!name}
                feedback="name is required"
                onChange={e=>{
                    setName(e.target.value);
                }}
            />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
        </Form.Group>
        <Button type="submit">Create Organization</Button>
    </Form></Card>);
}
