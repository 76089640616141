
import { BasePlacement } from '@popperjs/core';
import { Property } from 'csstype';
import React, { FC, useMemo, useRef } from 'react';
import { OverlayTrigger, Row } from 'react-bootstrap';
import { CustomTooltip, CustomTooltipPropTypes } from '../Custom';

export const position = {
  top: 'top',
  left: 'left',
  right: 'right',
  bottom: 'bottom',
}
export interface GenericInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string,
  labelPlacement?: BasePlacement,
  icon?: React.ReactNode,
  tooltip?: React.ReactNode,
  tooltipColor?: Property.Color,
  tooltipProps?: CustomTooltipPropTypes,
  innerClassName?: string,
  innerStyle?: React.CSSProperties,
}
export const GenericInput: FC<GenericInputProps> = ({
  id,
  className = '',
  name = '',
  label = null,
  labelPlacement = 'top',
  icon = <></>,
  tooltip = "",
  color = "blue",
  tooltipColor = color,
  tooltipProps = {},
  style,
  innerClassName = '',
  innerStyle = {},
  ...props
}) => {
  const targetRef = useRef<HTMLInputElement>(null);
  return <OverlayTrigger
    overlay={(overlayProps) => <CustomTooltip
      {...overlayProps}
      backgroundColor={tooltipColor}
      {...tooltipProps}
      target={targetRef.current}
    >{tooltip}</CustomTooltip>}
  >
    <div
      ref={targetRef}
      className={className}
      style={labelPlacement === position.left || labelPlacement === position.right
        ? { display: 'flex', ...style }
        : style
      }
    >
      {label && (labelPlacement === position.top || labelPlacement === position.left) && (
        <label style={{ display: 'inline' }} htmlFor={id}>{label}</label>
      )}
      {label && labelPlacement === position.left && " "}
      {label && labelPlacement === position.top && <br />}
      {icon && <>{icon}{" "}</>}
      <input
        {...props}
        className={innerClassName}
        style={{
          padding: 0,
          margin: 0,
          display: 'inline',
          ...innerStyle
        }}
      />
      {label && labelPlacement === position.bottom && <br />}
      {label && labelPlacement === position.right && " "}
      {label && (labelPlacement === position.bottom || labelPlacement === position.right) && (
        <label htmlFor={id}>{label}</label>
      )}
    </div>
  </OverlayTrigger >;
}
