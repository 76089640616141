import { combineReducers } from 'redux';

import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { countries } from './countries.reducer';
import { country } from './country.reducer';
import { organizationDeprecated } from './organizationDeprecated.reducer';
import { organizations } from './organizations.reducer';
import { alert } from './alert.reducer';
import { allOrgRatings } from './allOrgRatings.reducer';
import { allLanguages } from './allLanguages.reducer';
import { allSectors } from './allSectors.reducer';

import { abibApi } from '../services';
import { authenticationSlice, keyvisualSlice, languageSlice, organizationListSlice } from '../slices';

const rootReducer = combineReducers({
  [abibApi.reducerPath]: abibApi.reducer,
  authentication: authenticationSlice,
  // allCountries,
  // allCountryRatings,
  alert,
  allLanguages,
  allOrgRatings,
  allSectors,
  countries,
  country,
  keyvisual: keyvisualSlice,
  language: languageSlice,
  organizationDeprecated,
  organizationList: organizationListSlice,
  organizations,
  registration,
  users,
});

export default rootReducer;
