import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Table, Spinner, Image } from 'react-bootstrap';
import { organizationActions, countryActions, sectorActions } from '../../actions';
import { organizationTypeConstants } from '../../constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { SearchOptions } from './';
import { Sectors } from './profile';
import getPublicUrl from '../../helpers/getPublicUrl';
import { CustomPagination, ConfirmModal, defaultConfirmModalConfig } from '../../components/Custom';
import { DeleteButton, EditButton, ToggleHiddenButton, UserListButton } from '../../components/Button';
import { organizationPolicies } from '../../authorization';
import {
  useDeleteOrganizationMutation,
  useGetAllCountriesQuery,
  useGetAllSectorsQuery,
  useGetCurrentUserQuery,
  useGetOrganizationListQuery,
  useHideOrganizationMutation,
  useUnhideOrganizationMutation,
} from '../../services';
import { setOrganizationList } from '../../slices/organizationList.slice';
import { getOrganizationUrl, getOrganizationUrlPath, useAppDispatch, useAppSelector } from '../../helpers';

function OrganizationList({
}) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const organizationList = useAppSelector(state => state.organizationList);

  const { data: allCountries, isLoading: allCountriesIsLoading, isError: allCountriesIsError } = useGetAllCountriesQuery(0);
  const { data: currentUser, error: currentUserError, isLoading: currentUserIsLoading } = useGetCurrentUserQuery();
  const { data: organizations, error: orgsError, isLoading: orgsIsLoading } = useGetOrganizationListQuery(organizationList);
  const [deleteOrg, { isLoading: deleteOrgIsLoading, error: deleteOrgError }] = useDeleteOrganizationMutation();
  const [hideOrg, { isLoading: hideOrgIsLoading, error: hideOrgError }] = useHideOrganizationMutation();
  const [unhideOrg, { isLoading: unhideOrgIsLoading, error: unhideOrgError }] = useUnhideOrganizationMutation();


  function setOrgList(state) {
    dispatch(setOrganizationList(state));
  }
  const [confirmModalConfig, setConfirmModalConfig] = useState(defaultConfirmModalConfig);
  function handleDeleteOrg(org_id) {
    setConfirmModalConfig({
      show: true,
      title: 'Delete Organization?',
      body: <>Deleting an organization is not Reversible. Please Confirm.</>,
      onCancel: () => { setConfirmModalConfig(defaultConfirmModalConfig) },
      onConfirm: () => { deleteOrg({ org_id }) },
    });
  }

  return (<Card body>
    <ConfirmModal {...confirmModalConfig} />
    <Row>
      <Col md={3}>
        <SearchOptions filter={organizationList.filter} onChange={(filter) => setOrgList({ page: 1, perPage: 10, filter })} />
      </Col>
      <Col>
        <h3>List Organizations:</h3>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th key="name">Name</th>
              <th key="country_id">Country</th>
              <th key="sectors">Sectors</th>
              <th key="organizationType">Type</th>
            </tr>
          </thead>
          <tbody>
            {orgsIsLoading && <tr><td colSpan={4}><Spinner animation="border" /></td></tr>}
            {organizations && allCountries?.items && organizations.items.map((organization, index) => {
              const canUpdateOrg = organizationPolicies.update(currentUser, organization);
              const canDeleteOrg = organizationPolicies.deleteOrg(currentUser, organization);
              const canViewUsers = organizationPolicies.viewAnyUser(currentUser, organization);
              return <tr key={index} style={{
                opacity: organization.hidden_at ? 0.6 : 1
              }}>
                <td key="name">
                  {canUpdateOrg &&
                    <EditButton onClick={() => navigate({ pathname: `/orgs/${organization.id}/edit` })} />
                  }
                  {canUpdateOrg && canViewUsers && " "}
                  {canViewUsers &&
                    <UserListButton onClick={() => navigate({ pathname: `/orgs/${organization.id}/users` })} />
                  }
                  {(canUpdateOrg || canViewUsers) && " "}
                  {canDeleteOrg && <>
                    <DeleteButton onClick={() => handleDeleteOrg(organization.id)} />{" "}
                    <ToggleHiddenButton
                      toggle={organization.hidden_at}
                      onToggle={() => organization.hidden_at ? unhideOrg({ org_id: organization.id }) : hideOrg({ org_id: organization.id })}
                    />
                  </>}
                  {(canUpdateOrg || canViewUsers || canDeleteOrg) && " | "}
                  <Link to={getOrganizationUrlPath(organization.url_name, organization.id)}>
                    {organization.logo && <img src={getPublicUrl(organization.logo)} style={{ height: 50 }} />}
                    {organization.name}
                  </Link>
                </td>
                <td key="country">{!!organization.country && allCountries?.items.find(country => country.id == organization.country)?.name}</td>
                <td key="sectors"><Sectors sectors={organization.sectors} /></td>
                <td key="organizationType">{organizationTypeConstants[organization.organizationType]}</td>
              </tr>
            })}
          </tbody>
        </Table>
        <CustomPagination
          pagination={organizations?.pagination || {}}
          onChange={(page, perPage) => setOrgList({ page, perPage, filter: organizationList.filter })}
        />
      </Col>
    </Row>
  </Card>);
}

export { OrganizationList };
