import React, { Suspense, useContext, useEffect } from 'react';
import { Router, Route, useLocation, useParams, Routes } from 'react-router-dom';

import { Header } from './';
import { alertActions } from '../actions';
import { AuthMiddleware } from './';

import { routes } from '../config/routes';
import { useAppDispatch, useAppSelector } from '../helpers';
import { Spinner } from 'react-bootstrap';

import { Helmet } from 'react-helmet';

function ABIBApp() {
  const location = useLocation();
  const alert = useAppSelector(state => state.alert);
  const dispatch = useAppDispatch();

  useEffect(() => {
    // change host to rootUrl defined in config to avoid CORS-Errors
    if (!window.location.href.startsWith(APP_URL)) {
      window.location.href = `${APP_URL}${window.location.pathname}${window.location.search}`;
    }
  }, []);
  useEffect(() => {
    // clear alert on location change
    dispatch(alertActions.clear());
  }, [location]);

  return (
    <Suspense fallback={<Spinner />} >
      <Helmet>
        <title>AfricanBIB</title>
        <meta name="description" content="ABIB" />
      </Helmet>
      <div className="jumbotron">
        <div className="container">
          {alert.message &&
            <div className={`alert ${alert.type}`}>{alert.message}</div>
          }
          <div className='header'>
            <Routes>
              {routes.map((route, index) =>
                <Route key={index}
                  path={route.path}
                  element={<Header hideUI={route.hideUIHeader} />}
                />
              )}
              <Route path="*" element={<Header />} />
            </Routes>
          </div>
          <div className='content'>
            <Routes>
              {routes.map((route, index) =>
                <Route key={index}
                  path={route.path}
                  element={<AuthMiddleware route={route} />}
                />
              )}
            </Routes>
          </div>
        </div>
      </div>
    </Suspense>
  );
}

export { ABIBApp };

