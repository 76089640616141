/**
 *  load all of this project's JavaScript dependencies
 */

// require('./bootstrap');
// require('../css/app.css');
import 'bootstrap';
import '../css/app.css';

/**
 * React Root App Element
 */
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ABIBApp } from './components';
import { BrowserRouter } from 'react-router-dom';
import { store } from './helpers';

// Before
// import { render } from 'react-dom';
// const container = document.getElementById('app');
// render(<App tab="home" />, container);

// After
const container = document.getElementById('app');
const root = container && createRoot(container); // createRoot(container!) if you use TypeScript
root?.render(<StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ABIBApp />
    </BrowserRouter>
  </Provider>
</StrictMode>);

// Ein Test-Comment

