import React, { useState } from 'react';
import { ButtonGroup, Card, ToggleButton } from 'react-bootstrap';
import ProductDetails from './ProductDetails';
import ProductDetailsV2 from './ProductDetailsV2';

export default function OrganizationProfileProducts(props) {
  const [toggle, setToggle] = useState(true);
  return (<>
    <Card body key="products.products">
      <Card.Title>Products</Card.Title>
      <Card.Text>{props.organization.products}</Card.Text>
    </Card>
    <Card body key="products.services">
      <Card.Title>Services</Card.Title>
      <Card.Text>{props.organization.services}</Card.Text>
    </Card>

    {/* <ButtonGroup className="mb-2">
            <ToggleButton
            id="toggle-radio"
            type="radio"
            variant="secondary"
            checked={toggle}
            value="1"
            onChange={(e) => setToggle(true)}
            >
            V1
            </ToggleButton>
            <ToggleButton
            id="toggle-radio"
            type="radio"
            variant="secondary"
            checked={!toggle}
            value="1"
            onChange={(e) => setToggle(false)}
            >
            V2
            </ToggleButton>
        </ButtonGroup>
        <br /> */}
    {props.product_details && (
      // toggle ?
      <ProductDetails product_details={props.product_details} />
      // : <ProductDetailsV2 product_details={props.product_details} />
    )}
  </>);
}
